import React, { useState, useEffect } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import {  useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { LeadScript } from '../../redux/actions/Asterleads'
import LoadingSpinner from '../LoadingSpinner'
function Script(props) {
  let [scriptcontent, setscriptcontent] = useState('')
  let [iframeSrc, setIframeSrc] = useState('')
  let [contentType, setContentType] = useState(''); 
  let [loader,setLoader] = useState(false)
  let Dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    getScriptData()
  }, [])
  async function getScriptData(){

    let script = localStorage.getItem('script_name')
    setLoader(true)

    Dispatch(LeadScript(script)).then(async function (response) {
           if (response.status === 200) {
        if (response.data.data[0].type === 'TEXT') {
          let html = response.data.data[0].value.replace(/\n/g, `<br>`)
            function urlify(text) {
              var urlRegex = /(https?:\/\/[^\s]+)/g;
              return text.replace(urlRegex, function(url) {
                return (
                  `<a class="lead-script-link"  target="_blank" href=${url }  data-url="' 
                  ${url } 
                  '">Click Here</a>`)
  
              })
             
            }
          setscriptcontent(urlify(html))
          setContentType(response.data.data[0].type);
          setLoader(false)
        }
        if (response.data.data[0].type === 'URL') {
          
          const url = response.data.data[0].value;
          setIframeSrc(url);
          setContentType(response.data.data[0].type);
          setTimeout(()=>{
            setLoader(false)
          },1000)
        }
      }
    })
  }
 
  return (
    <>
      <div className="lead-notepad-container">
        <div className={ location.pathname === `/script`? "lead-notepad-head1" :"lead-notepad-head"}>
          <div className="lead-notepad-head-lft lead-meetings-head-lft">
            <div className=  { location.pathname === `/script`? "lead-notepad-head-title route-header1" :"lead-notepad-head-title route-header"} >
              Script
            </div>
          </div>
        </div>
        { loader && <div className='loader-script'>
           <LoadingSpinner size="larger" />
        </div> }
        {!loader &&<div
          className="lead-script-content"
        >
          {ReactHtmlParser(scriptcontent)}
        </div>}
        {!loader &&contentType === 'URL'  && !iframeSrc.includes(window.location.hostname )&&(<iframe
          id="content-frame"
          src={iframeSrc}
          style={{ width: '100%', height: '100%', border: 'none', marginTop: '20px' ,minHeight: '500px'}}    
          // onLoad={() => setLoader(false)} 
        ></iframe>)}
        {!loader &&contentType === 'URL' &&iframeSrc.includes(window.location.hostname)&&<div className='nodata_'>Cant able to load same url in iframe</div>}
      </div>
    </>
  )
}

export default Script