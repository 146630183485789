import api from "../../utils/axios-util";

export const AsterCampaigns = (temp_campaign) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            var apinfo = JSON.parse(localStorage.getItem("apinfo")) || "{}";
            await api
                .get(`/campaign/fetch?tid=${apinfo.tenant_code}&name=${temp_campaign}`)
                .then((response) => {
                    resolve(response);
                    
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
};
// edit
export const AsterAgentTable = (payload) =>{
  return (dispatch,getState) =>{
    return new Promise(async(resolve,reject)=>{
      await api.post(`/setdianamic/fields`,payload)
      .then((res)=>{
        resolve(res)
      }).catch((err)=>{ 
        reject(err)
      })
    })
  }
}

export const AsterAgentTableField = (payload) =>{
  return (dispatch,getState) =>{
    return new Promise(async(resolve,reject)=>{
      await api.get(`/getdianamic/fields?agent=${payload.user}&campaign=${payload.campaign}&template=${payload.template}&module=${payload.module}&all=${payload.fields}`)
      .then((res)=>{
        if(res.data.statusCode === 200){
          const leadDetailsPriority = 
          ['lead_id', 'first_name', 'last_name', 'phone_number'
          , 'alt_number', 'address', 'email', 'disposition'];
          const contactDetailPriority = [
            'id','name','phone_number','alternative_number'
          ]
          const meetingDetailPriority = [
            'meeting_id','meeting_name','meeting_sub_title','phone_number','schedule_date','Status','module'
          ]
          const ticketDetailPriority = [
            'ticket_id','account_name','phone_number','status','priority','subject'
          ]
          const voicemailDetailPriority = [
            'phone_number','Campaign','calldate','Queue'
          ]
          const allPriorityData = payload.module === 'lead' ?
          leadDetailsPriority : payload.module === 'contact' ?
          contactDetailPriority : payload.module ==='meeting' ? meetingDetailPriority :
          payload.module === 'ticket' ? ticketDetailPriority : 
          payload.module === 'voicemail' ? voicemailDetailPriority : []
          res.data.data.sort((a, b) => {
            const isADetail = allPriorityData.includes(a.model);
            const isBDetail = allPriorityData.includes(b.model);
            if (isADetail && !isBDetail) {
              return -1; 
            }
            if (!isADetail && isBDetail) {
              return 1; 
            }
            if (isADetail && isBDetail) {
              return allPriorityData.indexOf(a.model) - allPriorityData.indexOf(b.model);
            }
            return 0;
          }).map((item) => {
            if(item.name !== payload.template){
              item.agent_table_show = item.agent_table_show.filter(subItem => subItem.name === payload.template);
              item.agent_table_show = item.agent_table_show[0].showOn
            }
          })
        resolve(res)
        }else{
          reject(res)
        }
      }).catch((err)=>{
        reject(err)
      })
    })
  }
}

export const AsterAgentTableUpdate = (payload,agent) =>{
  return (dispatch,getState) => {
    return new Promise(async (resolve,reject) => {
      await api.put(`/dianamic/fields/${agent}`,payload)
      .then((res)=>{
        resolve(res)
      }).catch((err)=>{
        reject(err)
      })
    })
  }
}
// edit
export const AsterGetCampaignsData = (payload) => {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/campaign?campaign_name=${payload}`).then(response => {
          resolve(response);
          
        }).catch((err) => {
          reject(err)
        })
      })
    };    
  
  };

  export const PauseCodeDuration = (payload) => {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/pausecode?pause_code=${payload}`).then(response => {
          resolve(response);
          
        }).catch((err) => {
          reject(err)
        })
      })
    };
  }

  export const liveagentdata = (payload) => {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        await api.get(`/liveagent/datafetch?user=${payload}`).then(response => {
          resolve(response);
          
        }).catch((err) => {
          reject(err)
        })
      })
    };
  }