import React, { useState, useEffect, useRef } from 'react'
import { TicketFetch } from '../../redux/actions/TicketAction'
import { useDispatch, useSelector } from 'react-redux'
import Format from '../../utils/format-text'
import EventEmitter from '../../utils/EventEmitter'
import { useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router'
import { Encode ,Decode} from '../../utils/EncodeDecode'
import { getfilterdata } from '../../redux/actions/Asterleads'
function AssociatedLeads(props) {
  let navigate = useNavigate()
  let AppPermission
  
  let { contact_id } = useParams()

  const [contactDetails, setcontactDetails] = useState([])
  const [count, setcount] = useState(0)
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [skipdata, setskipdata] = useState({ skip: 0, take: 10 })
  let stateValue = useSelector((state) => state)
  let dispatch = useDispatch()
  const agent = localStorage.getItem('user')
  useEffect(() => {
  
      mount(contact_id, skipdata)
    

  }, [])

  function mount(data, skipdatavalue) {
    let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
    let source = []
    if (Object.keys(teamdata).length > 0) {
      if (teamdata.type == "all" || teamdata.type == "source") {
        source = teamdata.type_data.source.map((elm) => elm.name)
      }
    }
    const payload = {
        user_name: localStorage.getItem('user'),
        source: source,
        campaign_name:  localStorage.getItem('selectedCampaign'),
        filter_data: {
       
                "hopper_status": {
                    "label": "No",
                    "value": "0"
                },
                "isclosed": {
                    "label": "No",
                    "value": "false"
                },"associated_id": {
                    "label": 'contact_id',
                    "value": contact_id
                }
            
        },
        module:'lead',
        skip: skipdatavalue.skip,
        take: skipdatavalue.take
      }
      // setloader(true)
      dispatch(getfilterdata(payload))
      .then((response) => {

        if (response.status === 200) {

        
          setcount(response.data.count)
          setskipdata((set) => {
            set.skip = set.skip + 5
            set.take = 10
            return {
              ...set
            }
          })
         
            setcontactDetails(contactDetails.concat(response.data.data))
          

        } else {
          setcontactDetails([].concat([]))
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'ticket',
        })
      })
  }
  

  const fetchMoreData = () => {
    if (contactDetails.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout((v) => {
        let data = {
          skip: skipdata.skip + 5,
          take: 5,
        }
      
          mount(contact_id, skipdata)
        
       
      }, 500)
    }
  }


  function timeSince(value) {
    let date = new Date(value)
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' year ago'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' month ago '
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' day ago'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hour ago'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minute ago'
    }
    return Math.floor(seconds) + ' second ago'
  }
  return (
    <div style={{
      height: '100%',

    }}>
      <div
        id="ticketsDiv"
        style={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <InfiniteScroll
          scrollableTarget="ticketsDiv"
          dataLength={contactDetails.length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={infinitScroll.more}
        >
          {contactDetails.map((data, i) => {
            return (
              <div key={i} className="lead-meeting">
                <div className="lead-meeting-lft">
                  <div className="lead-ticket-header">
                    <div>
                      <strong
                     
                      >
                        {` #${data.lead_id}   Lead Assigned to ${Format(
                          data.user,
                        )}`}
                      </strong>
                    </div>

                    <div className="lead-ticket-subheader">
                    </div>
                    <div className="lead-ticket-subheader">
                      {`Last Modified By ${Format(data.modified_by)}`}
                    </div>
                  </div>
                  <div className="lead-meeting-subject">
                    <p className="lead-meeting-subject-p" style={{ marginBottom: 5 }}>{`Phone Number: ${Format(
                      data.phone_number, { maskbool: true }
                    )}`}</p>
                    { 
                      <div className="lead-ticket-status">
                        <strong
                          className={
                           'lead-ticket-others'
                          }
                        >
                          {Format(data.disposition)}
                        </strong>
                      </div>
                    }
                   
                  </div>
                </div>

                {<div
                 
                  className="lead-meeting-rht lead-ticket-rht"
                >
                  <div className='timeline-head-rht'>{timeSince(data.created_at)}</div>
                  <span onClick={() => {navigate(`/leads/${Encode(data.phone_number)}/${data.lead_id}`)}} className="material-icons lead-call-icon lead-view-ticket-icon ">
                  contact_page
                  </span>
                </div>}
              </div>
            )
          })}
          {contactDetails.length === 0 && (
            <div className="nodata " style={{ margin: '70px auto', width: '70px' }}>
              nodata
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default AssociatedLeads
