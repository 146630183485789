import axios from "axios";
import localdomain from "../../utils/constant";
import api from "../../utils/axios-util";
const auth0Config = localdomain();

let common_api_url = auth0Config.common_api_url;
const domain_name = () => {
  const regex = /doocti/g;
  const matches = window.location.hostname.match(regex);
  if(matches!==null && matches[0]==="doocti"){
      return true
  }
  return false
}
export const ResetloginPassword = (payload) => {
 
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .put(`${common_api_url}/userPassword?id=${payload.id}&user_email=${payload.user_email}&role=${payload.role}`, {password:payload.password})
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const asterForgot =(payload)=>{
  return (dispatch,getState)=>{
    return new Promise(async(resolve,reject)=>{
      await api.post(`${common_api_url}/forgot/password`,payload)
      .then((response)=>{
        resolve(response)
      }).catch((err)=>{
        reject(err)
      })
    })
  }

}
export const Asterlogin = (payload) => {
  // if(!domain_name()){
  //   common_api_url='https://api-v2.'+window.location.hostname
  // }
  payload.role = 2

  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`${common_api_url}/v2/oauth/login`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const AsterCampaigns = (data,url) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // var apinfo = JSON.parse(localStorage.getItem("apinfo")) || "{}";
      let statedata = getState()
       api.defaults.baseURL = url.api_url;
      api.defaults.headers.common["Authorization"] = `Bearer ${statedata.login.token}`;
      await api
        .get(`/asterdialer/campaigns?user=${data}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const AsterPermission=()=>{
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
     
      await api
        .get(`${common_api_url}/agent/user/permissions`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}
export const AsterConfig = (payload) => {
  // if(!domain_name()){
      //   common_api_url='https://api-v2.'+window.location.hostname
      // }
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let statedata = getState()
      api.defaults.baseURL =auth0Config.common_api_url;
     api.defaults.headers.common["Authorization"] = `Bearer ${statedata.login.token}`;
      await api
        .get(`/common/project/config?tenant_code=${payload.tenant_code}&user_email=${payload.user_email}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const LoginUserinfo = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/login`,payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};


export const AuthUserinfo = (DomainPath) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`${DomainPath}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const logout = (user) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/logout`,user)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const getdomain = (domain) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`${common_api_url}/${domain}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const getsystemconfig = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/systemconfig`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
