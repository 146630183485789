import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Col, Form, Row, Button, Card, Dropdown } from 'react-bootstrap'
import CallScreen from '../components/CallScreen'
import TicketTable from '../components/Table'
import EventEmitter from '../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import { TicketFetch ,TicketUpdate } from '../redux/actions/TicketAction'
import '../assets/css/Leads.css'
import Filters from '../components/filter'
import InfiniteScroll from 'react-infinite-scroll-component'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Outbound } from '../redux/SocketAction/SocketAction'
import { callmodule } from '../redux/Calls/CallAction'
import { Encode } from '../utils/EncodeDecode'
import Dialogbox from '../components/Dialogbox'
import { getTemplate } from '../redux/actions/ChatActions'
import { waTemplate } from '../redux/SocketAction/SocketAction'
import waicon from '../assets/images/whatsapp.svg'
import { ContactFetch } from '../redux/actions/ContactActions'
import { CustomformData } from '../redux/actions/Customform'
import {tempData} from '../redux/background/BackgroundAction'
import { AsterAgentTableField, AsterAgentTableUpdate } from '../redux/actions/AsterHeader'
import DynamicField from '../components/DynamicField'
import Format from '../utils/format-text'
import { adminPermission } from '../utils/localstorage-provider'
function Tickets() {

  const [searchselect, setsearchselect] = useState([])
  let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
  let selected_campaign = localStorage.getItem('selectedCampaign')
  let [wadata, setwadata] = useState([])
  let [leaddata, setleaddata] = useState({})
  let [loader,setloader]= useState(false)
  let [Numpopup,setNumpopup]= useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'TextInput',
        label: 'Add Number',
        placeholder: ' Enter Number',
        required: true,
        model: 'new_number',
        multiple: false,
        value: '',
        fieldtype:'number'
      },
    ],
    title: 'Add Number',
    Message: 'Add Number Successfully',
    Butonstatus: 'Add',
  })
  const [popUpData, setpopUpData] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Whatsapp Template',
        placeholder: ' select message',
        required: true,
        model: 'whatsapp_template',
        option: [],
        multiple: false,
        value: '',
      },
    ],
    title: 'Whatsapp Template',
    Message: 'Selected Successfully',
    Butonstatus: 'Send',
  })
  let wasession = localStorage.getItem("wasession")
  let stateValue = useSelector((state) => state)
  let dispatch = useDispatch()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let navigate = useNavigate()
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  let agent = localStorage.getItem('user')
  const [searchticketvalue, setsearchticketvalue] = useState("")
  const [ticketdetail, setticketdetail] = useState([])
  const [showtoggle, setshowtoggle] = useState(true)
  const [skipdata, setskipdata] = useState({
    user: agent,
    overdue:0,
    skip: 0,
    take: 20,
    search: '',
    merged_as: "",
    ticket_id: '',
    priority: '',
    status: 'open',
    from: '',
    to: '',
    assign: '',
  })
  let [count, setcount] = useState(0)
  let [checked, setchecked] = useState(true)
  let [dynamicHead,setDynamicHead] = useState(false)
  const [header, setheader] = useState([
    {
      Header: () => (
        <div >
          <span>Action</span>
        </div>
      ),
      accessor: 'action',
      isrender: false,
      Cell: ({ row }) => {
        return (
          <div className="lead_action">
            {<span
              className="material-icons"
              style={{ color: '#3e98c7' }}
              onClick={() => TicketView(row, 'TicketView')}
            >
              info
            </span>}
            {(row.original.phone_number && AppPermission.click_to_call) && <span
              className={stateValue.background.oncall == false && wasession === "false" ? "material-icons" : "material-icons disabledbutton"}
              style={{ color: '#32AA52' }}
              onClick={() => clickcall(row)}
            >
              phone
            </span>}
            {(row.original.phone_number && AppPermission.wa_view) &&
              <img
                onClick={() => { wapopup(row.original) }}
                className={"wa_icon"}
                src={waicon}
                alt="test"
              />


            }
            {!row.original.phone_number &&
              <OverlayTrigger
              placement={'bottom'}
              overlay={<Tooltip>{ "Add Number"}</Tooltip>}
            >
              <span 
              class="material-icons"
              onClick={() => { addnum(row.original) }}
              >
              add_box
              </span>

              </OverlayTrigger>
            }
          </div>
        )
      },
    },
  ])
    // edited
    const handleSettingsClick = (value)=>{
      setDynamicHead(!value)
    }
    const saveDynamicPopup = (data) => {
      if(data===false){
        setDynamicHead(data)
        return
      }
      setDynamicHead(false)
      setloader(true)
      const payload ={
        fields:[data],
        module:'ticket',
        campaign:selected_campaign,
        template:campaigninfo.template_name
      }
      dispatch(AsterAgentTableUpdate(payload,agent)).then((response)=>{
        if(response.data.statusCode === 200){
          getDynamicheaders()
        }
      }).catch((err)=>{
        setloader(false)
      })
    }
    useEffect(()=>{
      getDynamicheaders()
    },[])
  
    function getDynamicheaders(){
      setloader(true)
      const data = {
        user:agent,
        campaign:selected_campaign,
        module:'ticket',
        template:campaigninfo.template_name
      }
      dispatch(AsterAgentTableField(data)).then((response)=>{
        if(response.data.statusCode === 200){
          const headerDefault = header[header.length-1];
          const defaultHeader =[headerDefault]
          const newData = response.data.data.map(item => {
              console.log(item.model,"item.model")
              if(item.model === 'phone_number'){
                return{
                  masking:true,
                  Header: item.label,
                  accessor:item.model, 
                  agent_table_show:item.agent_table_show
                }
              }else{
                return{
                  masking:false,
                  Header: item.label,
                  accessor:item.model, 
                  agent_table_show:item.agent_table_show
                }
              }

            });
          const updatedHeader = [ ...newData,...defaultHeader];
          setheader(updatedHeader) 
          setloader(false)
        }else{
          const headerDefault = header[header.length-1];
          const defaultHeader = [headerDefault];
          setheader(defaultHeader)
          setloader(false)
        }
      }).catch((err)=>{
        EventEmitter.emit('error-message-getter', {
          action: 'field',
          module: "Selected fields are removed Please select field.",
        }) 
        setloader(false)
      })
    }
    // eidted  
  useEffect(() => {
    mountedfunction()
    if(AppPermission.wa_view){
    dispatch(getTemplate()).then((res) => {
      if (res.data.length > 0) {

        setpopUpData((set) => {
          set.field[0].option = res.data.map(e=>{
            e.label = e.name
            e.value = e.id
            return e
          })
          return {
            ...set
          }
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: "whatsapp Template",
      })
    })
    }
    dispatch(tempData({type:"",data:{}}))
  }, [])

  function mountedfunction(Moredata) {
    let url
    if (searchticketvalue !== "") {
      if (checked) {
        url = `/tickets/fetch?skip=${skipdata.skip}&portal=false&All=false&take=${skipdata.take}&user=${skipdata.user
          }&search=${searchticketvalue}&merged_as=parent&status=${skipdata.status}&overdue=${skipdata.overdue}&ticket_id=${skipdata.ticket_id}&priority=${skipdata.priority}&from=${skipdata.from}&to=${skipdata.to}`
      } else {
        url = `/tickets/fetch?skip=${skipdata.skip}&portal=false&All=false&take=${skipdata.take}&search=${searchticketvalue}&merged_as=parent&status=${skipdata.status}&overdue=${skipdata.overdue}&ticket_id=${skipdata.ticket_id}&priority=${skipdata.priority}&from=${skipdata.from}&to=${skipdata.to}`
      }
    }else{
      url = `/tickets/fetch?ticketview=&merged_as=parent&portal=false&All=false&skip=${skipdata.skip}&take=${skipdata.take}&user=${skipdata.user}&priority=${skipdata.priority}&status=${skipdata.status}&from=${skipdata.from}&to=${skipdata.to}&assign=${skipdata.assign}&overdue=${skipdata.overdue}`
    }
    if(Moredata != "Moredata"){
      setloader(true)
    }
    dispatch(TicketFetch(url))
      .then((response) => {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)
          setskipdata((set) => {

            if (set.take === 20) {
              set.skip = 20
              set.take = 10
            } else {
              set.skip = set.skip + 10
              set.take = 10
            }
            return {
              ...set,
            }
          })
          let Datas = []
          for (let i = 0; i < response.data.data[0].others.length; i++) {
            let obj = {};
            for (let key in response.data.data[0].others[i]) {
            if (response.data.data[0].others[i].hasOwnProperty(key)) {
              response.data.data[0].others[i][key]=response.data.data[0].others[i][key] === null ? "" :response.data.data[0].others[i][key]
              if (typeof response.data.data[0].others[i][key] !== 'object') {
              obj[key] = reduceLength(Format(response.data.data[0].others[i][key]));
              }
            }
           }
            obj.all = response.data.data[0].others[i];
            Datas.push(obj);
          }

          setticketdetail(ticketdetail.concat(Datas))
        } else {
          setticketdetail([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'ticket',
        })
      })
  }
  function closepopup(data) {
    if (data.action === "cancel") {
      setpopUpData((set) => {
        set.open = false
        return {
          ...set
        }
      })
    } else {
      let name
      if (wadata.req_module === "contact") {
        dispatch(
          ContactFetch(
            `/contact/fetchsearch?number=${wadata.phone_number}`,
          ),
        )
          .then((res) => {
           
            if (res.data.statusCode == 200) {
              name = res.data.data[0].name
              sendtemp(data, name)
            }
          }).catch(() => {
            EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: "contact",
            })
          })
      }
      else {
        let payload = {
          template_name: campaigninfo.template_name,
          type: campaigninfo.type,
          phone_number: wadata.phone_number,
          id: ""
        }
        dispatch(CustomformData(payload)).then((res) => {
          
          if (res.data.statusCode == 200) {
            name = res.data.data[0].others.first_name
            sendtemp(data, name)
          }
        }).catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: 'lead data',
          })
        })
      }

    }
  }
  const structureChange = () =>{
    if(header.length <= 4 || loader){
      return ''
    }
    return 'structureChange'
  }
  function closenumpopup(data) {
    if (data.action === "cancel") {
      setNumpopup((set) => {
        set.open = false
        return {
          ...set
        }
      })
    } else {
        // let updatedata ={
        //   fields:[
        //     {
        //       name: "modified_at",
        //       value: currenttime()
        //     },
        //     {
        //       name: "modified_by",
        //       value: agent
        //     },
        //     {
        //       name: "phone_number",
        //       value: data.data[0].new_number
        //     },
        //     {
        //       name: "sms_notify",
        //       value: '0'
        //     },
        //     {
        //       name: "wa_notify",
        //       value: '0'
        //     },
        //     {
        //       name: "email_notify",
        //       value: '0'
        //     },
        //     {
        //       name: "api_push",
        //       value: '0'
        //     }
        //   ]
        // }
        let updatedata ={
          fields:[
            {
              name: "modified_at",
              value: currenttime()
            },
            {
              name: "modified_by",
              value: agent
            },
            {
              name: "phone_number",
              value: data.data[0].new_number
            }
          ]
        }
        dispatch(TicketUpdate(leaddata.ticket_id, updatedata)).then((res)=>{
          if(res.status == 200 ){
            refresh()
            setNumpopup((set) => {
              set.open = false
              return {
                ...set
              }
            })
            EventEmitter.emit('error-message-getter', {
              action: 'updatesuccess',
              module: 'Update Successfully',
            })
          }
        }).catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'update',
            module: 'Failed Update',
          })
        })

    }
  }

  function currenttime() {
    const today = new Date()
    const yyyy = today.getFullYear()
    let mm = today.getMonth() + 1
    let dd = today.getDate()
    let hh = today.getHours()
    let MM = today.getMinutes()
    let ss = today.getSeconds()
    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hh < 10) hh = '0' + hh
    if (MM < 10) MM = '0' + MM
    if (ss < 10) ss = '0' + ss

    const formattedToday =
      yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + MM + ':' + ss
    return formattedToday
  }
  function wapopup(data) {
   
    setwadata(data)
    setpopUpData((set) => {
      set.open = true
      return {
        ...set
      }
    })
  }
  function addnum(data){ 
    setleaddata(data)
    setNumpopup((set) => {
      set.open = true
      return {
        ...set
      }
    })
  }
  function sendtemp(data, name) {
    function find_(data_) {
     
      return data_.value === data.data[0].whatsapp_template;
    }


    let templatedata = {
      "action": "send-wa-template",
      "station": localStorage.getItem('extension'),
      "phone_number": wadata.phone_number,
      "sender_id": wadata.req_id,
      "sender_type": wadata.req_module === "contact" ? 1 : 2,
      "sender_name": name,
      "tenant_id": JSON.parse(localStorage.getItem("config")).data.tenant_code,
      "agent": localStorage.getItem("user"),
      "template": {
        "id": data.data[0].whatsapp_template,
        "type": "",
        "name": data.field[0].option.find(find_).name,
        "language": data.field[0].option.find(find_).lang
      }
    }
    if (data.action === "create") {
    
      dispatch(waTemplate(stateValue.background.websocketconnection, templatedata))
      setpopUpData((set) => {
        set.open = false
        return {
          ...set
        }
      })
    }


   
  }
  const TicketView = (row, pageName) => {
    if (pageName === 'TicketView') {
      if(row.original.phone_number == ""){
        row.original.phone_number = row.cells[0].value
      }
      navigate(`/tickets/${Encode(row.original.phone_number)}/${row.original.ticket_id}`)
    }
  }

  const createticket = () => {
    navigate(`/tickets/create`)
  }


  const filterdataapi = (data, isfilter) => {
    let url
    if (data === 'false') {
      setskipdata((set) => {
        setchecked(false)
        setsearchticketvalue("")
        set.priority = ''
        set.status = skipdata.status
        set.from = ''
        set.to = ''
        set.assign = data
        set.skip = 20
        set.take = 10
        return {
          ...set,
        }
      })
      // http://localhost:4001
      url = `/tickets/fetch?skip=${0}&take=${20}&portal=false&All=false&user=${skipdata.user}&assign=${data}&status=${"open"}&overdue=0`
    } else if (data === 'true') {
      setskipdata((set) => {
        set.assign = ""
        return {
          ...set,
        }
      })
      refresh()
    } else {
      
      if (!isfilter) {
        setshowtoggle(false)
        setinfinitScroll((set) => {
          set.more = true
          return {
            ...set,
          }
        })
      }
      setskipdata((set) => {
        set.priority = data.priority
        set.overdue = data.overdue
        set.status = data.status
        set.from = data.from
        set.to = data.to
        set.merged_as = data.merged_as
        set.ticket_id = data.ticketid
        set.skip = 20
        set.take = 10
        set.assign = ""

        return {
          ...set,
        }
      })
      url = `/tickets/fetch?skip=${0}&portal=false&All=false&take=${20}&user=${skipdata.user
        }&priority=${data.priority}&from=${data.from}&to=${data.to
        }&merged_as=${data.merged_as}&ticket_id=${data.ticketid}`
        if( data.status =="" && data.overdue == 0){
          url += `&status=${data.status}`
        }else{
          if(data.overdue == 1){
            url += `&overdue=${data.overdue}`
          }else{
            url += `&status=${data.status}&overdue=${data.overdue}`
          }
          
        }
    }
    setloader(true)
    dispatch(TicketFetch(url))
      .then((response) => {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)
          let Datas = []
          for (let i = 0; i < response.data.data[0].others.length; i++) {
            let obj = {};
            for (let key in response.data.data[0].others[i]) {
            if (response.data.data[0].others[i].hasOwnProperty(key)) {
              response.data.data[0].others[i][key]=response.data.data[0].others[i][key] === null ? "" :response.data.data[0].others[i][key]
              if (typeof response.data.data[0].others[i][key] !== 'object') {
              obj[key] = reduceLength(Format(response.data.data[0].others[i][key]));
              }
            }
           }
            obj.all = response.data.data[0].others[i];
            Datas.push(obj);
          }
          setticketdetail([].concat(Datas))
        } else {
          setticketdetail([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'ticket',
        })
      })
  }

  function searchticket(e) {

    // user: agent,
    // overdue:"",
    // skip: 0,
    // take: 20,
    // search: '',
    // merged_as: "",
    // ticket_id: '',
    // priority: '',
    // status: 'open',
    // from: '',
    // to: '',
    // assign: '',

    setskipdata((set) => {
      set.search = e.target.value
      set.skip = 20
      set.take = 10
      return {
        ...set,
      }
    })
    setsearchticketvalue(e.target.value)
    // setchecked(true)
    let url
    if (checked) {
      url = `/tickets/fetch?skip=${0}&portal=false&All=false&take=${20}&user=${skipdata.user
        }&search=${e.target.value}&merged_as=parent&status=${skipdata.status}&overdue=${skipdata.overdue}&ticket_id=${skipdata.ticket_id}&priority=${skipdata.priority}&from=${skipdata.from}&to=${skipdata.to}`
    } else {
      url = `/tickets/fetch?skip=${0}&portal=false&All=false&take=${20}&search=${e.target.value}&merged_as=parent&status=${skipdata.status}&overdue=${skipdata.overdue}&ticket_id=${skipdata.ticket_id}&priority=${skipdata.priority}&from=${skipdata.from}&to=${skipdata.to}`
    }
    setloader(true)
    dispatch(TicketFetch(url))
      .then((response) => {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)
          let Datas = []
          for (let i = 0; i < response.data.data[0].others.length; i++) {
            let obj = {};
            for (let key in response.data.data[0].others[i]) {
            if (response.data.data[0].others[i].hasOwnProperty(key)) {
              response.data.data[0].others[i][key]=response.data.data[0].others[i][key] === null ? "" :response.data.data[0].others[i][key]
              if (typeof response.data.data[0].others[i][key] !== 'object') {
              obj[key] = reduceLength(Format(response.data.data[0].others[i][key]));
              }
            }
           }
            obj.all = response.data.data[0].others[i];
            Datas.push(obj);
          }
          setticketdetail([].concat(Datas))
        } else {
          setticketdetail([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'ticket',
        })
      })
  }

  function isValidDateString(str) {
    return !isNaN(Date.parse(str));
  }
  const reduceLength = (value) =>{
   
    if (typeof value === 'string' && isValidDateString(value)&& value.length > 11) {
      let datetime = new Date(value)
      let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
      let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
      let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
      let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
      value = datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`
    }
    else if(typeof value=== "string" && value.length >= 17 ){
      value = value.substring(0, 17)+'...';
      return value
    }
    return value
}

  let fetchMoreData = () => {
    if (ticketdetail.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout(() => {
        mountedfunction("Moredata")
      }, 1000)
    }
  }


  function clickcall(data) {

    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {

      let payload = {
        state: stateValue.background.websocketconnection,
        value: data.original.phone_number.toString()
      }
      dispatch(callmodule({
        id: data.original.ticket_id.toString(),
        module: "ticket",
        phone_no: data.original.phone_number.toString()
      }))
      dispatch(Outbound(payload.state, payload.value))
    } else {

      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }

  }
  let refresh = (verify) => {
    setinfinitScroll((set) => {
      set.more = true
      return {
        ...set,
      }
    })
    setshowtoggle(true)

    setchecked(true)

    setsearchticketvalue("")
    let set = {}
    set.priority = ''
    set.status = "open"
    set.from = ''
    set.to = ''
    set.merged_as = "parent"
    set.overdue=0
    set.skip = 20
    set.take = 10
    set.ticketid = ""
    filterdataapi(set, true)
  }
  return (
    <div className="page-main-container page-main-container-mobile">
      <DynamicField
      module={'ticket'}
      dynamicHead={dynamicHead}
      saveDynamicPopup={saveDynamicPopup}
      />
       <Dialogbox
        fields={closepopup}
        value={popUpData}
        className="dialog-body-default1"
        // cancel={{ display: 'none' }}
        save={'disabled'}
      />
      <Dialogbox
        fields={closenumpopup}
        value={Numpopup}
        className="dialog-body-default1"
        // cancel={{ display: 'none' }}
        save={'disabled'}
      />
      <div className="scroll-lft" md={8}>


        <div className="lead-header">
          <div className="lead-header1">
            <div className="route-header lead-header1-lft"> Tickets</div>
            {AppPermission.ticket_search && <div className="lead-search">
              <span className="material-icons lead-search-icon">search</span>
              <Form.Control
                onChange={(e) => {
                  searchticket(e)
                }}
                type="text"
                value={searchticketvalue}
                placeholder="Search"
                style={{
                  border: '1px solid #e9ecef',
                  backgroundColor: '#fff',
                  padding: 10,
                  'padding-left': '40px',
                }}
              />
            </div>}
          </div>
          <div className="lead-header2">
            {AppPermission.ticket_refresh && 
            <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>Refresh</Tooltip>}
          >
            <div
              onClick={refresh}
              className="lead-table-button material-icons"
            >
              refresh
            </div>
            </OverlayTrigger>}
            <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip>Header settings</Tooltip>}
              >
          <span
            className="material-icons settings lead-table-button"
            style={{ color: '#3e98c7' }}
            onClick={() => handleSettingsClick(dynamicHead)}
          >
            settings
          </span>
          </OverlayTrigger>
            {AppPermission.ticket_filter && 
            <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>Filter</Tooltip>}
            >
            <div>
            <Filters filterdataapi={filterdataapi} type={'tickets'} />
            </div>
            </OverlayTrigger>}
            {AppPermission.ticket_assign && showtoggle &&
             <OverlayTrigger
              placement={'bottom'}
              overlay={<Tooltip>{checked ? "Assigned Ticket" : "UnAssigned Ticket"}</Tooltip>}
            >
              <div className="ticket-toggle-icon lead-create-head-rht custom-cursor">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  placeholder="nnnnnnnnn"
                  checked={checked}
                  onChange={(e) => {
                    filterdataapi(e.target.checked.toString())
                  }}
                />
              </div>
            </OverlayTrigger>}
            {AppPermission.ticket_create && 
              <OverlayTrigger
              placement={'bottom'}
              overlay={<Tooltip>Add</Tooltip>} 
            >
            <div
              onClick={createticket}
              className="lead-table-button material-icons" 
            >
              add_circle
            </div> 
            </OverlayTrigger>}
          </div>
        </div>
        <div className="lead-body">
          <div
          className={structureChange()}
            id="ticketDiv"
            style={{
              height: '100%',
              overflow: 'auto',
              color: 'grey',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <InfiniteScroll
              dataLength={ticketdetail.length}
              next={fetchMoreData}
              hasMore={infinitScroll.more}
              scrollableTarget="ticketDiv"
            >
              <TicketTable
              loader={loader}
                checkbox={false}
                header={header}
                leaddetail={ticketdetail}
                type="lead"
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Tickets
