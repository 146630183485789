import React, { useEffect, useState, useMemo } from 'react'
import EventEmitter from '../utils/EventEmitter'
import '../assets/css/navbar.css'
import { CustomformData, } from '../redux/actions/Customform'
import { ContactFetch } from '../redux/actions/ContactActions'
import Dialogbox from './Dialogbox'
import logo from '../assets/images/logo.png'
import whatsapp from '../assets/images/whatsapp.svg'
import script from '../assets/images/script.svg'
import { NavLink } from 'react-router-dom'
import { Dropdown, Button, Modal, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { connect, useDispatch, useSelector } from 'react-redux'
import { setQueue } from '../redux/Details/DetailAction'
import { ChatHistory, WhatsappContact } from '../redux/actions/ChatActions'
import { PauseCodeDuration, liveagentdata } from '../redux/actions/AsterHeader'
import Sidebar from './Sidebar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import formate from '../utils/format-text'
import webchat_ from '../assets/images/webchat_color.svg'
import {
   socketmessage, callLeadDetail, oncall
} from '../redux/background/BackgroundAction'
import {
   queueLogin, wraptime,
   queueLogout,
   newMessage, resetcall, initiateEvent, queuePause,
   passcode_breach


} from '../redux/SocketAction/SocketAction'
import { adminPermission } from '../utils/localstorage-provider'
import {

   setPause,
} from '../redux/Details/DetailAction'
import jwtDecode from 'jwt-decode'
import {
   incoming,
   on_call, on_log,
   fetchcallaction, dispoValidation
} from '../redux/Calls/CallAction'
import Tost from './Toster'
import { useParams, useLocation } from 'react-router-dom'
import { Encode } from '../utils/EncodeDecode'
import fbchat_ from '../assets/images/fb-color.svg'
import { getlocalstorage } from '../utils/localstorage-provider'
import { AsterUpdateAgent } from '../redux/actions/SelectCRM'
import { Getannouncement } from '../redux/actions/AsterCallAction'
function Nav(props) {
   let getApplicationstatus = JSON.parse(localStorage.getItem('applicationState'))
   let selected_queue = JSON.parse(localStorage.getItem('selectedQueue'))
   let queues1 = []
   let queues = props.queues
   let selectedCampaign = props.selected_campaign
   let dispatch = useDispatch()
   const [callevent, setCallEvent] = useState("")
   const dValue = JSON.parse(localStorage.getItem('oncalldata'))
   const callValue = JSON.parse(localStorage.getItem('callstatus'))
   let state_value = useSelector((state) => state)
   let tenant
   let AppPermission
   if (localStorage.getItem('config')) {
      AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
      tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
     
   }
   let wasession = localStorage.getItem("wasession")
   let activewindow = localStorage.getItem('chatactive')
   let navigate = useNavigate()
   const location = useLocation()
   const user = localStorage.getItem('user')
   const agent = localStorage.getItem('username')
   const station = localStorage.getItem('extension')
   const decoded = jwtDecode(props.token)
   const tid = decoded['http://api.doocti.com/claims/tenant_code']
   const [timmer, settimmer] = useState(false)
   const navLink = { textDecoration: 'none', color: 'black' }
   const pauseCodes = props.pausecode
   const [login_action, setLoginAction] = useState('play_arrow')
   const [loginBreakOn, setLoginBreakon] = useState(true)
   const [seconds, setSecond] = useState(0)
   const [timeron, setTimeron] = useState(false)
   const [show, setShow] = useState(false)
   const [whshow, setwhShow] = useState('')
   const [displaydata, setdisplaydata] = useState('')
   const [button_txt, setbutton_txt] = useState({})
   const [header, setheader] = useState('')
   const [HeaderClass, setHeaderClass] = useState('')
   const [button_value, setbutton_value] = useState({})
   const [delay, setdelay] = useState(0)
   const [WaNotificationcount, setWaNotificationcount] = useState({})
   const [meetingNotificationcount, setmeetingNotificationcount] = useState({})
   const [timerColor, setTimerColor] = useState('red')
   const [saveTimer, setST] = useState(1)
   const [activelead, setactivelead] = useState(false)
   const [activeticket, setactiveticket] = useState(false)
   const [activecontacts, setactivecontacts] = useState(false)
   const [activeMeetings, setactiveMeetings] = useState(false)
   const [activedashboard, setactivedashboard] = useState(false)
   const [activemenu, setactivemenu] = useState(false)
   const [chatdrop, setchatdrop] = useState({})
   const [breakOn, setBreakOn] = useState(false)
   const [passTime, setPassTime] = useState(0)
   const [breakOverDue, setBreakOverDue] = useState(false)
   const [hide, setHide] = useState(false)
   const [adminPermissions, setadminPermission] = useState({
      lead: false, ticket: false, contact: false, meeting: false
   })
   const [announcementdata, setannouncementdata] = useState([])
   const [Announcementpopupdata, setAnnouncementpopupdata] = useState({
      open: false,
      Type: 'Notification_bar',
      field: [],
      title: 'Announcement',
      Message: '',
   })
   const [wrapup, setwrapup] = useState({
      open: false,
      Type: 'wraptime',
      field: [{
         type: 'SelectList',
         label: 'Break',
         placeholder: 'Break',
         required: true,
         model: 'reason',
         option: [],
         multiple: false,
         value: false,
         readonly: false,
       }],
      title: 'Wrapup Time',
      Message: 'Ready to take next call ?',
      Butonstatus: 'Yes',
      Butonstatus_break: 'Dialer Pause'
   })
   const [activescript, setactivescript] = useState(false)
   const [announcementNotificationcount, setannouncementNotificationcount] = useState({})
   let [Predictivebreak, SetPredictivebreak] = useState(false)
   let [selectCamp, setselectCamp] = useState(getlocalstorage("selectedCampaign_data"))
   if (selectCamp === null) {
      localStorage.clear();
      localStorage.setItem("revertload",'true')
      navigate('/')
   }
   queues.forEach((data) => {
      queues1.push({ value: data, label: data })
   })
   const [breakOverPopup, setBreakOverPopup] = useState({
      open: false,
      Type: 'Notification_bar',
      field: [],
      title: 'Breach',
      Message: '',
      Butonstatus: 'get start',
   })
   const [popUpData, setpopUpData] = useState({
      open: false,
      Type: 'form_bar',
      field: [
         {
            type: 'SelectList',
            label: 'Select Queues',
            placeholder: 'please select',
            required: true,
            model: 'selected_queue',
            option: queues1,
            multiple: true,
            value: '',
         },
      ],
      title: 'Select Queue',
      Message: 'Selected Successfully',
      Butonstatus: 'Save',
      topbutton: true
   })
   let [status, setstatus] = useState({
      status: "",
      color: ""
   })
   let process = localStorage.getItem('process').split(',')
   useEffect(() => {
      if (state_value.socketAction.stop_event === 1) {
         setTimeout(() => {
            dispatch(initiateEvent(false))
         }, 10000)
      }
   }, [state_value.socketAction.stop_event])
   useEffect(() => {
      if (state_value.background.oncall === true) {
         setHide(false)
         if (localStorage.getItem("wasession") === "true") {
            setstatus({
               status: formate('OnChat'),
               color: 'parse-bk-clr ava-bgclr'
            })
         }
      }
   }, [state_value.background.oncall])
   useEffect(() => {
      if (wasession === "true" && activewindow === "true") {
         close(false)
      }
   }, [activewindow])
   useEffect(() => {
      localStorage.setItem("autodial","false")
      localStorage.setItem('chatactive', 'false')

      dispatch(initiateEvent(false))

      if (callValue !== null && callValue.calltype === "OUTBOUND") {
         dispatch(dispoValidation(false))
         localStorage.setItem("showdispo", true)
         dispatch(socketmessage(callValue))
         dispatch(callLeadDetail(dValue))
         if (dValue.module === "lead") {
            navigate('/leads/' + Encode(dValue.phone_number) + '/0')
         }
         if (dValue.module === "contact") {
            navigate('/contact/' + Encode(dValue.phone_number) + '/' + dValue.lead_id)
         } if (dValue.module === "ticket" && dValue.phone_number && dValue.lead_id) {
            navigate('/tickets/' + Encode(dValue.phone_number) + '/' + dValue.lead_id)
         }
      } else if (callValue !== null && (callValue.calltype === "POWER" || callValue.calltype === "INBOUND" || callValue.calltype === "AUTO" || callValue.calltype === 'preview') && callValue.evt != "RINGING") {
         dispatch(dispoValidation(false))
         localStorage.setItem("showdispo", true)
         dispatch(socketmessage(callValue))
         dispatch(callLeadDetail(dValue))
         if (dValue.module === "lead") {
            navigate('/leads/' + Encode(dValue.phone_number) + '/0')
         }
         if (dValue.module === "contact") {
            navigate('/contact/' + Encode(dValue.phone_number) + '/' + dValue.lead_id)
         } if (dValue.module === "ticket" && dValue.phone_number && dValue.lead_id) {
            navigate('/tickets/' + Encode(dValue.phone_number) + '/' + dValue.lead_id)
         }
      }
      event()
      if (selected_queue.length == 0) {
         dialogopen(true)

      }
      if (localStorage.getItem("revertload") !== "true") {
         refreshpage(false)
      }
      getAgentdata()

      setadminPermission((set) => {
         set.lead = adminPermission('leadmanagement:lead:view') && process.includes('leads')
         set.ticket = adminPermission('leadmanagement:tickets:view') && process.includes('tickets')
         set.contact = adminPermission('leadmanagement:contacts:view')
         set.meeting = adminPermission('leadmanagement:meetings:view')
         return set
      })
      let wraptime= localStorage.getItem("wraptime")
      if(wraptime>0){
         setwrapup((set) => {
            
            set.open = true
            return {
               ...set
            }
         })
      }
      announcementapi()
   }, [])

   useEffect(() => {
 
      if (props.selected_pause != 'available' &&
         props.selected_pause != 'loginBreak' && parseInt(
            localStorage.getItem('breakoverdue')) > 0) {
               localStorage.setItem('pauseTime',-2)
             
         setSecond(
            parseInt(localStorage.getItem('breakoverdue'))
         )
 
 
 
         setBreakOverPopup((set) => {
            set.open = true
            set.Message = 'Your Break Time is over!!!'
            return {
               ...set,
            }
         })
         setBreakOn(false)
        
        
         setBreakOverDue(true)
      }
 
   }, [])

   useEffect(() => {
      let parsedata = pauseCodes.map((elm)=>{
          return {
             label: elm,
             value: elm
          }
       })
 
       setwrapup((set)=>{
          set.field[0].option = parsedata
          return set
       })
 
       return () => {
          
       };
    }, [pauseCodes]);

    useEffect(() => {
 
 
 
 
      return () => {
         if ((seconds == -1) && props.selected_pause != 'available' &&
            props.selected_pause != 'loginBreak') {
            if (parseInt(
               localStorage.getItem('breakoverdue')) > 0) {
                 setSecond(
                  parseInt(
                     localStorage.getItem('breakoverdue') === null
                        ? 0
                        : localStorage.getItem('breakoverdue')
                  )
               )
 
 
            }
            else {
              
               localStorage.setItem("breakoverdue", 1)
               setSecond(
                  parseInt(localStorage.getItem('breakoverdue')
                  )
               )
            }
            setBreakOverPopup((set) => {
               let breach_payload={
                  "tenant_id": tenant,
                  "action": "start",
                  "station": station,
                  "campaign":  localStorage.getItem('selectedCampaign'),
                  "agent": agent,
                  "time": timestamp()
               }
               dispatch(passcode_breach(state_value.background.websocketconnection,breach_payload))
           
               set.open = true
               set.Message = 'Your Break Time is over!!!'
               return {
                  ...set,
               }
            })
            setBreakOn(false)
            setBreakOverDue(true)
            // setSecond(1)
            // localStorage.setItem("breakoverdue", seconds)
         }
         if (saveTimer <= 2 && !breakOverDue) {
            if (props.selected_pause == 'available') {
               setST(0)
               localStorage.setItem('loginTime', seconds)
            }
            else if (
               props.selected_pause != 'available' &&
               props.selected_pause != 'loginBreak'
            ) {
               setST(0)
               localStorage.setItem('pauseTime', seconds)
            }
            else if (props.selected_pause == 'loginBreak') {
               setST(0)
               localStorage.setItem('loginBreak', seconds)
            }
         }
         if (saveTimer == 2 && breakOverDue) {
            setST(0)
            localStorage.setItem("breakoverdue", seconds)
         }
         // else if (saveTimer == 30 && breakOverDue) {
 
         //    let countdown = ""
         //    if (seconds > 0 && seconds <= 60) {
         //       countdown = seconds + ' seconds'
         //    } else if (seconds > 60 && seconds <= 3600) {
         //       countdown = Math.floor((seconds / 60) % 60) + ' minutes'
 
         //    } else if (seconds > 3600) {
         //       countdown = Math.floor((seconds / 3600) % 60) + ' hours'
         //    }
         //    setBreakOverPopup((set) => {
         //       set.Message = 'you are taking break more than ' + countdown
 
         //       return {
         //          ...set,
         //       }
         //    })
 
         //    setST(0)
         // }
 
      }
   })
   useEffect(() => {
      if (state_value.background.socket_message.calltype === "INBOUND" && state_value.background.socket_message.evt === "RINGING" || state_value.background.socket_message.evt === "ANSWERED") {

         // setname(dialname)
         dispatch(
            ContactFetch(
               `/contact/fetchsearch?number=${state_value.background.socket_message.phonenumber}`,
            ),
         ).then((res) => {
            const s = res.data.data
            if (s.length > 0) {
               let name = res.data.data[0].name
               if (name === "") {
                  name = state_value.background.socket_message.phonenumber
               }
               localStorage.setItem("dialpadname", name);
            }
            else {
               let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
               let payload = {
                  template_name: campaigninfo.template_name,
                  type: campaigninfo.type,
                  phone_number: state_value.background.socket_message.phonenumber,
                  id: ''
               }
               dispatch(CustomformData(payload)).then((res) => {
                  if (res.status != 204) {
                     let name = res.data.data[0].others.first_name
                     if (name === "") {
                        name = state_value.background.socket_message.phonenumber
                     }
                     localStorage.setItem("dialpadname", name);

                  } else {

                     localStorage.setItem("dialpadname", 'Unknown Number');
                  }


               })
            }
         })
         //   props.sound_(true)
      }
   }, [state_value.background.socket_message.evt])
   useMemo(() => {
      if (state_value.background.socket_message.evt === "RINGING" || state_value.background.socket_message.evt === "ANSWERED" || state_value.background.socket_message.evt === "DISPO") {
         if (state_value.background.socket_message.agentChannel !== undefined && state_value.background.socket_message.userChannel !== undefined) {
            localStorage.setItem("callstatus", JSON.stringify(state_value.background.socket_message))
         } else {
            dispatch(oncall(false))
         }
         getAgentdata()
      }


   }, [
      state_value.background.socket_message.evt,
   ])

   useEffect(() => {
      if (props.selected_pause == 'available') {
         setLoginAction('pause')
         setTimerColor('#32AA52')
         if (loginBreakOn) {
            localStorage.setItem('loginBreak', seconds)
            setLoginBreakon(false)
         }
         if (breakOn) {
            localStorage.setItem('pauseTime', seconds)
            setBreakOn(false)
         }
         if (breakOverDue) {
            setBreakOverDue(false)
         }
         setTimeron(false)
         setSecond(
            parseInt(
               localStorage.getItem('loginTime') === null
                  ? 0
                  : localStorage.getItem('loginTime'),
            ),
         )
         setTimeron(true)
         setstatus({
            status: formate(props.selected_pause),
            color: 'parse-bk-clr ava-bgclr'
         })
      }
      else if (
         props.selected_pause != 'available' &&
         props.selected_pause != 'loginBreak'
      ) {
         if (loginBreakOn) {
            localStorage.setItem('loginBreak', seconds)
            setLoginBreakon(false)
         }
 
         setLoginAction('play_arrow')
         setTimerColor('rgb(241 100 100)')
         setTimeron(false)
         setBreakOn(false)
        if (props.selected_pause != 'available' &&
            props.selected_pause != 'loginBreak' && parseInt(
               localStorage.getItem('breakoverdue')) === 0) {
                  setBreakOverDue(false)
               
         }
               
          if (props.selected_pause != 'available' &&
            props.selected_pause != 'loginBreak' && parseInt(
               localStorage.getItem('pauseTime')) === -2) {
                  
                  setSecond( parseInt(localStorage.getItem('breakoverdue')))
         }else{
            
            setSecond(
               parseInt(
                  localStorage.getItem('pauseTime') === null
                     ? 0
                     : localStorage.getItem('pauseTime'),
               ),
            )
         }
         
         setBreakOn(true)
        
        
         setstatus({
            status: formate(props.selected_pause),
            color: 'parse-bk-clr parse-ylo-bgclr'
         })
      } else if (props.selected_pause == 'loginBreak') {
         setLoginAction('play_arrow')
         setTimerColor('rgb(255, 153, 0)')
         setSecond(
            parseInt(
               localStorage.getItem('loginBreak') === null
                  ? 0
                  : localStorage.getItem('loginBreak'),
            ),
         )
         setstatus({
            status: "Unavailable",
            color: 'parse-bk-clr parse-bgclr'
         })
      } else {
         setstatus({
            status: formate(props.selected_pause),
            color: 'parse-bk-clr parse-bgclr'
         })
      }
 
   }, [props.selected_pause])
   useEffect(() => {
      if (wasession === "true") {
         setstatus({
            status: formate('OnChat'),
            color: 'parse-bk-clr ava-bgclr'
         })
      }else if (wasession === 'false'){
         setstatus({
            status: formate('Available'),
            color: 'parse-bk-clr ava-bgclr'
         })
      }
      if (wrapup.open) {
         setstatus({
            status: formate("Wrap Time"),
            color: 'parse-bk-clr parse-ylo-bgclr'
         })
      }
      else if (state_value.background.socket_message.evt === "DISPO" && state_value.background.socket_message.uniqueid === state_value.background.oncall_phonenumber) {
         setstatus({
            status: formate(state_value.background.socket_message.evt.toLowerCase()),
            color: 'parse-bk-clr parse-bgclr'
         })
      }
      else if (state_value.call.dispoValidation === false) {
         setstatus({

            status: formate("Dispo"),
            color: 'parse-bk-clr parse-bgclr'
         })
      }
      else if (state_value.background.socket_message.evt === "ANSWERED" || state_value.background.socket_message.evt === "RINGING") {
         setstatus({
            status: formate('Oncall'),
            color: 'parse-bk-clr ava-bgclr'
         })
      } else if (Object.keys(state_value.background.socket_message).length == 0) {
         if (props.selected_pause == "available") {
            setstatus({
               status: formate(props.selected_pause),
               color: 'parse-bk-clr ava-bgclr'
            })
         }

      }

   }, [state_value.background.socket_message.evt, state_value.call.dispoValidation,wrapup.open])

   useEffect(() => {
      var interval1 = null
      if (loginBreakOn) {
         localStorage.setItem('loginBreak', seconds)
         interval1 = setInterval(() => {
            setSecond((s) => s + 1)
            setST((v) => v + 1)

         }, 1000)
      } else {
         clearInterval(interval1)
      }
      return () => clearInterval(interval1)
   }, [loginBreakOn])

   useEffect(() => {
      var interval3 = null
      if (breakOverDue) {
         setBreakOn(false)

         interval3 = setInterval(() => {
            setSecond((s) => s + 1)

            setST((v) => v + 1)
         }, 1000)


      } else {
         clearInterval(interval3)
      }
      return () => clearInterval(interval3)
   }, [breakOverDue])

   useEffect(() => {
      var interval2 = null
      if (breakOn) {

         interval2 = setInterval(() => {
            setSecond((s) => s - 1)
            setST((v) => v + 1)

         }, 1000)


      } else {
         clearInterval(interval2)
      }
      return () => clearInterval(interval2)
   }, [breakOn])

   useEffect(() => {
      var interval = null
      if (timeron) {
         interval = setInterval(() => {
            setSecond((s) => s + 1)
            setST((v) => v + 1)
         }, 1000)
      } else {
         clearInterval(interval)
      }
      return () => clearInterval(interval)
   }, [timeron])

   useEffect(() => {
      if (selected_queue.length == 0 && AppPermission?.queue) {

         dialogopen(true)
      } else {
         dialogopen(false)
      }
   }, [props.selected_queue])

   useEffect(() => {
      if (location.pathname !== "/contact/create", location.pathname !== "/leads/create", location.pathname !== "/tickets/create") {
         localStorage.removeItem('setStatic_number')
         localStorage.removeItem('Parant_data')
      }
      if (location.pathname.split('/')[1] === 'dashboard') {
         setactivelead(false)
         setactiveticket(false)
         setactivecontacts(false)
         setactivemenu(false)
         setactivedashboard(true)
         setactivescript(false)
         setactiveMeetings(false)
      } else if (location.pathname.split('/')[1] === 'tickets') {
         setactivelead(false)
         setactiveticket(true)
         setactivecontacts(false)
         setactivemenu(false)
         setactivedashboard(false)
         setactivescript(false)
         setactiveMeetings(false)
      } else if (location.pathname.split('/')[1] === 'leads') {
         setactivelead(true)
         setactiveticket(false)
         setactivecontacts(false)
         setactivemenu(false)
         setactivedashboard(false)
         setactivescript(false)
         setactiveMeetings(false)
      } else if (location.pathname.split('/')[1] === 'contact') {
         setactivelead(false)
         setactiveticket(false)
         setactivecontacts(true)
         setactivemenu(false)
         setactivedashboard(false)
         setactivescript(false)
         setactiveMeetings(false)
      } else if (location.pathname.split('/')[1] === 'meeting') {
         setactivelead(false)
         setactiveticket(false)
         setactivecontacts(false)
         setactivemenu(false)
         setactivedashboard(false)
         setactivescript(false)
         setactiveMeetings(true)
      }

      else if (location.pathname.split('/')[1] === 'voicemail' || location.pathname.split('/')[1] === 'meeting') {
         setactivelead(false)
         setactiveticket(false)
         setactivecontacts(false)
         setactivedashboard(false)
         setactivemenu(true)
         setactivescript(false)
      } else if (location.pathname === '/script') {
         setactivescript(true)
         setactivelead(false)
         setactiveticket(false)
         setactivecontacts(false)
         setactivemenu(false)
         setactivedashboard(false)
      }
      if (location.pathname === '/dashboard' || location.pathname === '/leads' || location.pathname === '/meeting' || location.pathname == '/voicemail' || location.pathname == '/tickets' || location.pathname == '/contact') {
         localStorage.removeItem("oncustomform")
      }
   }, [location])
   function refreshpage(data) {
      if (data === false) {
         // window.onbeforeunload = function () {
         //    return 'You have unsaved changes!';
         // }
         // return () => {
         //    window.onbeforeunload = null;
         // };
      } else if (data === true) {
         
         localStorage.setItem('revertload', "true")
         if (localStorage.getItem("revertload") === "true") {
            localStorage.clear();
            localStorage.setItem("revertload",'true')
            navigate('/')
         }

      }

   }
   const closebreakpopup = (data) => {
      let breakod = localStorage.getItem('breakoverdue')
      setBreakOverPopup((set) => {
         let breach_payload={
            "tenant_id": tenant,
            "action": "stop",
            "station": station,
            "campaign":  localStorage.getItem('selectedCampaign'),
            "agent": agent,
            "time": timestamp()
         }
         dispatch(passcode_breach(state_value.background.websocketconnection,breach_payload))
         set.open = false
         set.Message = 'your breaktime is ' + (seconds > 0 && seconds <= 60) ? breakod + ' minutes' : 0 + ' overdue!'
         return {
            ...set,
         }
      })
      setBreakOverDue(false)
      let break_time = seconds + passTime
 
      localStorage.setItem('pauseTime', break_time)
 
      ready()
   }

   function refreshSocket() {
      try {
         props.refreshProp()
         let checkCallStatus = {
            "tenant_id": tenant,
            "station": station,
            "uniqueid": "",
            "action": "check-call-status",
            "campaign": { "campaign": selectedCampaign }
         }
         dispatch(resetcall(state_value.background.websocketconnection, checkCallStatus))

      } catch (err) {
         console.log(err)
      }

   }
   function timestamp(){
      const now = new Date();

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
      const day = String(now.getDate()).padStart(2, '0');

      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
return formattedDateTime
   }
   function event() {
      EventEmitter.addListener('user-logout-emitterresponse-2', (data) => {
         refreshpage(true)
      })
      EventEmitter.addListener('trigger-wrapup', (data) => {
         if ((data.autodial !== 'true') && JSON.parse(localStorage.getItem("campaigndetails")).wrap_time > 0) {
            setwrapup((set) => {
               localStorage.setItem("wraptime", "0")
               dispatch(wraptime(data.socket, { "event": "wrap", 'campaign': localStorage.getItem('selectedCampaign'), "tenant_id": tenant, "station": station, "agent": user, "time": timestamp(), "action": "start" }))
 
               set.open = true
               return {
                  ...set
               }
            })
         } else {
            localStorage.setItem('webrtcManual', 'false')
            dispatch(on_log())
            localStorage.removeItem("callstatus")
            dispatch(oncall(false))
         }
 
      })

      EventEmitter.addListener('autodial-timmer', (data) => {
         settimmer(data)
      })
      EventEmitter.addListener('wa-chat-drop-response', (data_) => {
         setchatdrop(data_)
         setShow(false)
         close(false)
      })
      EventEmitter.addListener('new-con-whmsg', (data_) => {
         let chatactive = localStorage.getItem('chatactive')
         if (chatactive === 'false') {
            let data = data_.MSG[0]
            setShow(true)
            setdisplaydata({
               header1: data.sender_name,
               header2: data.phone_number,
               header3: data.message,
            })
            setbutton_txt({ accept: 'accept' })
            setbutton_value({ accept: 'ok' })
            setheader('Whatsapp Notification')
            setHeaderClass('whatsapp')
            setdelay(3000)
         }
      })


      EventEmitter.addListener('another-chat', (data) => {
         if (getApplicationstatus.whatsapp.length > 0) {
            let name
            for (let i = 0; i < getApplicationstatus.whatsapp.length; i++) {
               const element = getApplicationstatus.whatsapp[i]
               if (element.phone_number === data.phone_number) {
                  setShow(true)
                  name = element.sender_name
               }
            }
            setdisplaydata({
               header1: name,
               header2: data.phone_number,
               header3: data.text,
            })
            setbutton_txt({ accept: 'accept' })
            setbutton_value({ accept: 'ok' })
            setheader('Whatsapp Notification')
            setHeaderClass('whatsapp')
            setdelay(3000)
         }
      })

      EventEmitter.addListener('agent-notification', (data) => {
         setwhShow(data)
         notificationsender(data.MSG[0])

      })
   }
   const ready = (change, action) => {
      if (action !== undefined) {
         setHide(!hide)
      } else {
         setHide(change)
      }
      const selected_queue = JSON.parse(localStorage.getItem('selectedQueue'))

      if (login_action == 'play_arrow') {
         const data = {
            action: 'QueuePause',
            agent: localStorage.getItem('user'),
            campaign: selectedCampaign,
            paused: false,
            station: station,
            queue: selected_queue,
            tenant_id: tenant,
         }

         dispatch(queuePause(state_value.background.websocketconnection, data))
         EventEmitter.emit('autodialpause', true)
         if (selected_queue.length > 0) {

            localStorage.setItem('pauseTime', 0)
            props.set_pause('available')
         }
      }
   }

   const changePauseCode = (item) => {
      props.set_pause(item)
      const selected_queue = JSON.parse(localStorage.getItem('selectedQueue'))


      const data = {
         action: 'QueuePause',
         agent: localStorage.getItem('user'),
         campaign: selectedCampaign,
         paused: true,
         queue: selected_queue,
         station: station,
         tenant_id: tenant,
         reason: item
      }

      dispatch(queuePause(state_value.background.websocketconnection, data))
      EventEmitter.emit('autodialpause', false)
      dispatch(PauseCodeDuration(item)).then((res) => {

         if (res.data.statusCode == 200) {
            let data = res.data.data

            let time = data[0].timeValue.split(":")
            setBreakOn(true)
            let convertSeconds = parseInt(time[0]) * 3600 + parseInt(time[1]) * 60
            setPassTime(convertSeconds)
            setTimeron(false)
            setSecond(convertSeconds);
            if (parseInt(localStorage.getItem('breakoverdue')) > 0) {
               localStorage.setItem('breakoverdue', 0)
            }
         } else {
            setSecond(0)
         }
      })

   }

   const warpPauseCode = (item) => {

      props.set_pause(item)
      // const selected_queue = JSON.parse(localStorage.getItem('selectedQueue'))
      // const data = {
      //    action: 'QueuePause',
      //    agent: localStorage.getItem('user'),
      //    campaign: selectedCampaign,
      //    paused: true,
      //    queue: selected_queue,
      //    station: station,
      //    tenant_id: tenant,
      //    reason: item
      // }
      // dispatch(queuePause(state_value.background.websocketconnection, data))
      EventEmitter.emit('autodialpause', false)
      dispatch(PauseCodeDuration(item)).then((res) => {
         if (res.data.statusCode == 200) {
            let data = res.data.data

            let time = data[0].timeValue.split(":")
            setBreakOn(true)
            let convertSeconds = parseInt(time[0]) * 3600 + parseInt(time[1]) * 60
            setPassTime(convertSeconds)
            setTimeron(false)
            setSecond(convertSeconds);
            if (parseInt(localStorage.getItem('breakoverdue')) > 0) {
               localStorage.setItem('breakoverdue', 0)
            }
         } else {
            setSecond(0)
         }
      })

   }


   function notificationsender(data) {
      if (data.channel === "whatsapp") {
         setWaNotificationcount(data)
         setdisplaydata({
            header1: data.phone_number,
            header2: data.message,
            header3: data.sender_name,
         })
         setbutton_txt({ accept: 'accept', cancel: 'close' })
         setbutton_value({ accept: 'accept', cancel: 'close' })
         setheader('Whatsapp Notification')
         setHeaderClass('whatsapp')
         setdelay(3000)
         setShow(true)

      }
      if (data.channel === "ticket") {        //raghavan
         setWaNotificationcount(data)
         setdisplaydata({
            header1: data.phone_number,
            header2: data.message,
            header3: data.sender_name,
         })
         setbutton_txt({ accept: 'accept', cancel: 'close' })
         setbutton_value({ accept: 'view', cancel: 'close' })
         setheader('SLA Notification')
         setHeaderClass('tickets')
         setdelay(3000)
         setShow(true)

      }
      if (data.channel === "meeting") {
         setmeetingNotificationcount(data)
         setdisplaydata({
            header1: data.phone_number,
            header2: data.message,
            header3: data.sender_name,
         })
         setbutton_txt({ accept: 'accept', cancel: 'close' })
         setbutton_value({ accept: 'view', cancel: 'close' })
         setheader('meeting Notification')
         setHeaderClass('meeting')
         setdelay(10000)
         setShow(true)

      } if (data.channel === "announcement") {
         setannouncementNotificationcount(data)
         setAnnouncementpopupdata((set) => {
            set.open = true
            set.Message = data.message
            return {
               ...set
            }
         })


      }
      if (data.channel === "website") {
         setWaNotificationcount(data)
         let sender_name
         let phone

         if (data.sender_name === "") {
            sender_name = "No Name"
         } else {
            sender_name = data.sender_name
         }
         setdisplaydata({
            header1: phone,
            header2: data.message,
            header3: sender_name,
         })
         setbutton_txt({ accept: 'accept', cancel: 'close' })
         setbutton_value({ accept: 'accept', cancel: 'close' })
         setheader('WebChat Notification')
         setHeaderClass('webchat')
         setdelay(3000)
         setShow(true)

      }
      if (data.channel === "facebook") {
         setWaNotificationcount(data)
         let sender_name
         let phone

         if (data.sender_name === "") {
            sender_name = "No Name"
         } else {
            sender_name = data.sender_name
         }
         setdisplaydata({
            header1: phone,
            header2: data.message,
            header3: sender_name,
         })
         setbutton_txt({ accept: 'accept', cancel: 'close' })
         setbutton_value({ accept: 'accept', cancel: 'close' })
         setheader('Facebook Notification')
         setHeaderClass('webchat')
         setdelay(3000)
         setShow(true)

      }
   }
   function switchToFacebook(data) {
      EventEmitter.emit('switchToWhatsapp', 'facebook')
   }
   function close(value) {
      setShow(value)
   }

   function note(value) {

      if (value === 'accept') {
         let payload = {
            tenant_id: tenant,
            station: station,
            agent: localStorage.getItem('user'),
            campaign: selectedCampaign,
            session_id: whshow.MSG[0].record_id,
            phone_number: whshow.MSG[0].phone_number,
            sender_name: whshow.MSG[0].sender_name,
            channel: whshow.MSG[0].channel,
            custom_value: whshow.MSG[0].custom_value
         }
         localStorage.setItem(`wa-session-data`,`${JSON.stringify({phonenumber:payload.phone_number,evt:'whatsapp'})}`)
         dispatch(socketmessage({phonenumber:payload.phone_number,evt:'whatsapp'}))
         dispatch(newMessage(state_value.background.websocketconnection, payload))
      } else if (value === 'view' && whshow.MSG[0].channel === "meeting") {
         navigate(`/meeting/${Encode(whshow.MSG[0].phone_number)}/${whshow.MSG[0].record_id}`)
      } else if (value === 'view' && whshow.MSG[0].channel === "ticket") {
         navigate(`/tickets/${Encode(whshow.MSG[0].phone_number)}/${whshow.MSG[0].record_id}`)
      }
   }
   function switchToWhatsapp(data) {
      EventEmitter.emit('switchToWhatsapp', 'whatsapp')
   }
   function switchTowebchat(data) {
      EventEmitter.emit('switchToWhatsapp', 'website')
   }

   const dialogopen = (data) => {
      setpopUpData((set) => {
         set.open = data
         return {
            ...set,
         }
      })
   }
   const closepopup = (data) => {
      selectedQueue(data.data[0])
   }

   const closeAnnouncement = (data) => {
      setAnnouncementpopupdata((set) => {
         set.open = false
         return {
            ...set
         }
      })
   }
   const wraptime_response = (data) => {
 
      setwrapup((set) => {
 
         set.open = false
         localStorage.setItem('webrtcManual', 'false')
         if (data.action == "wraptime_end") {
            dispatch(wraptime(state_value.background.websocketconnection, { "event": "wrap", 'campaign': localStorage.getItem('selectedCampaign'), "tenant_id": tenant, "station": station, "agent": user, "time": timestamp(), "action": "end" }))
         }
         else if (data.action == "wraptime_parse") {
            dispatch(wraptime(state_value.background.websocketconnection, { "event": "wrap", 'campaign': localStorage.getItem('selectedCampaign'), "tenant_id": tenant, "station": station, "agent": user, "time": timestamp(), "action": "end", paused: true, reason: data.value }))
            warpPauseCode(data.value)
         }
         dispatch(on_log())
         localStorage.removeItem("callstatus")
 
         dispatch(oncall(false))
         localStorage.setItem("wraptime", "end")
         return {
            ...set
         }
      })
 
 
   }
   function mergeQueues(queueRankDatas, selectedQueue) {
      return selectedQueue.map(item => {
         let found = queueRankDatas.find(q => q.queue === item);
         if (found) {
            return { name: found.queue, user_rank: found.user_rank };
         } else {
            return item;
         }
      });
   }
   const selectedQueue = (val) => {
      let value = val.selected_queue.split(',')
      let queueRankDatas = JSON.parse(localStorage.getItem('config')).data.projects[0].userInfo[0].queue
      let selectedRankQueue = mergeQueues(queueRankDatas, value);
      if (val.selected_queue !== '') {
         props.set_queue(JSON.stringify(value))
         if (value.length > 0) {
            let paused_ = false
            let readystate = localStorage.getItem("selectedPause")
            if (readystate !== "available") {
               setLoginAction('play_arrow')
               ready()
            }
            const data = {
               action: 'QueueLogin',
               agent: localStorage.getItem('user'),
               campaign: selectedCampaign,
               paused: paused_,
               queue: selectedRankQueue,
               station: station,
               tenant_id: tenant,
            }
            const data2 = {
               action: 'QueueLogout',
               agent: localStorage.getItem('user'),
               campaign: selectedCampaign,
               current_queue: value,
               queue: [],
               station: station,
               tenant_id: tenant,
            }
            setpopUpData((set) => {
               set.open = false
               return {
                  ...set
               }


            })


            dispatch(queueLogin(state_value.background.websocketconnection, data))
            dispatch(queueLogout(state_value.background.websocketconnection, data2))
            if (selectCamp.call_type == "POWER" || selectCamp.call_type == "AUTO") {
               SetPredictivebreak(false)
               PredictiveAction()
            }
            EventEmitter.emit('error-message-getter', {
               action: 'ready',
               module: "Agent ready successfully",
            })
         }
      }
   }

   let PredictiveAction = () => {
      SetPredictivebreak(!Predictivebreak)
      let payload = { user: localStorage.getItem('user'), dialer_flag: !Predictivebreak ? 0 : 1 }
      dispatch(AsterUpdateAgent(payload)).then((res) => {
         if (res.status == 200) {
            getAgentdata()
         }
      })

   }

   function getAgentdata() {
      dispatch(liveagentdata(localStorage.getItem('user')))
         .then(function (res) {
            if (res.status == 200) {
               setCallEvent(res.data.data.data1)
               if (res.data.data.dialer_flag != null) {
                  SetPredictivebreak(res.data.data.dialer_flag ? false : true)
                  setwrapup((set)=>{
                     set.field[0].value = res.data.data.dialer_flag ? false : true
                     return set
                  })
               }
            }
         }
         )
         .catch((err) => {
         })
   }
   const logoStyle = () => {
     
         return 'navbar-logo-img'
     
   }

   const announcementapi = async () => {
      let apicall = await dispatch(Getannouncement())
 
      if (apicall.length > 0) {
         let str = ""
         for (let i = 0; i < apicall.length; i++) {
            let element
            if (i === 0) {
               element = "| " + apicall[i].announcement + " |";
            } else {
               element = apicall[i].announcement + " |";
            }
 
 
            str += element
         }
 
         setannouncementdata(str)
      } else {
         props.announcementresize()
      }
   }
   return (
      <>
         <Dialogbox
            fields={closepopup}
            value={popUpData}
            className="dialog-body-default1"
            cancel={{ display: 'none' }}
            save={'disabled'}
         />

         <Dialogbox
            fields={closeAnnouncement}
            value={Announcementpopupdata}
            defaultclass={'dialog-body-default'}
         />
         <Dialogbox
            fields={wraptime_response}
            value={wrapup}
         />
         {show && (
            <Tost
               delay={delay}
               button_value={button_value}
               note={note}
               close={close}
               show={show}
               HeaderClass={HeaderClass}
               button_txt={button_txt}
               tosterdata={displaydata}
               header={header}
            ></Tost>
         )}
         <div className="navbar-main-container">
         {announcementdata.length > 0 && <div className='navbar-main-container-top'>
               <div id="scroll-text">
                  {announcementdata}</div>
            </div>}
            {/* {announcementdata.length === 0 && <div className='navbar-main-container-top'>
               <div id="scroll-text-noannouncement">
                  <div
                     className={
                        'material-icons'
                     }
                  >
                     campaign
                  </div>
                  <div  className={
                        'announcement-text'
                     }>
                     {"Any Announcement , will be published here"}
                  </div>  </div>
            </div>} */}
            <div className='navbar-main-container-bottom'>
            <div className="navbar-lft-container" onClick={refreshSocket} >
               <img className={`${logoStyle()} left_logoimge`} src={ JSON.parse(localStorage.getItem('metadata'))?.logo} alt="Text0" />
            </div>

            <div className="navbar-right-container">
               {AppPermission?.liveagent_data && state_value.background.socket_message.evt === "RINGING" && callevent && <p className={status.color} >{callevent}</p>}
               {AppPermission?.liveagent_data && state_value.background.socket_message.evt === "ANSWERED" && callevent && <p className={status.color} >{callevent}</p>}


               {AppPermission?.view_Performance && <div className="navbar-right-sub-container mobile_hide">
                  <NavLink to="/dashboard">
                     <>
                        <OverlayTrigger
                           placement={'bottom'}
                           overlay={<Tooltip>Dashboard</Tooltip>}
                        >
                           <div
                              className={
                                 activedashboard
                                    ? 'material-icons navbar-icons-active'
                                    : 'material-icons navbar-icons'
                              }
                           >
                              dashboard
                           </div>
                        </OverlayTrigger>
                     </>
                  </NavLink>
               </div>}

               {adminPermissions.lead && AppPermission?.lead_view && <div className="navbar-right-sub-container mobile_hide">
                  <NavLink to="/leads">
                     <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>Leads</Tooltip>}
                     >
                        <div
                           className={
                              activelead
                                 ? 'material-icons navbar-icons-active'
                                 : 'material-icons navbar-icons'
                           }
                        >

                           contact_page

                        </div>
                     </OverlayTrigger>
                  </NavLink>
               </div>}

               {adminPermissions.contact && AppPermission?.view_contacts && <div className="navbar-right-sub-container mobile_hide">
                  <NavLink to="/contact">
                     <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>Contacts</Tooltip>}
                     >
                        <div
                           className={
                              activecontacts
                                 ? 'material-icons navbar-icons-active'
                                 : 'material-icons navbar-icons'
                           }
                        >
                           contacts
                        </div>
                     </OverlayTrigger>
                  </NavLink>
               </div>}
               {adminPermissions.meeting && AppPermission?.view_meeting && <div className="navbar-right-sub-container mobile_hide">
                  <NavLink to="/meeting">
                     <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>Meetings</Tooltip>}
                     >
                        <div
                           className={
                              activeMeetings
                                 ? 'material-icons navbar-icons-active'
                                 : 'material-icons navbar-icons'
                           }
                        >
                           group
                        </div>
                     </OverlayTrigger>
                  </NavLink>
               </div>}

               {adminPermissions.ticket && AppPermission?.ticket_view && <div className="navbar-right-sub-container ">
                  <NavLink to="/tickets">
                     <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>Tickets</Tooltip>}
                     >
                        <div
                           className={
                              activeticket
                                 ? 'material-icons navbar-icons-active'
                                 : 'material-icons navbar-icons'
                           }
                        >
                           confirmation_number
                        </div>
                     </OverlayTrigger>
                  </NavLink>
               </div>}
               <p className={`${status.color}`}>{status.status}</p>
               <Dropdown show={hide}>
                  <Dropdown.Toggle id="dropdown-custom-1" className={(state_value.background.oncall == true || (state_value.call.dispoValidation === false && state_value.call.chatdispoValidation == true)) ? "logout disabledbutton" : "logout"}>

                     {props.selected_pause === 'available' ? (<span
                        className="material-icons navbar-icons"
                        style={{ fontSize: '28px', marginTop: '10px' }}
                        onClick={() => ready(true, "button")}
                     >
                        {login_action}
                     </span>) : (<span
                        className="material-icons navbar-icons"
                        style={{ fontSize: '28px', marginTop: '10px' }}
                        onClick={() => ready(false, "button")}
                     >
                        {login_action}
                     </span>)}
                  </Dropdown.Toggle>
                  {props.selected_pause === 'available' && hide &&
                     props.selected_pause != null && (
                        <Dropdown.Menu>
                           {pauseCodes.map((item) => {
                              return (
                                 <Dropdown.Item
                                    key={item}
                                    onClick={() => changePauseCode(item)}
                                 >
                                    {item}
                                 </Dropdown.Item>
                              )
                           })}
                        </Dropdown.Menu>
                     )}
               </Dropdown>
               {AppPermission?.lead_script && <div className="navbar-right-sub-container mobile_hide">
                  <NavLink to="/script">
                     <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>Script</Tooltip>}
                     >
                        <img
                           className="navbar-logo-img navbar-menu-icon navbar-whatsapp"
                           src={script}
                           alt="Text0"
                        />

                     </OverlayTrigger>
                  </NavLink>
               </div>}
               {AppPermission?.quick_access_menu_view && <div className="navbar-right-sub-containers">
                  <Dropdown autoClose={true}>
                     <Dropdown.Toggle id="dropdown-custom-1" className="logout">
                        <OverlayTrigger
                           placement={'bottom'}
                           overlay={<Tooltip>Menu</Tooltip>}
                        >
                           <div className={activemenu ? "material-icons navbar-icons-active" : "material-icons navbar-icons "}>list</div>
                        </OverlayTrigger>
                     </Dropdown.Toggle>

                     <Dropdown.Menu onToggle={"rootClose"}>
                        <div className="navbar-menu-container">
                           <div className="navbar-menu-header">Navigation</div>
                           <Dropdown.Divider />
                           <div className="navbar-menu ">
                              {AppPermission?.view_Performance && <div className="navbar-menu-sub-div">
                                 <NavLink to="/dashboard" style={navLink}>
                                    {' '}
                                    <div className="material-icons navbar-menu-icon">
                                       dashboard
                                    </div>
                                    <div className="navbar-menu-icon-text">dashboard</div>
                                 </NavLink>
                              </div>}

                              {adminPermissions.lead && AppPermission?.lead_view && <div className="navbar-menu-sub-div">
                                 <NavLink to="/leads" style={navLink}>
                                    {' '}
                                    <div className="material-icons navbar-menu-icon">
                                       contact_page
                                    </div>
                                    <div className="navbar-menu-icon-text">Leads</div>
                                 </NavLink>
                              </div>}

                              {adminPermissions.meeting && AppPermission?.view_meeting && <div className="navbar-menu-sub-div">
                                 <NavLink to="/meeting" style={navLink}>
                                    <div className="material-icons navbar-menu-icon">
                                       group
                                    </div>
                                    <div className="navbar-menu-icon-text">Meetings</div>
                                 </NavLink>
                              </div>}

                              {adminPermissions.ticket && AppPermission?.ticket_view && <div className="navbar-menu-sub-div">
                                 <NavLink to="/tickets" style={navLink}>

                                    <div className="material-icons navbar-menu-icon">
                                       confirmation_number
                                    </div>
                                    <div className="navbar-menu-icon-text">Ticket</div>
                                 </NavLink>
                              </div>}

                              {adminPermissions.contact && AppPermission?.view_contacts && <div className="navbar-menu-sub-div">
                                 <NavLink to="/contact" style={navLink}>

                                    <div className="material-icons navbar-menu-icon">
                                       contacts
                                    </div>
                                    <div className="navbar-menu-icon-text">Contacts</div>
                                 </NavLink>
                              </div>}

                              {AppPermission?.voicemail_view && <div className="navbar-menu-sub-div">
                                 <NavLink to="/voicemail" style={navLink}>
                                    <div className="material-icons navbar-menu-icon">
                                       voicemail
                                    </div>
                                    <div className="navbar-menu-icon-text">voicemail</div>
                                 </NavLink>
                              </div>}

                           </div>
                           <div className="navbar-menu-header">Menu</div>
                           <Dropdown.Divider />
                           <div className="navbar-menu ">
                              {AppPermission?.lead_script && <div className="navbar-menu ">
                                 <NavLink to="/script" style={navLink}>
                                    <div className="navbar-menu-sub-div">
                                       <img
                                          className="navbar-logo-img navbar-menu-icon navbar-whatsapp"
                                          src={script}
                                          alt="Text0"
                                       />
                                       <div className="navbar-menu-icon-text">Script</div>
                                    </div>
                                 </NavLink>
                              </div>}

                              {AppPermission?.wa_view && <div
                                 className="navbar-menu "
                                 onClick={() => {
                                    switchToWhatsapp()
                                 }}
                              >
                                 <div className="navbar-menu-sub-div">
                                    <img
                                       className="navbar-logo-img navbar-menu-icon navbar-whatsapp"
                                       src={whatsapp}
                                       alt="Text0"
                                    />
                                    <div className="navbar-menu-icon-text">Whatsapp</div>
                                 </div>
                              </div>}
                              {AppPermission?.wa_view && <div
                                 className="navbar-menu "
                                 onClick={() => {
                                    switchToFacebook()
                                 }}
                              >
                                 <div className="navbar-menu-sub-div">
                                    <img
                                       className="navbar-logo-img navbar-menu-icon navbar-whatsapp"
                                       src={fbchat_}
                                       alt="Text0"
                                    />
                                    <div className="navbar-menu-icon-text">Facebook</div>
                                 </div>
                              </div>}
                              {AppPermission?.webchat_view && <div
                                 className="navbar-menu "
                                 onClick={() => {
                                    switchTowebchat()
                                 }}
                              >
                                 <div className="navbar-menu-sub-div">
                                    <img
                                       className="navbar-logo-img navbar-menu-icon navbar-whatsapp"
                                       src={webchat_}
                                       alt="Text0"
                                    />
                                    {/* <img className='web_chatimg web_chatimg_window' src={webchat_} alt="test" /> */}
                                    <div className="navbar-menu-icon-text">WebChat</div>
                                 </div>
                              </div>}

                           </div>
                        </div>
                     </Dropdown.Menu>
                  </Dropdown>
               </div>}

               {(selectCamp.call_type == "POWER" || selectCamp.call_type == "AUTO") &&
                  <OverlayTrigger
                     placement={'bottom'}
                     overlay={<Tooltip>{Predictivebreak ? "Dialer Ready" : "Dialer Pause"}</Tooltip>}
                  >
                     <div className={`${Predictivebreak ? 'parse-txt-btn ava-bgclr' : 'parse-txt-btn parse-ylo-bgclr'}`}>
                        <p style={{ cursor: "pointer" }} className="material-icons" onClick={() => { PredictiveAction() }}>
                           {Predictivebreak ? 'phone_in_talk' : 'phone_paused'}
                        </p>
                     </div>
                  </OverlayTrigger>}
               <div className="navbar-right-sub-container-clock mobile_hide">
                  {seconds != -1 && <h3 className="navbar-timer" style={{ color: timerColor }}>
                        {breakOverPopup.open && "- "}
                        {'0' +
                           Math.floor((seconds / 3600) % 60) +
                           ':' +
                           ('0' + Math.floor((seconds / 60) % 60)).slice(-2) +
                           ':' +
                           ('0' + Math.floor((seconds / 1) % 60)).slice(-2)}
                     </h3>}{
                        seconds === -1 && <h3 className="navbar-timer" style={{ color: timerColor }}>00:00:00</h3>
                     }
               </div>


               {AppPermission?.notification && <div className='mobile_hide'>

                  <Sidebar announcementNotificationcount={announcementNotificationcount} meetingNotificationcount={meetingNotificationcount} WaNotificationcount={WaNotificationcount} type={'notification'} chatdrop={chatdrop}></Sidebar>
               </div>}
               </div>
               <div className='navbar-profile-main '>

                  <Sidebar type={'profile'}></Sidebar>
               </div>
            </div>
            <Dialogbox
               fields={closebreakpopup}
               value={breakOverPopup}
               defaultclass={'dialog-body-default'}
            />
         </div>
      </>
   )
}
const mapStateToProps = (state) => {
   return {

      selected_pause: state.detail.selected_pause,
      selected_queue: state.detail.selected_queue,
      token: state.login.token,
      user: state.detail.user,
      pausecode: state.detail.pause_codes,
      selected_campaign: state.detail.selected_campaign,
      queues: state.detail.queues,
   }
}
const mapDispatchToProps = (dispatch) => {
   return {

      set_pause: (type) => dispatch(setPause(type)),
      set_queue: (type) => dispatch(setQueue(type)),
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav)