import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTable, useRowSelect } from "react-table";
import { autodial, autodialdatas } from "../redux/Calls/CallAction";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner'
import EventEmitter from "../utils/EventEmitter"
import Format from "../utils/format-text";
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);


function Table1({ checkboxvalue, columns, data, selected_data, loader}) {
  let AppPermission
  let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const location = useLocation()
  let dispatch = useDispatch()
  let stateValue = useSelector((state) => state);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } =
    useTable(
      {
        columns,
        data,
      },
      useRowSelect,
      (hooks) => {
        if (location.pathname === `/leads` && AppPermission.auto_dial && stateValue.call.autodialstatus == false && AppPermission.auto_dial && campaigninfo.call_type == "PREVIEW"  ) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  {
                    checkboxvalue === true &&

                    < IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  }
                </div>
              ),
              Cell: ({ row }) => (

                <div>
                  {
                    checkboxvalue === true &&
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  }
                </div>
              ),
            },
            ...columns,
          ]);


        }
      }
    );


  useMemo(() => {

    let selectedvalue = selectedFlatRows.map((elm) => elm.original)
    if (selectedFlatRows.length > 0 && stateValue.call.autodialstatus == false && stateValue.background.oncall == false) {
      selected_data(selectedvalue)
      dispatch(autodialdatas(selectedvalue))
      EventEmitter.emit("auto-dial", "true")
    } else if (selectedFlatRows.length == 0 && stateValue.call.autodialstatus == false) {
      dispatch(autodialdatas(selectedvalue))
      EventEmitter.emit("auto-dial", "false")
    }
  }, [selectedFlatRows])
  function celldata(params, i) {
    if (i === 0 && params.props.column.Header === "Profile") {
      let url = params.props.cell.value;
      if (url !== "") {
        return (
          <>
            <img className="img_profile" src={url} alt="Profile" />
          </>
        );
      } else {
        <p className="align_content">{params}</p>;
      }
    } else {
      if (params.props.column.masking === true) {
        let formattedValue = Format(params.props.row.values[params.props.column.id].toString(), { maskbool: true });
        return (
          <>
            <p className="align_content">{formattedValue}</p>
          </>
        );
      } else {
        return (
          <>
            <p className="align_content">{params}</p>
          </>
        );
      }
    }
  }


  const dynamicStyles = {
    color: true ? 'green' : 'red',
    fontSize: true ? '16px' : '12px',
    // Add more styles as needed
  };
  const formater = (value) => {
    if (typeof value === 'string') {
        return value.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    } else {
        return value;
    }
}


  return (
    <>
   
    <Table className="lead" {...getTableProps()}>
      {loader ===false &&<thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              column.render("Header")!=='Settings'?(
              <th {...column.getHeaderProps()} style={{ width: "200px" }}>{formater(column.render("Header"))}</th>
              ):(
                <th {...column.getHeaderProps()} onClick={() => { }} style={{ width: "200px" }}>
                  {column.render("Header")}
                </th>
              )
            ))}
          </tr>
        ))}
      </thead>}
      {rows.length > 0 && loader == false && (
        <tbody {...getTableBodyProps()} style={{ display: columns.length <= 1 ? 'none' : '' }}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, j) => (
                  <td className={j === 0 ? "min-td" : ""} {...cell.getCellProps()}>
                    <div style={{
                      backgroundColor: cell.render("Cell").props.column.color ? data[i].all?.phone_number_color?.code : 'white',
                      fontSize: '16px',
                      borderRadius: "5px",
                      color: cell.render("Cell").props.column.color ? data[i].all?.phone_number_color?.text_color : 'black',
                      marginTop:'8px',
                      paddingRight: "5px",
                      paddingLeft: "5px",
                      width: "200px",
                    }} className="txt-ohide">
                      {celldata(cell.render("Cell"), j)}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      )}
    </Table>
  
  {loader == true && <LoadingSpinner size="larger" />}
  {rows.length === 0 && loader == false &&
    <div className="nodata" style={{ margin: "auto" }}>No Data</div>
  }
</>

  );
}

export function LeadTable(props) {
  const value = props.leaddetail;
  const columns = React.useMemo(() => props.header, [props.header]);
  function selected_data(data) {
  }

  return (
    <>
      <Table1 checkboxvalue={props.checkbox} columns={columns} data={value} selected_data={selected_data} loader={props.loader}/>
    </>
  );
}

export default LeadTable;



