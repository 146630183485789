import React, { useState, useEffect, useMemo } from 'react'
import TicketSidebar from '../components/Lead/Leadsideview'
import TicketMainView from '../components/Tickets/TicketMainView'
import '../assets/css/Leads.css'
import EventEmitter from '../utils/EventEmitter'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { callLeadDetail } from '../redux/background/BackgroundAction'
import { GetAsterCustommoduleData } from '../redux/actions/ContactActions'
import {
  TicketFetch,
  TicketCustomformFields,
} from '../redux/actions/TicketAction.js'
import { Decode } from '../utils/EncodeDecode'
function TicketView(props) {
  let Dispatch = useDispatch()
  const [name, setname] = useState()
  let { phone_number, ticket_id } = useParams()
  phone_number =Decode(phone_number)
  let state_value = useSelector((state) => state)
  const [create, setcreate] = useState(false)
  const [formData, setformData] = useState({})
  const [isthere, setisthere] = useState(false)
  const [formfield, setformfield] = useState([])



  function fetchapi(params) {
    mount()
  }
  useMemo(() => {

    mount()
  }, [ticket_id])

  function mount() {
    let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
    Dispatch(TicketCustomformFields()).then(async(response) => {
     
      for (let i = 0; i < response.data.fields.length; i++) {
        const element = response.data.fields[i];
        if (element.catagory_value === "Custom module") {
          let url = `?template_name=${element.model}&skip=${0}&take=${0}&takeall=true`
          let payload ={
            url,
            bodydata:{
              template_name: element.model,
              fetch_type: 'internal',
            }
          }
        await  Dispatch(GetAsterCustommoduleData(payload)).then((res) => {
          if (res.data.statusCode === 200) {
              element.values = JSON.stringify(res.data.data.map((elm) => {
                return { name: elm.title, value: elm.title };
              }))

            }
          }).catch((err) => {
           
            EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: 'Custom module data',
            })
          })
        }

      }
      setformfield(response.data)

      if (props.ticketmodule) {
        setformData({ status: 204, data: "" })
        setcreate(true)
        setisthere(true)
        let leadData = {
          lead_id: "",
          lead_name: "",
          phone_number: "",
          module: "ticket",
          render: 1
        }
        Dispatch(callLeadDetail(leadData))
        if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
          localStorage.setItem("dialpadname", "Unknown Number");
          setname(localStorage.getItem("dialpadname"))
        }

      } else {
        // http://localhost:4001
        Dispatch(
          TicketFetch(
            `/tickets/fetch?ticket_id=${ticket_id}&portal=false&All=false&template_name=${campaigninfo.template_name
            }&phone_number=${phone_number}`,
          ),
        ).then((res) => {
          if (res.status == 204) {
            setcreate(true)
            setisthere(true)
            setformData(res)

            if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
              localStorage.setItem("dialpadname", "Unknown Number");
              setname(localStorage.getItem("dialpadname"))
            }
          } else if (res.data.statusCode == 200) {


            if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === phone_number) {
              let leadData = {
                lead_id: res.data.data[0].others.ticket_id,
                lead_name: res.data.data[0].basic.first_name,
                phone_number: res.data.data[0].others.phone_number,
                module: "ticket",
                render: 1
              }
              let names = res.data.data[0].basic.first_name
              if (names === "") {
                names = res.data.data[0].basic.phone_number
              }
              localStorage.setItem("dialpadname", names);
              setname(localStorage.getItem("dialpadname"))
              Dispatch(callLeadDetail(leadData))
            }

            setisthere(true)
            setformData(res)
            setcreate(false)



          }
        }).catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: 'ticket',
          })
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'form fields',
      })
    })
  }
  return (
    <div className="page-main-container page-main-container-mobile">
      {isthere && (
        <>
          <div className="scroll-lft leads-lft">
            {create === false && (
              <div className="leads-sidebar">
                <TicketSidebar
                  formdatas={formData}
                  type={"ticket"}
                  id={phone_number}
                />
              </div>
            )}
            <div
              className={
                create
                  ? 'leads-main leads-main-new'
                  : 'leads-main leads-main-exist'
              }
            >
              <TicketMainView
                fetchapi={fetchapi}
                operation={create}
                formdatas={formData}
                statedata={formfield}
                type={"ticket"}

              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default TicketView
