import api from "../../utils/axios-util";
import localdomain from "../../utils/constant"
const auth0Config = localdomain();
const common_api_url = auth0Config.common_api_url;

export const agentdailywork = (query) => {

    let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agent/activity/count?user=${query.agent}&campaign=${query.campaign}&template_name=${campaigninfo.template_name}&team=${JSON.parse(localStorage.getItem("Teaminfo"))?.name}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const leadscore = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/doocity/call_activity-count?id=${payload.id}&module=${payload.module}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const getcalllog = (query, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/dashboard/call_log/fetch?user=${query.agent}&campaign=${query.campaign}&type=${type}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};

export const inboundOutbound = (payload, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/inbound/outbound/report?user=${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};


export const agentworkhour = (payload, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agentwork/time?user=${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};

export const callreports = (payload, type) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/call/report?user=${payload}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

};
export const wa_call_reports = (query) => {

    let query1 = ""
    if (query.dispo_status) {
        query1 = ` &dispo_status=${query.dispo_status}`
    }

    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agentworkreport?user=${query.agent}&date=${query.type}&wa_permit=${query.wa_permit}&chartView=${query.chartView}` + query1)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
export const getdispocount = (query) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .get(`/agent/dispo/count?user=${query.user}&template_name=${query.template_name}&module=${query.module}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

export const postdispo = (payload) => {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            await api
                .post(`/add/dispo`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}


export const zohodashboard = (query) => {
    return async (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await api.get(`${common_api_url}/getviews?role=${query.role}`);
    
          dispatch({ type: 'FETCH_DASHBOARD_SUCCESS', payload: response.data });
 
          resolve(response);
        } catch (err) {
          dispatch({ type: 'FETCH_DASHBOARD_ERROR', error: err.message });
          reject(err);
        }
      });
    };
  };

  export const zohodashboardurl = (viewId)=> {
    return async (dispatch, getState) => {
    return new Promise(async(resolve, reject) => {
        try {
            const response = await api.get(`${common_api_url}/geturl/${viewId}`);
      
            dispatch({ type: 'FETCH_DASHBOARD_URL_SUCCESS ', payload: response.data });
    
            resolve(response.data);
          } catch (err) {
            dispatch({ type: 'FETCH_DASHBOARD_ERROR', error: err.message });
            reject(err);
          }
      });
    }
}


//    Axios.get(`http://0.0.0.0:8118/geturl/${viewId}`)
// .then(response => {
//     commit(types.COMMIT_NOTHING);
//     resolve(response);
//   })
//   .catch(error => {
//     reject(error);
//   });