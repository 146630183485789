import { Navigate,Outlet } from "react-router-dom";
import { connect } from 'react-redux';
import Layout from './layout/Layout'
import Unauth from './pages/Error/Unauth'

const AppPermission = (props) => {

    let AppPermissions 
    if (localStorage.getItem('config')) {
      AppPermissions = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    if(((props.type==="/dashboard" &&  AppPermissions.view_Performance )|| (props.type==="/custom-module" &&  AppPermissions.view_custommodule )|| (props.type==="/leads"  &&  AppPermissions.lead_view) || (props.type==="/tickets"  &&  AppPermissions.ticket_view) || (props.type==="/tickets/create"  &&  AppPermissions.ticket_create)   || (props.type==="/meeting"  &&  AppPermissions.view_meeting)|| (AppPermissions.voicemail_view && props.type==="/voicemail"  )
    ||(AppPermissions.contact_create && props.type==="/contact/create"  )||(AppPermissions.contact_create && props.type==="/contact/create/lead:phone_number"  )||(AppPermissions.lead_create && props.type==="/leads/create"  )
     ||(AppPermissions.lead_script && props.type==="/script"  )||(AppPermissions.view_contacts && props.type==="/contact"  )) 
    ){
      return <Layout />
    }else{
        return <Unauth />
    }
}

export default AppPermission;

