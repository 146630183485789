import '../../assets/css/timeline.css'
import React, { useState, useEffect, useRef } from 'react'
import { CallActivity, HistoryTimeline } from '../../redux/actions/Asterleads'
import { useParams } from 'react-router-dom'
import EventEmitter from '../../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import call from '../../assets/images/timeline/call.svg'
import { getfilterdata } from '../../redux/actions/Asterleads'
import note from '../../assets/images/timeline/note.svg'
import format from '../../utils/format-text'
import pen from '../../assets/images/timeline/pen.svg'
import link from '../../assets/images/timeline/link.svg'
import unlink from '../../assets/images/timeline/unlink.svg'
import duplicate from '../../assets/images/timeline/duplicate.svg'
import revert from '../../assets/images/timeline/revert.svg'
import create from '../../assets/images/timeline/create.svg'
import deleteicom from '../../assets/images/timeline/delete.svg'
import contact from '../../assets/images/timeline/contact.svg'
import whatsapp from '../../assets/images/timeline/whatsapp.svg'
import download from '../../assets/images/timeline/download.svg'
import speaker from '../../assets/images/timeline/speaker.svg'
import mutespeaker from '../../assets/images/timeline/mutespeaker.svg'
import historyimg from '../../assets/images/timeline/history.svg'
import Wadialog from '../wa-coversation-popover'


import auto from '../../assets/images/timeline/auto.svg'
import incoming from '../../assets/images/timeline/incoming.svg'
import missed from '../../assets/images/timeline/missed.svg'
import noanswer from '../../assets/images/timeline/noanswer.svg'
import outgoing from '../../assets/images/timeline/outgoing.svg'
import preview from '../../assets/images/timeline/preview.svg'



import { setaudio, playaudio } from '../../redux/Lead/LeadAction'
import wp from '../../assets/images/wp.png'
import InfiniteScroll from 'react-infinite-scroll-component'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Popover from 'react-bootstrap/Popover'

import fromat from '../../utils/format-text'
import { TicketHistory } from '../../redux/actions/TicketAction'
import { Decode } from '../../utils/EncodeDecode'
function LeadTimeline(props) {
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let { phone_number } = useParams()
  phone_number = Decode(phone_number)
  let agent = localStorage.getItem('user')
  let stateValue = useSelector((state) => state)
  let dispatch = useDispatch()
  const [leadids,setleadids]=useState([])
  const [pophistoryopen, setpophistoryopen] = useState(false)
  const [wapopupdata, setwapopupdata] = useState({})
  const [Activity, setActivity] = useState([])
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [skipdata, setskipdata] = useState({ skip: 0, take: 10 })
  const [count, setcount] = useState(0)
  const [history, sethistory] = useState([])
  const [playerduration, setplayerduration] = useState({})
  const [rectimer, setrectimer] = useState(false)
  const [seconds, setSecond] = useState(0)
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"));
  useEffect(() => {
    var interval1 = null
    if (rectimer) {
      interval1 = setInterval(() => {
        setSecond((s) => s + 1)


      }, 1000)
    } else {
      clearInterval(interval1)
      setSecond(0)
    }
    return () => clearInterval(interval1)
  }, [rectimer])

  useEffect(() => {
    let sec =
      '0' +
      Math.floor((seconds / 3600) % 60) +
      ':' +
      ('0' + Math.floor((seconds / 60) % 60)).slice(-2) +
      ':' +
      ('0' + Math.floor((seconds / 1) % 60)).slice(-2)
    if (sec === playerduration.duration) {
      changeMute(playerduration, false)
    }

  }, [seconds])

  useEffect(() => {
    if (props.type === 'ticket') {
      mount(skipdata, "ticket", 'mount')
    }
    else if (props.type === 'contact') {
      let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
      let source = []
      if (Object.keys(teamdata).length > 0) {
        if (teamdata.type == "all" || teamdata.type == "source") {
          source = teamdata.type_data.source.map((elm) => elm.name)
        }
      }
      const payload = {
        user_name: localStorage.getItem('user'),
        source: source,
        campaign_name: localStorage.getItem('selectedCampaign'),
        filter_data: {

          "hopper_status": {
            "label": "No",
            "value": "0"
          },
          "isclosed": {
            "label": "No",
            "value": "false"
          }, "associated_id": {
            "label": 'contact_id',
            "value": props.id
          }

        },
        module: 'lead',

      }

      dispatch(getfilterdata(payload))
        .then((response) => {
          let arr = []
          if (response.status === 200) {

            for (let i = 0; i < response.data.data.length; i++) {
              const element = response.data.data[i].lead_id;
              arr.push(element)
            
            }
            setleadids(arr)
          }
          mount(skipdata, "contact", 'mount', arr)
        })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: 'leads',
          })
        })

    }
    else {
      mount(skipdata, "lead", 'mount')
    }
    return () => {
      dispatch(playaudio(stateValue.lead.audio, false))
    };
  }, [])
  function mount(skiptakevalue, module, value, arrval = leadids) {

    skiptakevalue['id'] = props.id

      skiptakevalue['lead_id'] = arrval

    skiptakevalue['module'] = module
    if (props.view === "action") {
      skiptakevalue['phone_number'] = phone_number
      dispatch(CallActivity(skiptakevalue))
        .then(async function (response) {
          if (response.data.statusCode === 200) {
            setcount(response.data.totalCount)
            setskipdata((set) => {
              if (value === 'mount') {
                set.skip = 0
                set.take = 10
              } else {
                set.skip = skiptakevalue.skip
                set.take = 10
              }
              delete set.phone_number
              delete set.id
              delete set.name
              return {
                ...set,
              }
            })
            setActivity(Activity.concat(response.data.data))
          } else {
            setActivity([])
          }
        })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: 'activity',
          })
        })
    } else {
      skiptakevalue['template_name'] = campaigninfo.template_name

      dispatch(HistoryTimeline(skiptakevalue))
        .then(async function (response) {
          if (response.data.statusCode === 200) {
            setcount(response.data.totalCount)
            setskipdata((set) => {
              if (value === 'mount') {
                set.skip = 0
                set.take = 10
              } else {
                set.skip = skiptakevalue.skip
                set.take = 10
              }
              delete set.phone_number
              delete set.id
              delete set.name
              return {
                ...set,
              }
            })
            setActivity(Activity.concat(response.data.data))
          } else {
            setActivity([])
          }
        })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: 'history',
          })
        })
    }
  }



  const fetchMoreData = () => {
    if (Activity.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout((v) => {
        let data = {
          skip: skipdata.skip + 10,
          take: 10,
        }
        if (props.type === 'ticket') {
          mount(data, "ticket")
        }
        else if (props.type === 'contact') {
          mount(data, "contact")
        }
        else {
          mount(data, "lead")
        }

      }, 500)
    }
  }

  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' year ago'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' month ago '
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' day ago'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hour ago'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minute ago'
    }
    return Math.floor(seconds) + ' second ago'
  }

  function changeMute(ele, value) {
    if (ele.rec_url !== null) {
      setplayerduration(ele)
      let data = []
      for (let i = 0; i < Activity.length; i++) {
        const element = Activity[i]
        if (element.id === ele.id) {
          if (element.sound === false) {
            setrectimer(true)
            element.sound = true

            dispatch(setaudio(new Audio(ele.rec_url)))
            if (stateValue.lead.audio === '') {
              dispatch(playaudio(new Audio(ele.rec_url), value))
            } else {
              dispatch(playaudio(stateValue.lead.audio, value))
            }
          } else {
            setrectimer(false)
            element.sound = false
            dispatch(playaudio(stateValue.lead.audio, value))
          }

        } else {
          if (value) {
            delete element.sound
          } else {
            element.sound = false
          }
        }

        data.push(element)
      }
      setActivity(data)
    } else {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'Call Recording',
      })
    }
  }
  function gethistory(ele, value) {
    setwapopupdata(ele)
    setpophistoryopen(true)
  }
  function close() {
    setwapopupdata({})
    setpophistoryopen(false)
  }

  function timeformate(data) {
    const today = new Date(data);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let hh = today.getHours();
    let MM = today.getMinutes();
    let ss = today.getSeconds();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (hh < 10) hh = "0" + hh;
    if (MM < 10) MM = "0" + MM;
    if (ss < 10) ss = "0" + ss;

    const formattedToday =
      yyyy + "-" + mm + "-" + dd + " " + hh + ":" + MM + ":" + ss;
    return formattedToday;
  }
  return (
    <>
      <Wadialog close={close} channel={wapopupdata.channel} open={pophistoryopen} data={wapopupdata} phone_number={phone_number}></Wadialog>
      <div style={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div
          id="timelineDiv"
          style={{
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <InfiniteScroll
            scrollableTarget="timelineDiv"
            dataLength={Activity.length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={infinitScroll.more}
          >
            {Activity.length > 0 && (
              <ul className="timeline">
                
                {Activity.map((ele, index) => {
                  return (
                    <>

                      {props.view === 'action' &&
                        <li className="timeline-inverted">
                          
                          {ele.channel === 'call' && (
                            <div className="timeline-badge">
                              {ele.icon === "missed" && <img
                                className="timeline-badge-image"
                                src={missed}
                                alt="test"
                              />}
                              {ele.icon === "noanswer" && <img
                                className="timeline-badge-image"
                                src={noanswer}
                                alt="test"
                              />}
                              {ele.icon === "inbound" && <img
                                className="timeline-badge-image"
                                src={incoming}
                                alt="test"
                              />}
                              {ele.icon === "outbound" && <img
                                className="timeline-badge-image"
                                src={outgoing}
                                alt="test"
                              />}
                              {ele.icon === "auto" && <img
                                className="timeline-badge-image"
                                src={auto}
                                alt="test"
                              />}
                              {ele.icon === "preview" && <img
                                className="timeline-badge-image"
                                src={preview}
                                alt="test"
                              />}
                            </div>
                          )}
                          {ele.channel === 'note' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={note}
                                alt="test"
                              />
                            </div>
                          )}
                          {ele.channel === 'whatsapp' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={whatsapp}
                                alt="test"
                              />
                            </div>
                          )}
                          <div className="timeline-panel">
                            <div className="flex-container flex-around">
                              <div className="timeline-detail-sub-container">
                                <div className="timeline-head-flex">
                                  <div className="timeline-head-lft">
                                    <span>{format(ele.header)} -</span>
                                    {format(ele.module)} -  {format(ele.lead_id)}
                                    {ele.channel === 'call' && AppPermission.recording_play &&
                                      ele.duration != "00:00:00" &&
                                      (
                                        <span>
                                          {ele.sound === false && (
                                            <img
                                              onClick={() => {
                                                changeMute(ele, true)
                                              }}
                                              className={stateValue.background.oncall == false ? "timeline-action-image" : "timeline-action-image disabledbutton"}
                                              src={speaker}
                                              alt="test"
                                            />
                                          )}
                                          {ele.sound === true && (
                                            <img
                                              onClick={() => {
                                                changeMute(ele, false)
                                              }}
                                              className={stateValue.background.oncall == false ? "timeline-action-image" : "timeline-action-image disabledbutton"}
                                              src={mutespeaker}
                                              alt="test"
                                            />
                                          )}
                                          {ele.sound === true && <div style={{ color: "#3e98c7" }}> {'0' +
                                            Math.floor((seconds / 3600) % 60) +
                                            ':' +
                                            ('0' + Math.floor((seconds / 60) % 60)).slice(-2) +
                                            ':' +
                                            ('0' + Math.floor((seconds / 1) % 60)).slice(-2)}</div>}
                                          {ele.sound === false && AppPermission.download_audio && (
                                            <span>
                                              <a href={ele.rec_url}>
                                                <img

                                                  className={stateValue.background.oncall == false ? "timeline-action-image" : "timeline-action-image disabledbutton"}
                                                  src={download}
                                                  alt="test"
                                                />
                                              </a>

                                            </span>
                                          )}
                                        </span>
                                      )}
                                    {ele.channel === 'whatsapp' && (
                                      <span>

                                        {ele.history === false && AppPermission.timeline_wa_chat_history && (
                                          <>
                                            {/* <Popover
                                              show={true}
                                              className="lead-popover"
                                              placement='right'
                                            >
                                              <div>
                                                <Popover.Header
                                                  className="lead-history-chat-head"
                                                  as="h3"
                                                >
                                                  <div>
                                                    <img
                                                      className="timeline-badge-image"
                                                      src={whatsapp}
                                                      alt="test"
                                                    />
                                                  </div>
                                                  <div className="lead-whatsapp">
                                                    Whatsapp Conversation
                                                  </div>
                                                </Popover.Header>
                                                <Popover.Body className="lead-history-chat">
                                                  
                                                </Popover.Body>
                                              </div>
                                            </Popover> */}
                                            {ele.history === false && (
                                              <img
                                                onClick={() => {
                                                  gethistory(ele, ele.history)
                                                }}
                                                className="timeline-action-image"
                                                src={historyimg}
                                                alt="test"
                                              />

                                            )}


                                          </>
                                        )}
                                      </span>
                                    )}
                                  </div>

                                  <div className="timeline-head-rht">
                                    <OverlayTrigger
                                      placement={'bottom'}
                                      overlay={<Tooltip>{timeformate(ele.created_at)}</Tooltip>}
                                    >
                                      <div>
                                        {timeSince(new Date(ele.created_at))}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                                {ele.channel === 'call' && (
                                  <>
                                    <div>
                                      <span className="timeline-discription">
                                        <strong>Agent:</strong>
                                      </span>
                                      <span className="timeline-discription">
                                        {format(ele.agent)}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="timeline-discription">
                                        <strong>Call Status:</strong>
                                      </span>
                                      <span className="timeline-discription">
                                        {format(ele.status)}
                                      </span>
                                    </div>
                                    {ele.channel === "call" && <div>
                                      <span className="timeline-discription">
                                        <strong>Duration:</strong>
                                      </span>
                                      <span className="timeline-discription">
                                        {format(ele.duration)}
                                      </span>
                                    </div>}
                                  </>
                                )}
                                {ele.channel === 'note' && (
                                  <>
                                    <div>
                                      <span className="timeline-discription">
                                        {format(ele.description)}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>}

                      {props.view === 'history' && (
                        <li className="timeline-inverted">
                          {ele.type === 'Created' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={create}
                                alt="test"
                              />
                            </div>
                          )}
                          {ele.type === 'From Contact' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={contact}
                                alt="test"
                              />
                            </div>
                          )}
                          {(ele.type === 'Updated' || ele.type === 'Assign') && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={pen}
                                alt="test"
                              />
                            </div>
                          )}
                          {ele.type === 'merge' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={link}
                                alt="test"
                              />
                            </div>
                          )}
                          {ele.type === 'unlink' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={unlink}
                                alt="test"
                              />
                            </div>
                          )}{ele.type === 'duplicate' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={duplicate}
                                alt="test"
                              />
                            </div>
                          )}{ele.type === 'revert' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={revert}
                                alt="test"
                              />
                            </div>
                          )}
                          {ele.type === 'Deleted' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={deleteicom}
                                alt="test"
                              />
                            </div>
                          )}
                          {ele.type === 'Comment' && (
                            <div className="timeline-badge">
                              <img
                                className="timeline-badge-image"
                                src={note}
                                alt="test"
                              />
                            </div>
                          )}
                          <div className="timeline-panel">
                            <div className="flex-container flex-around">
                              <div className="timeline-detail-sub-container">
                                <div className="timeline-head-flex">
                                  <div className="timeline-head-lft">
                                    <span>{format(ele.header)} </span>
                                  </div>

                                  <div className="timeline-head-rht">
                                    <span>
                                      {timeSince(new Date(ele.modified_at))}
                                    </span>

                                  </div>
                                </div>

                                {ele.type === 'Comment' && (
                                  <>
                                    <div>

                                      <span className="timeline-discription">
                                        {format(ele.field_value)}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </>
                  )
                })}
              </ul>
            )}
            {Activity.length === 0 && (
              <div
                className="nodata "
                style={{ margin: '100px auto', width: '70px' }}
              >
                nodata
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default LeadTimeline
