function setlocalstorage(name, value) {
  localStorage.setItem(name, JSON.stringify(value))
}

function getlocalstorage(name) {
  return JSON.parse(localStorage.getItem(name))
}

function removelocalstorage(name) {
  return localStorage.removeItem(name)
}
function adminPermission(name) {
  let Adminpermission = JSON.parse(localStorage.getItem('permissions'))
  let ispresent=Adminpermission.indexOf(`${name}`)!==-1
  return ispresent
}

export { setlocalstorage, getlocalstorage, removelocalstorage, adminPermission }