import React, { useState, useEffect, useMemo } from 'react'
import EventEmitter from '../utils/EventEmitter'
import LeadSidebar from '../components/Lead/Leadsideview'
import LeadMainView from '../components/Lead/LeadMainView'
import { useParams, useLocation } from 'react-router-dom'
import '../assets/css/Leads.css'
import { useDispatch, useSelector } from 'react-redux'
import { CustomformData, CustomformFields } from '../redux/actions/Customform'
import { callmodule } from '../redux/Calls/CallAction'
import { callLeadDetail } from '../redux/background/BackgroundAction'
import {Encode, Decode} from '../utils/EncodeDecode'
import { GetAsterCustommoduleData } from '../redux/actions/ContactActions'

function LeadView(props) {
  const [name, setname] = useState()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const location = useLocation()

  let Dispatch = useDispatch()
  let { phone_number, meeting_id,lead_id } = useParams()
  phone_number =Decode(phone_number)
  let state_value = useSelector((state) => state)
  const [create, setcreate] = useState(false)
  const [formData, setformData] = useState({})
  const [isthere, setisthere] = useState(false)
  const [id, setid] = useState("")
  const [formfield, setformfield] = useState([])


  useMemo(() => {
    mount()
  }, [phone_number])


  function fetchapi() {
    mount()
  }


  function mount() {
    let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))

    Dispatch(CustomformFields(campaigninfo.template_name)).then(async(response) => {
     
      for (let i = 0; i < response.data.data[0].fields.length; i++) {
        const element = response.data.data[0].fields[i];
        if (element.group === "Custom module") {
          let url = `?template_name=${element.model}&skip=${0}&take=${0}&takeall=true`
          let payload = {
            url,
            bodydata:{
              template_name: element.model,
              fetch_type: 'internal',
            }
          }
        await  Dispatch(GetAsterCustommoduleData(payload)).then((res) => {
            if (res.data.statusCode === 200) {
              element.values = [JSON.stringify(res.data.data.map((elm) => {
                return { name: elm.title, value: elm.title };
              }))]

            }
          }).catch((err) => {
             EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: 'Custom module data',
            })
          })
        }

      }
     
      setformfield(response.data.data[0])
      if (props.leadmodule) {
        setformData({ status: 204, data: "", action: "create" })
        setcreate(true)
        setisthere(true)
        if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
          localStorage.setItem("dialpadname", "Unknown Number");
          setname(localStorage.getItem("dialpadname"))
        }
      } else {
        let payload = {
          template_name: campaigninfo.template_name,
          type: campaigninfo.type,
          phone_number: phone_number,
          id: lead_id == 0 ? '' : lead_id
        }
        Dispatch(CustomformData(payload)).then((res) => {
          if (res.status == 204) {
            setcreate(true)
            setisthere(true)
            setformData(res)
            if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
              localStorage.setItem("dialpadname", "Unknown Number");
              setname(localStorage.getItem("dialpadname"))
            }
          } 
          else if (res.data.statusCode == 200) {
            Dispatch(callmodule({
              id: res.data.data[0].others.lead_id,
              module: "lead",
              phone_no: res.data.data[0].others.phone_number
            }))
            // let leadData = {
            //   lead_id: res.data.data[0].others.lead_id,
            //   lead_name: res.data.data[0].others.first_name,
            //   phone_number: res.data.data[0].others.phone_number,
            //   module: "lead",
            //   render: 1
            // }
            // Dispatch(callLeadDetail(leadData))
            setid(res.data.data[0].others.lead_id)
            setisthere(true)
            setformData(res)
            setcreate(false)

            if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === phone_number) {
              let leadData = {
                lead_id: res.data.data[0].others.lead_id,
                lead_name: res.data.data[0].others.first_name,
                phone_number: res.data.data[0].others.phone_number,
                module: "lead",
                render: 1
              }
              let names = res.data.data[0].others.first_name
              if (names === "") {
                names = res.data.data[0].others.phone_number
              }
              localStorage.setItem("dialpadname", names);
              setname(localStorage.getItem("dialpadname"))
              Dispatch(callLeadDetail(leadData))
            }


          }
        }).catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'fetch',
            module: 'lead data',
          })
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'form fields',
      })
    })
  }


  return (
    <div className="page-main-container page-main-container-mobile">
      {isthere && (
        <>
          <div className="scroll-lft leads-lft">
            {create === false && (
              <div className="leads-sidebar">
                <LeadSidebar
                  formdatas={formData}
                  type={"lead"}
                  id={id}
                />
              </div>
            )}
            <div
              className={
                create
                  ? 'leads-main leads-main-new'
                  : 'leads-main leads-main-exist'
              }
            >
              <LeadMainView
                operation={create}
                formdatas={formData}
                statedata={formfield}
                type={"lead"}
                fetchapi={fetchapi}

              />
            </div>
          </div>
        </>
      )}

    </div>
  )
}

export default LeadView
