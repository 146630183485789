export const checkmemory = (payload) => {
    return (dispatch, getState) => {

        function safeStringify(obj) {
            const cache = new Set();
            const result = JSON.stringify(obj, (key, value) => {
              if (typeof value === 'object' && value !== null) {
                if (cache.has(value)) {
                  // Circular reference found, discard key
                  return;
                }
                // Store value in the cache
                cache.add(value);
              }
              return value;
            });
            cache.clear(); // Clear the cache after the operation
            return result;
          }
          
          // This function converts the size in bytes to a more readable format
          function formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
          
            const i = Math.floor(Math.log(bytes) / Math.log(k));
          
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
          }
          
          // Function to get the approximate memory usage of the Redux store
          function getReduxStoreMemoryUsage() {
            const state = getState(); // Get the current Redux state
            const stateSize = new Blob([safeStringify(state)]).size; // Use safeStringify here
            console.log(`Redux Store Memory Usage: ${formatBytes(stateSize)}`);
          }

        function getlocalStoreMemoryUsage(){
            var _lsTotal = 0,_xLen, _x;
            console.log("Localstorage : Start")
            for (_x in localStorage) {
                if (!localStorage.hasOwnProperty(_x)) {
                    continue;
                }
                _xLen = ((localStorage[_x].length + _x.length) * 2);
                _lsTotal += _xLen;
                console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
            };
            console.log("Localstorage Store Memory Usage:" + (_lsTotal / 1024).toFixed(2) + " KB");
            console.log("Localstorage : End")
        }
        
        setInterval(() => {
            getlocalStoreMemoryUsage()
            getReduxStoreMemoryUsage();
        }, 60000);  
  
    }
};