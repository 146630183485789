
import React, { useEffect, useState, useRef } from 'react'
import { Form, Dropdown, Row, Col, ListGroup, OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import EventEmitter from "../../utils/EventEmitter";
import { GetLeadStataus, getfilter, addfilter, getfilterdata } from '../../redux/actions/Asterleads'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Timer from "../Timepicker";
import moment from 'moment'
import {
  TicketTagManagement,

} from '../../redux/actions/TicketAction.js'
import { setFilterTypes } from '../../redux/customFilter/CustomFilterAction'
import { GetSource } from '../../redux/actions/Asterleads';
import Format from '../../utils/format-text.js'
import { Subdispo } from '../../redux/actions/AsterCallAction.js'
export const Customfilter = ({ ...props }) => {
  const { filtererapstyle } = props
  const [showName, setShowName] = useState(false)
  const [tags, settags] = useState([])
  const [subdispo, setsubdispo] = useState([])
  const [module, setModule] = useState()
  const [openMenu, setopenMenu] = useState(false)
  const [openfilterpopup, setopenfilterpopup] = useState(false)
  const [filterName, setfilterName] = useState("")
  const [errorFilterName, setErrorFilterName] = useState("")
  const [filterchangeval, setfilterchangeval] = useState({})
  const [list_of_Filter, setlist_of_Filter] = useState([])
  const finterNameFocus = useRef()
  const [Mode, setMode] = useState('create')
  const [favChecked, setfavChecked] = useState(false)
  const [isFavEdit, setisFavEdit] = useState(false)
  const selectedCampaign = localStorage.getItem('selectedCampaign')
  const dispatch = useDispatch()
  const [leadstatus, setleadstatus] = useState([])
  const [activeFilter, setActiveFilter] = useState({})
  const [Listdata, setlistdata] = useState(
    JSON.parse(localStorage.getItem("list")).map((elm) => {
      return { label: elm.list_id, value: elm.list_id.toString() };
    })
  );
  const [source, setsource] = useState([])
  const [dispodata, setdispodata] = useState(
    JSON.parse(localStorage.getItem("dispo")).map((elm) => {
      return { label: elm, value: elm };
    })
  );
  let statevalue = useSelector((state) => state)
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    let obj = {}
    for (var i = 0; i < props.fields.length; i++) {
      if (props.fields[i].fieldType === "DatePicker" || props.fields[i].fieldType === 'DatetimePicker') {
        obj[`${props.fields[i].model}`] = {}
        obj[`${props.fields[i].model}`]["from"] = ""
        obj[`${props.fields[i].model}`]["to"] = ""
        obj[`${props.fields[i].model}`]["isdate"] = true
      } else {
        obj[props.fields[i].model] = ""
      }
    }
    obj['from'] = ""
    obj['to'] = ""
    console.log("raghavan", 20, obj)
    setfilterchangeval(obj)
  }, [props.fields])
  useEffect(() => {
    if (props.closefilterpop) {
      setopenMenu(false)
      setShowName(false)
      setopenfilterpopup(false)
      props.closefilterpopresponse()

    }
  }, [props.closefilterpop])
  useEffect(() => {
    setModule(props.module)
    dispatch(getfilter(props.module)).then((res) => {
      if (res.status === 200) {
        const f = res.data.data
        const filtereddata = f.filter(
          (item) => item.is_active === "true"
        );
        setlist_of_Filter(filtereddata)

        props.sendlist_of_Filter(filtereddata)
      } else {
        const f = []
        const filtereddata = f.filter(
          (item) => item.is_active === "true"
        );
        setlist_of_Filter(filtereddata)

        props.sendlist_of_Filter(filtereddata)
      }

    })

    dispatch(GetLeadStataus()).then(function (response) {
      if (response.status === 200) {
        setleadstatus(response.data.data)
        let leadstatus_ = response.data.data.map((elm) => {
          if (elm.is_closed === "Y") {
            return { label: elm.name, value: elm.name, isclosed: true }
          } else {
            return { label: elm.name, value: elm.name, isclosed: false }
          }

        })
        setleadstatus(leadstatus_)
      } else if (response.status == 204) {
        setleadstatus([])
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'status',
        module: 'lead',
      })
    })
    let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))

    if (Object.keys(teamdata).length > 0) {
      if (teamdata.type == "all" || teamdata.type == "source") {
        let sourcedata = teamdata.type_data.source.map((elm) => {
          return { label: elm.name, value: elm.name };
        })
        if (sourcedata.length > 0) {
          setsource(sourcedata)
        } else {
          sourcefn()
        }
      } else {
        sourcefn()
      }
    } else {
      sourcefn()
    }
    dispatch(TicketTagManagement())
      .then((response) => {
        if (response.data.statusCode === 200) {
          let tag = response.data.data.map((elm) => {
            return { label: elm.name, value: elm.name }
          })
          settags(tag)
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'tag',
        })
      })
  }, [])
  function sourcefn() {
    dispatch(GetSource()).then((res) => {
      if (res.status == 200) {
        let sourcedata = res.data.data.map((elm) => {
          return { label: elm.name, value: elm.name }
        })
        setsource(sourcedata)
      } else if (res.status == 204) {
        setsource([])
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'Source',
        module: 'lead',
      })
    });
  }
  const getfilterdetail = (index) => {
    dispatch(getfilter(module)).then((res) => {
      if (res.status === 200) {
        const f = res.data.data
        setMode('Edit')
        setopenfilterpopup(true)
        setErrorFilterName('')
        setfilterName(list_of_Filter[index].filter_name)
        setActiveFilter(list_of_Filter[index].id)
        let obj = {}
        for (var i = 0; i < props.fields.length; i++) {
          if (props.fields[i].fieldType === "DatePicker" || props.fields[i].fieldType === 'DatetimePicker') {
            obj[`${props.fields[i].model}`] = {}
            obj[`${props.fields[i].model}`]["from"] = ""
            obj[`${props.fields[i].model}`]["to"] = ""
            obj[`${props.fields[i].model}`]["isdate"] = true
          } else {
            obj[props.fields[i].model] = ""
          }
        }
        obj['from'] = ""
        obj['to'] = ""
        console.log("raghavan", 20)

        let remoteJob = {
          ...obj,
          ...f[index].filter_data
        };
        f[index].is_favourite == "true" ? setfavChecked(true) : setfavChecked(false)
        setfilterchangeval(remoteJob)

      } else {
        const f = []
        const filtereddata = f.filter(
          (item) => item.is_active === "true"
        );
        setlist_of_Filter(filtereddata)

        props.sendlist_of_Filter(filtereddata)
      }

    })
  }
  const FilterLead = (filter_id, filterdata, filtereddataFavSend) => {
    for (const key in filterchangeval) {
      if (Object.hasOwnProperty.call(filterchangeval, key)) {
        if (filterchangeval[key] === '') {
          delete filterchangeval[key];
        }
      }
    }
    if (filtereddataFavSend === undefined && filter_id !== undefined && filterdata !== undefined) {
      let payload = {
        filter_data: filterchangeval
      }
      props.onLeadscreenFavselection(payload, "Filterstart")
    } else if (typeof filter_id === "string") {
      if (filter_id === "") {
        let payload = {
          filter_data: filterchangeval
        }
        props.onLeadscreenFavselection(payload, "Filterstart")
      } else {
        let payload = {
          filter_data: filterchangeval
        }
        const QuickFilter = props.List_of_Fav_Filter.filter(item => item.filter_name === filter_id)
        if (QuickFilter.length === 0) {
          props.onLeadscreenFavselection(payload, 'Filterstart')
        } else {
          QuickFilter[0].filter_data = filterchangeval
          props.onLeadscreenFavselection(QuickFilter[0], 'Filterstart')
        }

      }
    }
    // const payload = {
    //   user_name:localStorage.getItem('user'),
    //   source:[],
    //   campaign_name:selectedCampaign,
    //   filter_data:filterchangeval,
    //   module:'lead',

    // }
    // dispatch(getfilterdata(payload)).then((response)=>{
    //   console.log(activeFilter,'checking filter active')
    //   if(filter_id !==undefined){
    //     console.log('checking filter if ')
    //     props.dataAfterfilter(response.data.data,filter_id,filterdata)
    //   }else{
    //     console.log('checking filter else')
    //     props.dataAfterfilter(response.data.data,activeFilter)
    //   }

    //   props.setFilterCheck('check')
    //   setfilterchangeval({})
    if (filtereddataFavSend?.length > 0) {
      props.onLeadscreenFavselection(filtereddataFavSend[0], 'Filterstart')
    }
    setopenMenu(false)
    setfilterName("")
    // })
  }
  const onchangevalue = (val, data) => {
    console.log(val, "88888888888888888888888888888", data)
    if (data.fieldType === "Checkbox") {
      setfilterchangeval({ ...filterchangeval, [data.model]: val.target.checked })
    }
    else if (data.fieldType === "TextInput") {
      setfilterchangeval({ ...filterchangeval, [data.model]: val.target.value })
    }
    else if (data.fieldType === "RadioButton") {
      setfilterchangeval({ ...filterchangeval, [data.model]: val.value })
    }
    else {
      
      if (data.multiple === false) {
        setfilterchangeval({ ...filterchangeval, [data.model]: val })
      } else {
        setfilterchangeval({ ...filterchangeval, [data.model]: val })
      }
      if (data.model === "disposition") {
        dispatch(Subdispo(val.value)).then(function (value) {
          if (value.data.data!==undefined&&value.data.data.length > 0) {
            let subdispo_data = value.data.data.map((elm) => {
              return { label: elm.subdispo_id, value: elm.subdispo_id };
            })
            setfilterchangeval({ ...filterchangeval, [data.model]: val, "subdisposition": "" })
            setsubdispo(subdispo_data)
          }else{
            setfilterchangeval({ ...filterchangeval, [data.model]: val, "subdisposition": "" })
            setsubdispo([])
          }
        })
     
      }
    }



  }
  const buttonDisable = () => {
    const payloadfilter = {};
    let hasFromDate = false;
    let hasToDate = false;

    for (let key in filterchangeval) {
      if (filterchangeval[key] !== "") {
        payloadfilter[key] = filterchangeval[key];
        if (key === "from") {
          hasFromDate = true;
        }
        if (key === "to") {
          hasToDate = true;
        }
      }
    }
    if (hasFromDate && !hasToDate) {
      return true;
    }
    if (hasToDate && !hasFromDate) {
      return true;
    }
    if (Object.keys(payloadfilter).length === 0) {
      return true;
    }
    return false;
  };

  const applyfilter = async () => {
    await setShowName(true)
    if (filterName.trim() === "") {
      finterNameFocus.current.focus()
      setErrorFilterName("Please Fill Required Field")
      return
    } else {
      setErrorFilterName('')
    }
    const payloadfilter = {}
    for (var i = 0; i < Object.keys(filterchangeval).length; i++) {
      if (filterchangeval[Object.keys(filterchangeval)[i]] !== "") {
        payloadfilter[Object.keys(filterchangeval)[i]] = filterchangeval[Object.keys(filterchangeval)[i]]
      }
    }
    if (Object.keys(payloadfilter).length === 0) {
      return;
    }
    //FilterLead()
    const payload = {
      template_name: JSON.parse(localStorage.getItem('selectedCampaign_data')).template_name,
      filter_data: payloadfilter,
      user_name: localStorage.getItem('user'),
      is_favourite: favChecked ? "true" : "false",
      filter_name: filterName,
      type: Mode.toLowerCase(),
      module: module
    }
    dispatch(addfilter(payload)).then((res) => {
      if (res.status === 200) {
        if (res.data.statusCode === 409) {
          EventEmitter.emit('error-message-getter', {
            action: 'filter_Duplicate',
            module: res.data.message,
          })
          return
        }
        let id = res.data.data.id
        setfavChecked(false)
        let filtereddata
        dispatch(getfilter(module)).then((res) => {
          let filtereddataFavSend
          if (res.status === 200) {
            const f = res.data.data
            filtereddata = f.filter(
              (item) => item.is_active === "true"
            );
            setlist_of_Filter(filtereddata)
            props.sendlist_of_Filter(filtereddata)
            filtereddataFavSend = filtereddata.filter(
              (item) => item.filter_name === filterName
            );

            setMode('create')
            setopenfilterpopup(false)
            // setfilterchangeval({})
            setfilterName("")
            setShowName(false)
          } else {
            const f = []
            filtereddata = f.filter(
              (item) => item.is_active === "true"
            );
            setlist_of_Filter(filtereddata)
            props.sendlist_of_Filter(filtereddata)
          }

          FilterLead(id, filtereddata, filtereddataFavSend)
        })

      }
    })
  }

  const openeditfilter = (index, fav) => {
    setisFavEdit(fav)
    getfilterdetail(index)

  }
  useEffect(() => {
    if (openfilterpopup) {
      setErrorFilterName('')
    }
  }, [openfilterpopup]);



  const setaddfavourite = (val) => {
    setfavChecked(val.target.checked)
  }
  const onselectfav = (val) => {
    const payload = {
      template_name: JSON.parse(localStorage.getItem('selectedCampaign_data')).template_name,
      user_name: localStorage.getItem('user'),
      is_favourite: list_of_Filter[val].is_favourite === "true" ? "false" : "true",
      filter_name: list_of_Filter[val].filter_name,
      type: "fav",
      module: module
    }
    dispatch(addfilter(payload)).then((res) => {
      if (res.status === 200) {
        if (res.data.statusCode !== 200) {
          EventEmitter.emit('error-message-getter', {
            action: 'fav',
            module: res.data.message,
          })
          return
        }
        dispatch(getfilter(module)).then((res) => {
          if (res.status === 200) {

            const f = res.data.data
            const filtereddata = f.filter(
              (item) => item.is_active === "true"
            );
            setlist_of_Filter(filtereddata)
            props.sendlist_of_Filter(filtereddata)
            setMode('create')
            setopenfilterpopup(false)
            // setfilterchangeval({})
            setfilterName("")
          } else {
            const f = []
            const filtereddata = f.filter(
              (item) => item.is_active === "true"
            );
            setlist_of_Filter(filtereddata)

            props.sendlist_of_Filter(filtereddata)
          }

        })

      }
    })

  }

  const clearvalues = () => {
    if (Mode === 'create') {
      setfilterName("");
    }
    let obj = {}
    for (var i = 0; i < props.fields.length; i++) {
      if (props.fields[i].fieldType === "DatePicker" || props.fields[i].fieldType === 'DatetimePicker') {
        obj[`${props.fields[i].model}`] = {}
        obj[`${props.fields[i].model}`]["from"] = ""
        obj[`${props.fields[i].model}`]["to"] = ""
        obj[`${props.fields[i].model}`]["isdate"] = true
      } else {
        obj[props.fields[i].model] = ""
      }
    }
    obj['from'] = ""
    obj['to'] = ""
    console.log("raghavan", 20)
    setfilterchangeval(obj)
  }

  const deletefilter = (val) => {
    const payload = {
      template_name: JSON.parse(localStorage.getItem('selectedCampaign_data')).template_name,
      user_name: localStorage.getItem('user'),
      is_favourite: list_of_Filter[val].is_favourite,
      filter_name: list_of_Filter[val].filter_name,
      is_active: "false",
      type: 'delete',
      module: module
    }
    dispatch(addfilter(payload)).then((res) => {
      if (res.status === 200) {
        dispatch(getfilter(module)).then((res) => {
          if (res.status === 200) {
            const f = res.data.data
            const filtereddata = f.filter(
              (item) => item.is_active === "true"
            );
            setlist_of_Filter(filtereddata)
            props.sendlist_of_Filter(filtereddata)
            setMode('create')
            setopenfilterpopup(false)
            // setfilterchangeval({})
            setfilterName("")
          } else {
            const f = []
            const filtereddata = f.filter(
              (item) => item.is_active === "true"
            );
            setlist_of_Filter(filtereddata)

            props.sendlist_of_Filter(filtereddata)
          }

        })

      }
    })
  }
  function objectConverter(val) {
    let arr = JSON.parse(val.values[0]);
    if (val.fieldType === "RadioButton") {
      return (
        <>
          {arr.map((res, i) => {
            return (

              <Form.Check
                value={filterchangeval[`${val.model}`]}
                onChange={(e) => onchangevalue(res, val)}
                checked={filterchangeval[`${val.model}`] === res.value}
                type="radio"
                label={res.name}
              />
            );
          })}
        </>
      );
    } else if (val.fieldType === "Checkbox") {
      let val_ = filterchangeval[`${val.model}`].toString();
      return (
        <Form.Check
          value={filterchangeval[`${val.model}`]}
          onChange={(e) => onchangevalue(e, val)}
          type="checkbox"
          checked={val_ == "true"}
          label={`${val.label}`}
        />
      );
    }
    else {
      let default_value = [];
      let option_arr = [];
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        let obj = {};
        let default_val = {};
        obj["value"] = element.value;
        obj["label"] = element.name;
        option_arr.push(obj);
        if (filterchangeval[`${val.model}`] === element.value) {
          default_val["value"] = element.value;
          default_val["label"] = element.name;
          default_value.push(default_val);
        }
      }
      return (
        <>
          <Select
            key={val.model + Math.round(Math.random() * 100).toString()}
            isMulti={val.model === "hopper_status" || val.model === "isclosed" || val.model === 'disposition' ? false : true}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
            onChange={(e) => onchangevalue(e, val)}
            value={filterchangeval[`${val.model}`]}
            options={val.model === "subdisposition" ? subdispo : val.model === "hopper_status" ? [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }] : val.model === "tags" ? tags : val.model === "source" ? source : val.model == "list_id" ? Listdata : val.model == "lead_status" ? leadstatus : val.model == "disposition" ? dispodata : val.model === "isclosed" ? [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }] : option_arr}
            isSearchable={true && val.readonly ? false : true}
            menuIsOpen={val.readonly ? false : undefined}
            placeholder={val.model === "hopper_status" ? "Dial Status" : Format(val.placeholder)}
            menuPlacement={`auto`}
          />
        </>
      );
    }
  }
  // let SelectedGetData = (val) => {

  // let select_options = JSON.parse(val.values[0]);
  // if (val.multiple != true) {
  // if (
  // filterchangeval[`${val.model}`] === "" ||
  // filterchangeval[`${val.model}`] === null
  // ) {
  // return null;
  // } else {
  // if (val.model == "list_id") {
  // let listdatas = Listdata.find(
  // (elm) => elm.value == filterchangeval[`${val.model}`]
  // );
  // return {
  // value: listdatas.value,
  // label: listdatas.label,
  // };
  // }

  // if (val.model == "lead_status") {

  // let leadstatus_ = leadstatus.find(
  // (elm) => elm.value == filterchangeval[`${val.model}`]
  // );
  // // return {
  // // value: leadstatus_.value,
  // // label: leadstatus_.label,
  // // };
  // if (leadstatus_ == undefined) {
  // return null
  // } else {
  // return {
  // value: leadstatus_.value,
  // label: leadstatus_.label,
  // }
  // }
  // }
  // else {
  // let res_val = select_options.find(
  // (elm) => elm.value == filterchangeval[`${val.model}`]
  // );
  // if (res_val == undefined) {
  // return null;
  // } else {
  // return {
  // value: res_val.value,
  // label: res_val.name,
  // };
  // }
  // }
  // }
  // } else {
  // let final_res;
  // if (
  // filterchangeval[`${val.model}`] !== "" &&
  // filterchangeval &&
  // Object.keys(filterchangeval).length > 0
  // ) {
  // let splited_data = filterchangeval[`${val.model}`].split(",");
  // final_res = splited_data.map((elem) => {
  // let res_val = select_options.find((elm) => elm.value == elem);
  // if (res_val == undefined) {
  // return null;
  // } else {
  // return {
  // value: res_val.value,
  // label: res_val.name,
  // };
  // }
  // });
  // } else {
  // final_res = [];
  // }

  // return final_res;
  // }
  // };
  const DateTimeGetData = (datetime_return, model) => {

    if (datetime_return) {
      if (!filterchangeval[model] || filterchangeval[model] === '' || filterchangeval[model] === '0000-00-00 00:00:00') {
        return null;
      } else {
        const dateTime = moment(filterchangeval[model], 'YYYY-MM-DD HH:mm:ss');
        if (!dateTime.isValid()) {
          console.log("DateTimeGetData: Invalid date format");
          return null;
        } else {
          const formattedTime = dateTime.format('HH:mm');
          return formattedTime;
        }
      }
    } else {
      if (!filterchangeval[model] || filterchangeval[model] === '' || filterchangeval[model] === '0000-00-00 00:00:00') {
        return null;
      } else {
        const dateTime = moment(filterchangeval[model], 'YYYY-MM-DD HH:mm:ss');
        if (!dateTime.isValid()) {
          console.log("DateTimeGetData: Invalid date format");
          return null;
        } else {
          return dateTime.toDate();
        }
      }
    }
  };
  let DateGetData = (type, model, val_type) => {
    console.log("444444444444444444444444444")
    if (type == 'DatePicker' && val_type !== undefined) {
      if (filterchangeval[model][val_type] == null || filterchangeval[model][val_type] == '') {
        return filterchangeval[model][val_type]
      } else {
        if (filterchangeval[model][val_type] == '0000-00-00 00:00:00') {
          return null;
        }
        else if (filterchangeval[model][val_type] == '0000-00-00') {
          return null;
        } else {
          console.log(new Date(filterchangeval[model][val_type]), "444444444444444444444444444")
          return new Date(filterchangeval[model][val_type])
        }

      }
    } else if (type == 'DatePicker') {
      if (filterchangeval[model] == null || filterchangeval[model] == '') {
        return filterchangeval[model]
      } else {
        if (filterchangeval[model] == '0000-00-00 00:00:00') {
          return null;
        }
        else if (filterchangeval[model] == '0000-00-00') {
          return null;
        } else {
          return new Date(filterchangeval[model])
        }

      }
    }
    else if (type == 'TimePicker') {
      return filterchangeval[model]
    }
  }
  const onDateTimeChanges = (datetime_return, data_val, fieldval) => {
    if (fieldval === 'from' || fieldval === 'to') {
      let fieldval__ = {
        "model": fieldval
      }
      fieldval = fieldval__
    }
    if (datetime_return) {
      let datetime = new Date();
      datetime.setHours(parseInt(data_val.hour), parseInt(data_val.minute), 0);

      if (filterchangeval[fieldval.model] == "" || filterchangeval[fieldval.model] == null) {
        setfilterchangeval((set) => {
          set[`${fieldval.model}`] = datetime.toISOString();

          return {
            ...set,
          };
        });
      } else {
        let updatetime = new Date(filterchangeval[fieldval.model]);
        updatetime.setHours(
          parseInt(data_val.hour),
          parseInt(data_val.minute),
          0
        );
        setfilterchangeval((set) => {
          set[`${fieldval.model}`] = datetime.toISOString();

          return {
            ...set,
          };
        });
      }
    } else {
      let yyyy
      let mm
      let dd
      if (Object.prototype.toString.call(data_val) === '[object Date]' && !isNaN(data_val)) {
        yyyy = data_val.getFullYear();
        mm = data_val.getMonth() + 1;
        dd = data_val.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;
        if (filterchangeval[fieldval.model] == "" || filterchangeval[fieldval.model] == null) {

          if (fieldval.model === "from") {
            setfilterchangeval({ ...filterchangeval, "to": "", [fieldval.model]: new Date(formattedToday).toISOString() })
          } else {
            setfilterchangeval({ ...filterchangeval, [fieldval.model]: new Date(formattedToday).toISOString() })
          }

        } else {
          let updatetime = new Date(filterchangeval[fieldval.model]);
          updatetime.setFullYear(yyyy, mm - 1, dd);
          if (fieldval.model === "from") {
            setfilterchangeval({ ...filterchangeval, 'to': "", [fieldval.model]: updatetime.toISOString() })
          } else {
            setfilterchangeval({ ...filterchangeval, [fieldval.model]: updatetime.toISOString() })
          }

        }
      }
    }
  };
  const formatDate = (date__) => {
    if (date__ === "") {
      return ""
    }
    let date = new Date(date__)
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  function disabling(data) {
    console.log(props.List_of_Fav_Filter.filter(item => item.is_favourite === "true").length, 'this is filter change val sec')
    if (isFavEdit === 'true') {
      return false
    } else if (isFavEdit === 'false' && props.List_of_Fav_Filter.filter(item => item.is_favourite === "true").length !== 10) {
      return false
    }
    return props.List_of_Fav_Filter.filter(item => item.is_favourite === "true").length === 10
  }
  function timer(data) {
    let val = data.val;
    let datetime = data.datetime;
    if (datetime) {
      onDateTimeChanges(true, data, val);
    } else {
      onTimeChanges(data, val);
    }
  }
  function setuserdate(val, filterchangeval, val_type) {
    const today = val;
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    console.log("raghavan", 18)
    setfilterchangeval((set) => {
      if (val_type !== undefined) {
        console.log(set, "tttttyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuu")
        set[`${filterchangeval.model}`][val_type] = formattedToday;
      } else {
        set[`${filterchangeval.model}`] = formattedToday;
      }


      return {
        ...set,
      };
    });
  }
  const onTimeChanges = (val, filterchangeval) => {
    filterchangeval = val.val
    let { hour, minute, meridiem } = val;
    let timevalue = `${hour}:${minute}`;
    setfilterchangeval((set) => {
      set[`${filterchangeval.model}`] = timevalue;

      return {
        ...set,
      };
    });
  };
  let resetvalue = () => {
    setActiveFilter({})
    setfavChecked(false)
    setisFavEdit(false)
    let obj = {}
    for (var i = 0; i < props.fields.length; i++) {
      if (props.fields[i].fieldType === "DatePicker" || props.fields[i].fieldType === 'DatetimePicker') {
        obj[`${props.fields[i].model}`] = {}
        obj[`${props.fields[i].model}`]["from"] = ""
        obj[`${props.fields[i].model}`]["to"] = ""
        obj[`${props.fields[i].model}`]["isdate"] = true
      } else {
        


        obj[props.fields[i].model] = props.fields[i].model === "isclosed" ?
          {
            "label": "No",
            "value": "false"
          }
          : props.fields[i].model === "hopper_status" ?
            {
              "label": "No",
              "value": "0"
            }
            : ""
      }
    }
    obj['from'] = ""
    obj['to'] = ""
    console.log("raghavan", 20, obj)
    setfilterchangeval(obj)
  };
  let DateTimeGetter = (model, type) => {
    console.log(filterchangeval[model][type], "wwwwwwwwwwwwwwwwwwwwwww", filterchangeval, model, type)
    if (filterchangeval[model][type] == null || filterchangeval[model][type] == '') {
      return null
    } else {
      if (filterchangeval[model][type] == '0000-00-00 00:00:00') {
        return null;
      }
      else if (filterchangeval[model][type] == '0000-00-00') {
        return null;
      } else {
        return new Date(filterchangeval[model][type])
      }

    }
  };
  let setdatetime = (value, type, model) => {
    setfilterchangeval((set) => {

      set[model][type] = value;

      return {
        ...set,
      };
    });
  }

  return (

    <div className={filtererapstyle}>
      <Dropdown show={openMenu}>
        <Dropdown.Toggle id="dropdown-custom-1" className="logout">
          <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>Filter</Tooltip>}
          ><span
            onClick={() => {
              setopenMenu(!openMenu)
              setShowName(false)
              setopenfilterpopup(false)
            }}
            className={
              `material-icons lead-table-button ${props.filterCheck} lead-reset`
            }
          >
              tune
            </span></OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu show={openMenu}>
          <div className="lead-filter-container">
            <div className="lead-filter-container-head line">
              {
                openfilterpopup ? <span class="material-icons lead-filter-icon custom-cursor" onClick={() => {
                  setopenfilterpopup(false)
                  setfilterName("")
                  // setfilterchangeval({})
                  setMode('create')
                  setShowName(false)
                }}>
                  arrow_back_ios
                </span> : <span className="material-icons lead-filter-icon custom-pointer " >
                  filter_list
                </span>
              }

              <label style={{ fontWeight: 'bold', marginTop: '3px', color: 'rgb(40 153 243)' }}>Filter By</label>
            </div>
            <div className="lead-filter-parent">
              <div className="lead-filter-body">
                {
                  !openfilterpopup ? <ListGroup style={{ width: '100%' }}>
                    {
                      list_of_Filter.map((item, i) => {
                        if (item.is_active === "true") {
                          return (
                            <div style={{ display: 'flex', width: '100%' }} >
                              <ListGroup.Item style={{ padding: '10px', border: 0, width: '50%', cursor: 'pointer' }}
                                onClick={() => {
                                  props.onLeadscreenFavselection(item, "Filterstart")
                                  setopenMenu(!openMenu)
                                }}
                              >
                                <span style={{ float: 'left', marginRight: '5px', marginTop: '-4px' }} class="material-icons">
                                  manage_search
                                </span>
                                <span style={{ marginTop: '5px' }}>{item.filter_name} </span>

                              </ListGroup.Item>
                              <div style={{ width: '50%' }}>

                                <span style={{ float: 'right', cursor: 'pointer', color: 'red' }} className="material-icons" onClick={() => deletefilter(i)} >
                                  clear
                                </span>


                                <span style={{ float: 'right', color: item.is_favourite === "true" ? "#ffd702" : null, cursor: 'pointer' }} className="material-icons" onClick={() => onselectfav(i)} >
                                  {item.is_favourite === "true" ? "star" : "star_border"}
                                </span>
                                <span style={{ float: 'right', cursor: 'pointer' }} className="material-icons lead-filter-icon" onClick={() => openeditfilter(i, item.is_favourite)} >
                                  edit
                                </span>
                              </div>

                            </div>

                          )
                        }

                      })
                    }
                    <ListGroup.Item style={{ padding: '10px', border: 0, marginTop: '5px', cursor: 'pointer', position: 'sticky', bottom: 0, color: 'rgb(40 153 243)' }} onClick={() => {
                      resetvalue()
                      setopenfilterpopup(true)
                    }}>
                      <span style={{ float: 'left', marginRight: '5px', marginTop: '-4px' }} class="material-icons">
                        add_circle
                      </span>
                      Add Filter</ListGroup.Item>

                  </ListGroup> : null
                }
                {
                  openfilterpopup ? <div style={{ width: '100%' }}>
                    <Form>
                      {Mode === 'Edit' && <div style={{ display: 'flex' }}>
                        <div>
                          <label className={"label_cls required"}
                          >
                            Filter Name
                          </label>
                          <Form.Control type="text" placeholder="Filter Name" value={filterName} ref={finterNameFocus}
                            onChange={(e) => {
                              setfilterName(e.target.value)
                              setErrorFilterName('')
                            }} disabled={Mode === 'Edit'} />
                          <div id="errorCustomFilterName">{errorFilterName}</div>
                        </div>

                        <div style={{ marginLeft: '10%', marginTop: '2%' }}>
                          <label className={'label_cls'}>{'Add to Fav'}</label>
                          <>
                            <Form.Check
                              className='Addto_fav'
                              type="switch"
                              id="custom-switch"
                              checked={favChecked}
                              disabled={disabling()}
                              onChange={(e) => {
                                setfavChecked(!favChecked)
                              }}
                            />
                          </>
                        </div>

                      </div>}
                      {Mode === 'Edit' && <hr />}
                      {
                        props.fields.map((res, i) => {
                          return (
                            <>

                              <label

                                className={"label_cls "
                                }
                              >
                                {res.label === "Hopper Status" ? "Dial Status" : Format(res.label)}
                              </label>

                              {res.fieldType == "TextInput" && (
                                <>
                                  <Form.Control
                                    onChange={(e) => {
                                      if (res.model === "phone_number" || res.model === "alt_number" ||
                                        res.model === "priority" || res.model === "dialed_count" || res.model === "lead_id") {
                                        if (/^\d*$/.test(e.target.value)) {
                                          onchangevalue(e, res);
                                        }
                                        return
                                      } else {
                                        onchangevalue(e, res)
                                      }
                                    }
                                    }
                                    value={
                                      filterchangeval[`${res.model}`] ===
                                        undefined
                                        ? ""
                                        : filterchangeval[`${res.model}`]
                                    }
                                    readOnly={res.readonly}
                                    placeholder={Format(res.placeholder)}
                                  />

                                </>
                              )}
                              {res.fieldType == "RadioButton" && (
                                <>

                                  {objectConverter(res)}

                                </>
                              )}
                              {res.fieldType == "Checkbox" && (
                                <>
                                  {objectConverter(res)}
                                </>
                              )}
                              {res.fieldType == "SelectList" && (
                                <>

                                  {objectConverter(res)}

                                </>
                              )}
                              {res.fieldType == "DatePicker" && (
                                <>
                                  <DatePicker
                                    key={res.model + i + i + i.toString()}
                                    readOnly={res.readonly}
                                    dateFormat="dd/MM/yyyy"
                                    selected={DateGetData(
                                      res.fieldType,
                                      res.model, "from"
                                    )}
                                    onChange={(date_value) =>
                                      setuserdate(date_value, res, 'from')
                                    }
                                    placeholderText={"From"}
                                    className="form-control"
                                  />

                                  <DatePicker
                                    key={res.model + i + i + i.toString()}
                                    readOnly={res.readonly}
                                    dateFormat="dd/MM/yyyy"
                                    selected={DateGetData(
                                      res.fieldType,
                                      res.model, "to"
                                    )}
                                    onChange={(date_value) =>
                                      setuserdate(date_value, res, 'to')
                                    }
                                    placeholderText={"To"}
                                    className="form-control"
                                  />
                                </>
                              )}

                              {res.fieldType == "DatetimePicker" && (
                                <>
                                  <div
                                    className="DatetimePicker"
                                  >
                                    <div
                                      className="Datetime_container"
                                    >
                                      <DatePicker
                                        selected={DateTimeGetter(
                                          res.model, 'from')}
                                        onChange={(date_value) =>
                                          setdatetime(
                                            date_value,
                                            'from', res.model
                                          )
                                        }
                                        placeholderText={'From'}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="DatetimePicker"
                                  >
                                    <div
                                      className="Datetime_container"
                                    >
                                      <DatePicker
                                        selected={DateTimeGetter(
                                          res.model, 'to')}
                                        onChange={(date_value) =>
                                          setdatetime(
                                            date_value,
                                            'to', res.model
                                          )
                                        }
                                        placeholderText={'To'}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}


                            </>
                          )
                        })
                      }
                      <div className="Customform-field-cnt">
                        <label className={'label_cls'}>{'Created At'}</label>
                        <>
                          <div className="DatetimePicker">
                            <div className="Datetime_container" key="1">
                              <DatePicker
                                // minDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                selected={DateTimeGetData(
                                  false,
                                  'from'
                                )}
                                onChange={(date_value) =>
                                  onDateTimeChanges(
                                    false,
                                    date_value,
                                    'from'
                                  )
                                }
                                placeholderText={'From'}
                                className="form-control"
                              />
                            </div>
                            {<div className="Datetime_container" key="2">
                              <DatePicker
                                minDate={new Date(new Date(filterchangeval['from']).setDate(new Date(filterchangeval['from']).getDate()))}
                                dateFormat="dd/MM/yyyy"
                                selected={DateTimeGetData(
                                  false,
                                  'to'
                                )}
                                value={formatDate(filterchangeval['to'])}
                                disabled={filterchangeval['from'] === ""}
                                onChange={(date_value) =>
                                  onDateTimeChanges(
                                    false,
                                    date_value,
                                    'to'
                                  )
                                }
                                placeholderText={'To'}
                                className="form-control"
                              />
                            </div>}
                          </div>
                        </>
                      </div>
                      {Mode === 'create' && <hr style={{ display: showName === false ? 'none' : 'block' }} />}
                      {Mode === 'create' && <div style={{ display: 'flex' }}>
                        <div style={{ display: showName === false ? 'none' : 'block', paddingBottom: '10px' }}>
                          <label className={"label_cls required"}
                          >
                            Filter Name
                          </label>
                          <Form.Control type="text" placeholder="Filter Name" value={filterName} ref={finterNameFocus}
                            onChange={(e) => {
                              setfilterName(e.target.value)
                              setErrorFilterName('')
                            }} disabled={Mode === 'Edit'} />
                          <div id="errorCustomFilterName">{errorFilterName}</div>
                        </div>
                        <div style={{ marginLeft: '10%', marginTop: '2%' }}>
                          <label style={{ display: showName === false ? 'none' : 'block' }} className={'label_cls'}>{'Add to Fav'}</label>
                          <>
                            <Form.Check style={{ display: showName === false ? 'none' : 'block' }}
                              type="switch"
                              id="custom-switch"
                              checked={favChecked}
                              disabled={disabling()}
                              onChange={(e) => {
                                setfavChecked(!favChecked)
                              }}
                            />
                          </>
                        </div>
                      </div>}

                    </Form>

                  </div> : null
                }

              </div>

            </div>
            {
              openfilterpopup ? <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', 'border-top': '1px solid rgba(0, 0, 0, 0.1)' }}>
                <div className="dialog-buttons">
                  <button
                    onClick={() => {

                      clearvalues()
                    }}
                    className="dialog-btn1"
                    disabled={buttonDisable()}
                  >
                    Clear
                  </button>
                </div>
                <div className="dialog-buttons">
                  <button
                    onClick={() => {

                      applyfilter()
                    }}
                    className="dialog-btn1"
                    disabled={buttonDisable()}
                  >
                    Apply and Save
                  </button>
                </div>
                <div className="dialog-buttons">
                  <button
                    onClick={() => {
                      setMode('create')
                      FilterLead(filterName)
                    }}
                    className="dialog-btn1"
                    disabled={buttonDisable()}
                  >
                    Quick Filter
                  </button>
                </div>
              </div>
                : null
            }
          </div>
        </Dropdown.Menu>
      </Dropdown>

    </div>
  )
}