import CryptoJS from 'crypto-js';

const Encode = (payload) => {
    if(payload !== "" &&payload !== undefined){
        const enbase64data = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(payload));
        return enbase64data
    }
    
};



const Decode = (payload) => {
    if(payload !== "" && payload !== undefined){
        const debase64data = CryptoJS.enc.Base64.parse(payload).toString(CryptoJS.enc.Utf8);
        return debase64data
    }
    
};


const Encrypt = (text) => {
    let security_key="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" 
    let secret_iv = "smlt";
    const key = CryptoJS.SHA512(security_key).toString(CryptoJS.enc.Hex).substring(0, 32);
   const iv = CryptoJS.SHA512(secret_iv).toString(CryptoJS.enc.Hex).substring(0, 16);
    if (text !== "" && text !== undefined && key && iv) {
        const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
        console.log(encrypted.toString(),'this is encrypted 1')
        return encrypted.toString();
    } else {
        throw new Error("Missing parameters for encryption");
    }
};

const Decrypt = (encryptedText) => {
    try {
        let security_key = "8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38";
        let secret_iv = "smlt";
        const key = CryptoJS.SHA512(security_key).toString(CryptoJS.enc.Hex).substring(0, 32);
        const iv = CryptoJS.SHA512(secret_iv).toString(CryptoJS.enc.Hex).substring(0, 16);

        if (encryptedText !== "" && encryptedText !== undefined && key && iv) {
            const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv });
            return decrypted.toString(CryptoJS.enc.Utf8);
        } else {
            throw new Error("Missing parameters for decryption");
        }
    } catch (error) {
        console.error("Error during decryption:", error.message);
        return null;
    }
};



export { Encode, Decode ,Encrypt,Decrypt};