
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import 'react-datepicker/dist/react-datepicker.css';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Form from 'react-bootstrap/Form'
import { useDispatch } from 'react-redux';
import { AsterAgentTableField } from '../redux/actions/AsterHeader';
import EventEmitter from '../utils/EventEmitter'
function DynamicField(props) {
    const agent = localStorage.getItem('user')
    let selected_campaign = localStorage.getItem('selectedCampaign')
    let template = JSON.parse(localStorage.getItem('selectedCampaign_data')).template_name
    const dispatch = useDispatch()
    const {
        dynamicHead,
        module,
        saveDynamicPopup
    } = props
    const [formdata, setformdata] = useState({})
    const [header,setheader] = useState([])
    const [values,setValue] = useState(false)
    const formate = (title) => {
        return title.charAt(0).toUpperCase() + title.slice(1);
    };
    useEffect(()=>{
        try {
        if(dynamicHead){
            const data = {
                user:agent,
                campaign:selected_campaign,
                module:module,
                fields:'all',
                template:template
            }
            dispatch(AsterAgentTableField(data)).then((response)=>{
                const newData = response.data.data.map(item => {
                      return{
                        Header: item.label,
                        accessor:item.model,
                        agent_table_show:item.agent_table_show
                      }
                    })
                setheader(newData) 
                setValue(true)
            }).catch((err)=>{
                EventEmitter.emit('error-message-getter', {
                    action: 'field',
                    module: "Selected fields are removed Please select field.",
                })
            })
        }
        } catch (error) {
            console.log(error,'this is checking error')
        }
    },[dynamicHead])

    useEffect(()=>{
        header.map((items)=>{
            if(items.agent_table_show === 1){
                setformdata((prevFormData) => {
                    return {
                    ...prevFormData,
                    [items.accessor]: 'true'
                    };
                });
            }else{
                if (items.accessor  !='action'){
                    setformdata((prevFormData) => {
                        return {
                        ...prevFormData,
                        [items.accessor]: 'false'
                        };
                    });
                }
                
            }
        })
    },[header])
    const formater = (value) => {
        if (typeof value === 'string') {
            return value.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
        } else {
            return value;
        }
    }
    const handleCheckboxChange = (headerKey, isChecked) => {
        setformdata(prevFormData => ({
          ...prevFormData,
          [headerKey]: isChecked.toString() 
        }));
      };
      const areAllCheckboxesChecked = () => {
        return header.every(item => formdata[item.accessor] === 'true');
      };
      const handleSelectAllChange = (isChecked) => {
        const updatedFormData = {};
        header.forEach(item => {
          updatedFormData[item.accessor] = isChecked.toString();
        });
        console.log(updatedFormData,'this is updated formdata')
        setformdata(updatedFormData);
      };
      const checkIfAnyCheckboxChecked = () => {
        const isAnyChecked = header.some(item => formdata[item.accessor] === 'true');
        return isAnyChecked ? '' : 'disabledbutton'
      };

    return (
        <div >
            <Dialog
            className='dyanamicHeaderMainDiv'
                open={dynamicHead && values}
                fullWidth={true}
            >
                <DialogTitle className="dialog-header">
                    <div className='dialog-header-flex'>
                        <div className='dialog-header-flex-lft-field'>
                            {formate('header Fields')}
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <>
                        <div className="Customform-field-cnt dialog-fileds" >
                                    <Form.Check
                                    className='field-check select_all'
                                            type="checkbox"
                                            checked={ areAllCheckboxesChecked() }
                                            onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    />
                                        <label
                                            className={
                                                'label_cls-field select_all'
                                            }
                                        >
                                            {formater('select All')}{' '}
                                        </label>
                                        
                                    </div>
                        {header.map((item)=>{
                            if(item.accessor!=='action'){
                                return(
                                <div className="Customform-field-cnt dialog-fileds" >
                                    <Form.Check
                                    className='field-check'
                                            value={item.accessor}
                                            type="checkbox"
                                            checked={formdata[item.accessor]=='true' }
                                            onChange={(e) => handleCheckboxChange(item.accessor, e.target.checked)}
                                    />
                                        <label
                                            className={
                                                // res.required
                                                //   ? 'label_cls required'
                                                //   : 
                                                'label_cls-field'
                                            }
                                        >
                                            {formater(item.Header)}{' '}
                                        </label>
                                        
                                    </div>
                                )
                            }
                            
                        })   
                        }
                        </>
                    </DialogContentText>
                </DialogContent>
                <div className="dialog-buttons">
            <>
              <button
                className="dialog-btn1"
                onClick={() => {
                    saveDynamicPopup(false)
                }}
              >
                Cancel{' '}
              </button>
              <button
                className={`dialog-btn1 ${checkIfAnyCheckboxChecked()}`}
                onClick={() => {
                    saveDynamicPopup(formdata)
                }}
              >
                {' '}
                {formate('save')}
              </button>
            </>
          {/* )} */}
        </div>
            </Dialog>
        </div>
    );
}

export default DynamicField;