import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: '60vw',
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[10],
    backdropFilter: 'blur(5px)',
  },
  title: {
    width: '100%',
    padding: '16px 24px',
    backgroundColor: '#2196f3',
    color: '#fff',
    fontSize: '20px',
    fontWeight: 600,
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    boxSizing: 'border-box',
  },
  spinner: {
    color: '#2196f3',
  },
}));

const NetworkStatus = () => {
  const classes = useStyles();
  const [isOnline, setIsOnline] = useState(true);
  const socketStatus = useSelector((state) => state.background.networkstatus);

  useEffect(() => {
   
    const handleOnlineStatusChange = () => {
       setIsOnline(navigator.onLine);
    };
    setIsOnline(navigator.onLine);
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
  
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [navigator.onLine]);

  const isOffline = !isOnline || socketStatus !== 'online';

  return (
    <Dialog
      open={isOffline}
      onClose={() => {}}
      disableEscapeKeyDown
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.title}>No Internet Connection</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          Your device appears to be offline. Please check your Internet connection.
        </DialogContentText>
        <CircularProgress className={classes.spinner} />
      </DialogContent>
    </Dialog>
  );
};

export default NetworkStatus;