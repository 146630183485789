import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { useOutletContext } from 'react-router-dom';
import Timer from "../Timepicker";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import "../../assets/css/Customform.css";
import Form from "react-bootstrap/Form";
import {
  TicketTagManagement,

} from '../../redux/actions/TicketAction.js'
import TimePicker from "react-times";
import moment from "moment";
import "react-times/css/material/default.css";
import "react-times/css/classic/default.css";
import { BsFillHexagonFill } from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import EventEmitter from "../../utils/EventEmitter";
import Regex_ from '../../utils/regex'
import Format from '../../utils/format-text'
import {
  CustomformUpdate,
  CustomFormCreate,
} from "../../redux/actions/Customform";
import Dialogbox from "../Dialogbox";
import { GetLeadStataus, GetSource, GetTimezone, Getsearch } from '../../redux/actions/Asterleads'
import { triggerdispostate, callLeadDetail, triggerreqpopup } from "../../redux/background/BackgroundAction";
import { Decode, Encode } from "../../utils/EncodeDecode";
import { useNavigate } from 'react-router'
import fromat from '../../utils/format-text'
import AsyncSelect from 'react-select/async';
import ChatBox from "../chatBox.js";
import { FiNavigation } from "react-icons/fi";
import { CiChat2 } from "react-icons/ci";
function CustomForm(props, ref) {
  const { triggerCallScreenFunction, outletData, isMobile } = useOutletContext();
  let { phone_number } = useParams();
  phone_number = Decode(phone_number)
  let [loadvalues,setloadvalues]=useState([])
  let [datetimemodel, setdatetimemodel] = useState([])
  const [timezone, settimezone] = useState([])
  let state_value = useSelector((state) => state);
  const [validate, setvalidate] = useState(false);
  let [chatdata, setchatdata] = useState({})
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"));
  let Dispatch = useDispatch();
  let navigate = useNavigate()
  const location = useLocation()
  const [customField, setcustomField] = useState([]);
  const [formdata, setformdata] = useState({});
  const [cloneformdata, setcloneformdata] = useState({});
  const [catagory, setcatagory] = useState([]);
  const [dispo, setdispo] = useState([]);
  const [updatedata, setupdatedata] = useState({});
  const [templetedata, settempletedata] = useState({});
  const [leadcreatestatus, setleadcreatestatus] = useState(false);
  const [regexvalidation, setregexvalidation] = useState({});
  const [textcount, settextcount] = useState({})
  const [notepadtext, setnotepadtext] = useState('')
  const [leadstatus, setleadstatus] = useState([])
  const [flagnumber, setflagnumber] = useState(false)
  // const [wordCount, setwordCount] = useState(255)
  const [Listdata, setlistdata] = useState(
    JSON.parse(localStorage.getItem("list")).map((elm) => {
      return { label: elm.list_id, value: elm.list_id.toString() };
    })
  );
  const [tags, settags] = useState([])
  const [source, setsource] = useState([])
  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: "Notification_bar",
    field: [],
    title: "update Lead ",
    Message: "lead updated successfully!!! ",
  });

  const [requiredpopupdata, setrequiredpopupdata] = useState({
    open: false,
    Type: "Notification_bar",
    field: [],
    title: "Require field error",
    Message: "please fill require field!!! ",
  });
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  // useMemo(() => {
  //   if (location.pathname === '/leads/create') {
  //     console.log("jhschjsdbchjsdgbhjcshchsc")
  //     phone_number=state_value.background.socket_message.phonenumber
  //   }
  // }, [])
  useEffect(() => {

    if (props.triggerdispo) {

      if(isMobile){
        setTimeout(() => {
          update(true)
          props.closedispo()
        }, 500);
      }else{
        update(true)
        props.closedispo()
      }

    }
  }, [props.triggerdispo])
  useMemo(() => {
    mounted();
    Dispatch(triggerdispostate(false));
  }, [phone_number, props.formdatas]);

  // useImperativeHandle(ref, () => ({
  //   updateapi() {
  //     update();
  //   },
  // }));

  useEffect(() => {
    if (props.savebutton) {
      console.log("444444444444444", "9999999999999999999")
      update();
    }
  }, [props.savebutton]);
  useEffect(() => {

    if (state_value.background.triggerreqpopup) {
      console.log("3333333333333333333333333", "9999999999999999999")
      update()
      Dispatch(triggerreqpopup(false));
    }
  }, [state_value.background.triggerreqpopup]);

  useEffect(() => {
    let campaigndetailslocalstorage = JSON.parse(localStorage.getItem("campaigndetails"))
    if (state_value.background.triggerdispo && !campaigndetailslocalstorage.auto_dispo) {
      console.log("22222222222222222222222", "9999999999999999999")
      update();
    } else {
      Dispatch(triggerdispostate(false));
    }
  }, [state_value.background.triggerinitiate]);
  useEffect(() => {
    localStorage.setItem("formvalidate", validate)
  }, [validate])
  useMemo(() => {

    let emptyobj = { fields: [] };
    emptyobj.fields.push({
      name: 'modified_by',
      value: localStorage.getItem("user")
    });
    for (const key in formdata) {
      if (key === "modified_date") {
        formdata[key] = currenttime();
      }
      if (
        key !== "lead_id" &&
        key !== "phone_number" &&
        key !== "list_id" &&
        key !== "hopper_status"
        //  &&
        // key !== "disposition"
        && key !== "subdisposition" && key !== "deleted" &&
        key !== 'modified_by' && key !== 'created_by' && key !== 'created_at'
      ) {

        let datedate = new Date(formdata[key])
        if (Object.prototype.toString.call(datedate) === "[object Date]" && key !== "modified_date") {
          if (!isNaN(datedate.getTime()) && datetimemodel.includes(key)) {
            const yyyy = datedate.getFullYear();
            let mm = datedate.getMonth() + 1;
            let dd = datedate.getDate();
            let hh = datedate.getHours();
            let MM = datedate.getMinutes();
            let ss = datedate.getSeconds();
            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;
            if (hh < 10) hh = "0" + hh;
            if (MM < 10) MM = "0" + MM;
            if (ss < 10) ss = "0" + ss;

            const formattedToday =
              yyyy + "-" + mm + "-" + dd + " " + hh + ":" + MM + ":" + ss;
            emptyobj.fields.push({
              name: key,
              value: formattedToday,
            });
          } else {
            emptyobj.fields.push({
              name: key,
              value: formdata[key],
            });
          }
        }

      }
      if (leadcreatestatus === true) {
        if (key === "phone_number" || key === "list_id") {
          emptyobj.fields.push({
            name: key,
            value: formdata[key],
          });
        }
        if (key === "modified_date" && props.formdatas.action === "create") {
          emptyobj.fields.push({
            name: key,
            value: currenttime(),
          });
        }
        if (key === "disposition" && props.formdatas.action === "create") {
          emptyobj.fields.push({
            name: key,
            value: "new",
          });
        }
        if (key === "deleted") {
          emptyobj.fields.push({
            name: key,
            value: "false",
          });
        }
        if (key === 'isclosed') {
          emptyobj.fields.push({
            name: key,
            value: formdata[key],
          });
        }
        if (leadcreatestatus && key === 'created_by') {
          emptyobj.fields.push({
            name: key,
            value: localStorage.getItem('user'),
          })
        }
        if (leadcreatestatus && key === 'created_at') {
          emptyobj.fields.push({
            name: key,
            value: currenttime(),
          })
        }
        if (leadcreatestatus && key === 'hopper_status') {
          emptyobj.fields.push({
            name: key,
            value: "0",
          })
        }
      }
    }
    setupdatedata(emptyobj);
  }, [formdata]);

  useEffect(() => {
    for (let i = 0; i < customField.length; i++) {
      let elm = customField[i];
      if (
        formdata[`${elm.model}`] !== "" &&
        formdata[`${elm.model}`] !== null &&
        formdata[`${elm.model}`] !== undefined
      ) {
        if (elm.fieldType === "Checkbox") {
          handleChangedata(elm, {
            checked: formdata[`${elm.model}`],
            value: elm.model,
          });
        } else {
          handleChangedata(elm, { value: formdata[`${elm.model}`] });
        }
      }
    }
  }, [cloneformdata]);

  function currenttime() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let hh = today.getHours();
    let MM = today.getMinutes();
    let ss = today.getSeconds();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (hh < 10) hh = "0" + hh;
    if (MM < 10) MM = "0" + MM;
    if (ss < 10) ss = "0" + ss;

    const formattedToday =
      yyyy + "-" + mm + "-" + dd + " " + hh + ":" + MM + ":" + ss;
    return formattedToday;
  }
  function gettag() {

    Dispatch(TicketTagManagement())
      .then((response) => {
        if (response.data.statusCode === 200) {   
          let tag = response.data.data.map((elm) => {
            return { label: elm.name, value: elm.name }
          })
          settags(tag)
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'tag',
        })
      })
  }
  function Getdispo() {

    let dispo = JSON.parse(localStorage.getItem('dispo')).map((elm) => {
      return { label: elm, value: elm }
    })
    console.log(dispo, "dispodispodispo")
    setdispo(dispo)


  }

  function sourcefn() {
    Dispatch(GetSource()).then((res) => {
      if (res.status == 200) {
        let sourcedata = res.data.data.map((elm) => {
          return { label: elm.name, value: elm.name }
        })
        setsource(sourcedata)
      } else if (res.status == 204) {
        setcustomField((set) => {
          let getindexfield = set.findIndex((Elm) => Elm.model == "source")
          if (getindexfield !== -1) {
            set[getindexfield].required = false
          }
          return set
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'Source',
        module: 'lead',
      })
    });
  }
  function mounted() {
    Dispatch(GetTimezone())
      .then((res) => {
        let timezone_data = res.data.map(elm => {
          return {
            label: elm.zone_name,
            value: elm.zone_name
          }
        })
        settimezone(timezone_data)

      })
      .catch((error) => {
        console.log(error)

      });
    gettag()
    Getdispo()
    let newlead = {};
    Dispatch(GetLeadStataus()).then(function (response) {
      if (response.status === 200) {
        setleadstatus(response.data.data)
        let leadstatus_ = response.data.data.map((elm) => {
          if (elm.is_closed === "Y") {
            return { label: elm.name, value: elm.name, isclosed: true }
          } else {
            return { label: elm.name, value: elm.name, isclosed: false }
          }

        })
        setleadstatus(leadstatus_)
      } else if (response.status == 204) {
        setleadstatus([])
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'status',
        module: 'lead',
      })
    })


    setcustomField(props.statedata.fields);
    let payload = {
      template_name: campaigninfo.template_name,
      type: campaigninfo.type,
      phone_number: phone_number,
    };
    let modeldata = []
    let validateobj = {}
    let txtcount = {}
    props.statedata.fields.forEach((element) => {

      validateobj[`${element.model}`] = false
      txtcount[`${element.model}`] = 255
      if (element.model === "phone_number" || element.model === "user") {
        if (location.pathname === '/leads/create') {
          if (state_value.background.socket_message.phonenumber != "" && state_value.background.socket_message.phonenumber != undefined &&
            state_value.background.socket_message.phonenumber !== null && state_value.background.socket_message.phonenumber) {
            setflagnumber(true)
          }
          phone_number = state_value.background.socket_message.phonenumber
        }
        if (localStorage.getItem(`setStatic_number`) !== null) {
          phone_number = localStorage.getItem(`setStatic_number`)
        }
        newlead["phone_number"] = phone_number;
        newlead["user"] = localStorage.getItem("user");
      } else {
        newlead[element.model] = "";
        if ((element.fieldType == "TextInput" || element.fieldType == "LongTextInput") && element.hasDefault && element.show_on_popup) {
          if (element.default != null && element.default != "") {
            newlead[element.model] = element.default
          }
        }
      }

      if (element.fieldType === "DatetimePicker") {
        modeldata.push(element.model)
      }
    });
    settextcount(txtcount)
    setregexvalidation(validateobj)
    setdatetimemodel(modeldata)
    let res = props.formdatas;
    if (res.status === 204) {
      setleadcreatestatus(true);
      setpopupdata((set) => {
        set.title = "create lead";
        set.Message = "lead created successfully!!! ";
        return {
          ...set,
        };
      });
      setformdata(newlead);
      settempletedata(payload);
    } else if (res.data.statusCode === 200) {
      payload.id = res.data.data[0].others.lead_id;


      settempletedata(payload);
      setleadcreatestatus(false);
      setformdata(res.data.data[0].others);
      res.data.data[0].basic.id = res.data.data[0].others.lead_id;
    }

    let customField_data = props.statedata.fields;
    let catagory_array = [];
    let child_fielddata = customField_data.filter(
      (elm) => elm.link_type === "Child"
    );
    let parent_fielddata = customField_data.filter(
      (elm) => elm.link_type === "Parent"
    );

    let obj = {};
    for (let i = 0; i < customField_data.length; i++) {
      const element = customField_data[i].catagory_value;

      if (
        customField_data[i].model === "lead_id"
      ) {
        catagory_array.splice(0, 0, element);
      } else if (!catagory_array.includes(element)) {
        catagory_array.push(element);
      }
      catagory_array = [...new Set(catagory_array)];


      if (
        (customField_data[i].link_type === "Child" &&
          customField_data[i].is_link) ||
        customField_data[i].disabled ||customField_data[i].model === "associated_id"||
        customField_data[i].model === "modified_date" ||
        customField_data[i].model === "modified_by" ||
        customField_data[i].model === "lead_id" ||
        customField_data[i].model === "phone_number" ||
        customField_data[i].model === "list_id" ||
        customField_data[i].model === "hopper_status"
        ||
        (customField_data[i].model === "disposition" && location.pathname === '/leads/create')
      ) {
        if (customField_data[i].model === "phone_number") {
          customField_data[i]["visible"] = true;
          customField_data[i]["disabled"] = true; //last work
        }else if((customField_data[i].model === "associated_id"&&customField_data[i].disabled)){
          customField_data[i]["visible"] = true;
                  customField_data[i]["disabled"] = true;
        }else if((customField_data[i].model === "associated_id"&&!customField_data[i].disabled)){
          customField_data[i]["visible"] = true;
                  customField_data[i]["disabled"] = false;
        } else {
          customField_data[i]["visible"] = false; //last work
        }


        if (res.status === 204) {
          if (
            (customField_data[i].model === "list_id" ||
              customField_data[i].model === "email" || customField_data[i].model === "phone_number") && res.action === "create"
          ) {
            customField_data[i]["visible"] = true;
            customField_data[i].disabled = false;
            customField_data[i].readonly = false;
          } else if (customField_data[i].model === "list_id" ||
            customField_data[i].model === "email" && res.action !== "create") {
            customField_data[i]["visible"] = true;
            customField_data[i].disabled = false;
            customField_data[i].readonly = false;
          }

          if (
            customField_data[i].model === "user" ||
            customField_data[i].model === "list_id"
          ) {
            if (customField_data[i].fieldType === "TextInput") {
              customField_data[i].fieldType = "SelectList";
            }
            // SelectList
          }
        }
      } else if (
        customField_data[i].link_type === "" ||
        customField_data[i].link_type === "Parent"
      ) {

        customField_data[i]["visible"] = true;
      }
    }

    parent_fielddata.forEach((elm) => {
      child_fielddata.forEach((elem) => {
        if (elem.linked_to === elm.model) {
          if (
            (elm.fieldType === "RadioButton" &&
              elem.fieldType === "SelectList") ||
            (elm.fieldType === "RadioButton" &&
              elem.fieldType === "RadioButton") ||
            (elm.fieldType === "RadioButton" &&
              elem.fieldType === "SelectList") ||
            (elm.fieldType === "Checkbox" && elem.fieldType === "RadioButton") ||
            (elm.fieldType === "Checkbox" && elem.fieldType === "SelectList") ||
            (elm.fieldType === "SelectList" && elem.fieldType === "SelectList") ||
            (elm.fieldType === "SelectList" && elem.fieldType === "RadioButton")
          ) {
            elem.visible = true;
            elem.disabled = true;
          }
        }
      });
    });

    setcatagory(catagory_array);
    if (res.data.statusCode === 200) {
      setcloneformdata(res.data.data[0].others);
    }
    let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
    if (Object.keys(teamdata).length > 0) {
      if (teamdata.type == "all" || teamdata.type == "source") {
        let sourcedata = teamdata.type_data.source.map((elm) => {
          return { label: elm.name, value: elm.name }
        })
        if (sourcedata.length > 0) {
          setsource(sourcedata)
        } else {
          sourcefn()
        }

      } else {
        sourcefn()
      }

    } else {
      sourcefn()
    }
  }

  const closepopup = (data) => {
    setpopupdata(data);
    localStorage.removeItem('erroraction_popup')
    console.log("5544444444444444444");
    if (state_value.background.triggerdispo) {
      Dispatch(triggerdispostate(false));
    }
    mounted();
  };

  const requireclosepopup = (data) => {
    console.log("5611111111111111");
    localStorage.setItem("erroraction_popup", true)
    setrequiredpopupdata(data);
  };

  const requireddialogopen = (data) => {
    setrequiredpopupdata((set) => {
      set.open = data;
      return {
        ...set,
      };
    });
  };
  const dialogopen = (data) => {
    console.log("5777777777777777");
    localStorage.removeItem('erroraction_popup')
    setpopupdata((set) => {
      set.open = data;
      return {
        ...set,
      };
    });
  };

  function update(data) {
    console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
    if (leadcreatestatus === true) {
      let payload = {
        template_name: templetedata.template_name,
        fields: updatedata.fields,
      };

      let count = 0;
      for (let i = 0; i < customField.length; i++) {
        const element = customField[i];
        let tempcount = 0;
        if (
          element.required === true &&
          element.visible === true &&
          element.show_on_popup === true
        ) {
          for (let j = 0; j < updatedata.fields.length; j++) {
            const element1 = updatedata.fields[j];
            let campaigndata = localStorage.getItem('campaigndetails')
            if (element1.name === 'list_id' && element1.value === "" && (JSON.parse(campaigndata).primary_list !== null || JSON.parse(campaigndata).primary_list !== "")) {
              element1.value = JSON.parse(campaigndata).primary_list
            }
            if (element1.name === element.model) {
              let value = element1.value;
              if (value === "" || value === undefined) {
                tempcount += 1;
              }
            }
          }
        }
        count += tempcount;
      }
      if (count > 0) {
        Dispatch(triggerdispostate(true));
        setvalidate(true);

        requireddialogopen(true);
      } else {
        setvalidate(false);

        Dispatch(CustomFormCreate(payload))
          .then((res) => {
            EventEmitter.emit("error-message-getter", {
              action: "createform",
              module: "lead",
            });
            if ((state_value.background.oncall || localStorage.getItem("wasession") === "true") && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === res.data.data.phone_number) {
              let leadData = {
                lead_id: res.data.data.lead_id,
                lead_name: res.data.data.first_name,
                phone_number: res.data.data.phone_number,
                render: 1,
                module: "lead",
              }
              localStorage.setItem("oncalldata", JSON.stringify(leadData))
              // props.fetchapi()
              Dispatch(callLeadDetail(leadData))
              if (data) {
                triggerCallScreenFunction(true);
              }
              // EventEmitter.emit("give_dispo_oncreate", {
              //   data:data
              // });
              navigate(`/leads/${Encode(res.data.data.phone_number)}/${res.data.data.lead_id}`)
            } else {
              navigate(`/leads/${Encode(res.data.data.phone_number)}/${res.data.data.lead_id}`)
            }
            if (data === undefined) {
              dialogopen(true);
            }

          })
          .catch((err) => {
            EventEmitter.emit("error-message-getter", {
              action: "create",
              module: "lead",
            });
          });
      }
    } else {
      let count = 0;
      for (let i = 0; i < customField.length; i++) {
        const element = customField[i];
        let tempcount = 0;
        if (
          element.required === true &&
          element.visible === true &&
          element.show_on_popup === true
        ) {
          for (let j = 0; j < updatedata.fields.length; j++) {
            const element1 = updatedata.fields[j];
            if (element1.name === element.model) {
              if(state_value.background.socket_message.evt !== "RINGING" && state_value.background.socket_message.calltype === "AUTO"){
                if (element1.name === "user" && element1.value != localStorage.getItem("user") && !element1.value.includes(localStorage.getItem("user"))) {
                  if(element1.value != "" && element1.value != null){
                    element1.value = `${element1.value},${localStorage.getItem("user")}`
                  }else{
                    element1.value = localStorage.getItem("user")
                  }
                }
              }
              let value = element1.value;
              if (value === "") {
                tempcount += 1;
              }
            }
          }
        }else if(element.model == "user" && state_value.background.socket_message.evt !== "RINGING" && state_value.background.socket_message.calltype === "AUTO"){
          for (let j = 0; j < updatedata.fields.length; j++) {
            const element1 = updatedata.fields[j];
            if (element1.name === element.model) {
                if (element1.name === "user" && element1.value != localStorage.getItem("user") && !element1.value.includes(localStorage.getItem("user"))) {
                  if(element1.value != "" && element1.value != null){
                    element1.value = `${element1.value},${localStorage.getItem("user")}`
                  }else{
                    element1.value = localStorage.getItem("user")
                  }
                }

              let value = element1.value;
              if (value === "") {
                tempcount += 1;
              }
            }
          }
        }
        // updatedata.fields.forEach((elm)=>{
        //   if(elm.name ==  "sms_notify"){
        //     elm.value = '0'
        //   }
        //   if(elm.name == "wa_notify"){
        //     elm.value = '0'
        //   }
        //   if(elm.name == "email_notify"){
        //     elm.value = '0'
        //   }
        //   if(elm.name == "api_push"){
        //     elm.value = '0'
        //   }
        // })
        count += tempcount;
      }
      if (count > 0) {
        setvalidate(true);
        requireddialogopen(true);
      }
      else {
        let network_status = state_value.background.networkstatus
        if (network_status == "online") {
          Dispatch(triggerdispostate(true));
          setvalidate(false);
          Dispatch(CustomformUpdate(updatedata, templetedata))
            .then(function (res) {
              if (data === undefined) {
                dialogopen(true);
              }
              props.fetchapi()
              console.log(EventEmitter, "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
              if (data) {
                triggerCallScreenFunction(true);
              }

              // EventEmitter.emit("give_dispo", {
              //   data:data
              // });
            })
            .catch((err) => {
              EventEmitter.emit("error-message-getter", {
                action: "update",
                module: "customform",
              });
            });

        }


      }
    }

    props.saveleaddetail(false);
  }

  const linkedupadate = (linkedres, parentval, selectedval) => {
    if (linkedres.length > 0) {
      let filterdata;
      linkedres.forEach((elem) => {
        filterdata = customField.map((elm) => {
          if (elm.model === elem.model) {
            if (parentval.fieldType === "RadioButton") {
              elm.disabled = false;
              elm.visible = true;
            } else if (parentval.fieldType === "Checkbox") {
              if (selectedval.checked === "true") {
                elm.disabled = false;
                elm.visible = true;
              } else {
                if (elm.link_property.hasOwnProperty("map") != true) {
                  elm.disabled = true;
                } else {
                  elm.visible = false;
                }
                formdata[`${elm.model}`] = "";
              }
            } else if (parentval.fieldType === "SelectList") {
              elm.disabled = false;
              elm.visible = true;
            }

            if (elm.link_property.hasOwnProperty("map") != true) {
              for (const property in elm.link_property) {
                if (selectedval.value === property) {
                  let split_linkedprop = elm.link_property[property].split(",");
                  let update_linked = split_linkedprop.map((elm) => {
                    return { name: elm, value: elm };
                  });
                  elm.values = [JSON.stringify(update_linked)];
                }
              }
            }
          }
          return elm;
        });
      });
      setcustomField(filterdata);
    }
  };

  function handleChangedata(parentval, data) {
    if (
      parentval.link_type === "Parent" &&
      parentval.linked_to === "" &&
      parentval.is_link === true
    ) {
      let linkedres = customField.filter((elm) => {
        if (elm.linked_to === parentval.model) {
          if (
            elm.link_property.map !== data.value &&
            elm.link_property.hasOwnProperty("map")
          ) {
            elm.visible = false;
            formdata[`${elm.model}`] = "";
          } else if (elm.link_property.map === data.value) {
            return elm;
          } else if (elm.link_property.hasOwnProperty("map") != true) {
            return elm;
          }
        }
      });

      linkedupadate(linkedres, parentval, data);
    }

    setformdata((set) => {
      if (data.isclosed === true) {
        set[`isclosed`] = "true";
      } else {
        set[`isclosed`] = "false";
      }
      if (parentval.fieldType === "RadioButton") {
        set[`${parentval.model}`] = data.value;
      } else if (parentval.fieldType === "Checkbox") {
        set[`${parentval.model}`] = data.checked;
      } else if (
        parentval.fieldType == "SelectList" &&
        parentval.multiple == true
      ) {
        if (Array.isArray(data)) {
          if (parentval.readonly) {
            set[`${parentval.model}`] = set[`${parentval.model}`];
          } else {
            let mergeselect_vals = data.map((elm) => elm.value).toString();
            set[`${parentval.model}`] = mergeselect_vals;
          }
        } else {
          set[`${parentval.model}`] = data.value;
        }
      } else {
        console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuu", data.value)
        set[`${parentval.model}`] = data.value;
        console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuu", data.value, set, parentval)
      }

      return {
        ...set,
      };
    });
  }
  function gettextareavalue(value, model) {
    let count = value.split("").length
    if (255 - count <= 255 && 255 - count >= 0) {
      settextcount((set) => {
        set[`${model}`] = 255 - count
        return {
          ...set
        }
      })
      setformdata((set) => {
        set[`${model}`] = value;
        return { ...set };
      })
    }
  }
  let textinputchange = (parentval, data) => {
    if (parentval.type == "input") {
      parentval.type = "others"
    }
    let val = Regex_(parentval.type)
    let otherval = false
    if (parentval.type === "others") {
      if (parentval.model_type === "SmallNumber" || parentval.model_type === "Number" || parentval.model_type === "BigNumber") {
        val = Regex_("number")
      } else {
        otherval = true
      }
    }

    if (data.value === "" || (parentval.type === "others" && otherval)) {
      regexvalidation[`${parentval.model}`] = false
      setregexvalidation((set) => {
        set[`${parentval.model}`] = false
        return {
          ...set
        }
      })
      props.regexval(Object.values(regexvalidation).every((condition) =>
        condition === false
      ))
    }
    else if (!val.test(data.value)) {
      regexvalidation[`${parentval.model}`] = true
      setregexvalidation((set) => {
        set[`${parentval.model}`] = true
        return {
          ...set
        }
      })
      props.regexval(Object.values(regexvalidation).every((condition) =>
        condition === false
      ))
    } else {
      regexvalidation[`${parentval.model}`] = false
      setregexvalidation((set) => {
        set[`${parentval.model}`] = false
        return {
          ...set
        }
      })
      props.regexval(Object.values(regexvalidation).every((condition) =>
        condition === false
      ))
    }
    setformdata((set) => {
      set[`${parentval.model}`] = data.value;

      return {
        ...set,
      };
    });
  };

  let SelectedGetData = (val) => {
    let select_options = JSON.parse(val.values[0]);
    if (val.multiple != true) {
      if (
        formdata[`${val.model}`] === "" ||
        formdata[`${val.model}`] === null
      ) {
        return null;
      } else {
        if (val.model == "list_id") {
          let listdatas = Listdata.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
          return {
            value: listdatas.value,
            label: listdatas.label,
          };
        }
        if (val.model == "source") {
          let listdatas = source.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
          if (listdatas !== undefined) {
            return {
              value: listdatas.value,
              label: listdatas.label,
            };
          } else {
            return null
          }

        }
        if (val.model == "timezone") {
          let timezonedata = timezone.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
          if (timezonedata !== undefined) {
            return {
              value: timezonedata.value,
              label: timezonedata.label,
            };
          } else {
            return null
          }

        }
        if (val.model == "disposition") {
          let dispositiondata = dispo.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
          if (dispositiondata !== undefined) {
            return {
              value: dispositiondata.value,
              label: dispositiondata.label,
            };
          } else {
            return null
          }

        }
        if (val.model == "lead_status") {

          let leadstatus_ = leadstatus.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
          // return {
          // value: leadstatus_.value,
          // label: leadstatus_.label,
          // };
          if (leadstatus_ == undefined) {
            return null
          } else {
            return {
              value: leadstatus_.value,
              label: leadstatus_.label,
            }
          }
        }
        if (val.model == "associated_id") {
        
          let associated_id = loadvalues.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
         
          if (associated_id == undefined) {
            return null
          } else {
            return {
              value: associated_id.value,
              phone_number:associated_id.phone_number,
              label: associated_id.label ,
            }
          }
        }
        else {

          let res_val = select_options.find(
            (elm) => elm.value == formdata[`${val.model}`]
          );
        
          if (res_val == undefined) {
            return null;
          } else {

            return {
              value: res_val.value,
              label: res_val.name,
            };
          }
        }
      }
    } else {
      let final_res;
      if (
        formdata[`${val.model}`] !== "" && formdata[`${val.model}`] !== null &&
        formdata &&
        Object.keys(formdata).length > 0
      ) {
        let splited_data = formdata[`${val.model}`].split(",");
        final_res = splited_data.map((elem) => {
          if (val.model === 'tags') {
            let res_val = tags.find((elm) => elm.value == elem)
            if (res_val == undefined) {
              return null
            } else {
              return {
                value: res_val.value,
                label: res_val.label,
              }
            }
          } else {
            let res_val = select_options.find((elm) => elm.value == elem);
            return {
              value: res_val.value,
              label: res_val.name,
            };
          }

        });
      } else {
        final_res = [];
      }

      return final_res;
    }
  };

  let DateGetData = (type, model) => {
    if (type == 'DatePicker') {
      if (formdata[model] == null || formdata[model] == '') {
        return formdata[model]
      } else {
        if (formdata[model] == '0000-00-00 00:00:00') {
          return null;
        }
        else if (formdata[model] == '0000-00-00') {
          return null;
        } else {
          return new Date(formdata[model])
        }

      }
    } else if (type == 'TimePicker') {
      return formdata[model]
    }
  }

  function setuserdate(val, fieldata) {
    const today = val;
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    setformdata((set) => {
      set[`${fieldata.model}`] = formattedToday;

      return {
        ...set,
      };
    });
  }

  const onTimeChanges = (val, fieldata) => {
    fieldata = val.val
    let { hour, minute, meridiem } = val;
    let timevalue = `${hour}:${minute}`;
    setformdata((set) => {
      set[`${fieldata.model}`] = timevalue;

      return {
        ...set,
      };
    });
  };

  const DateTimeGetData = (datetime_return, model) => {

    if (datetime_return) {
      if (!formdata[model] || formdata[model] === '' || formdata[model] === '0000-00-00 00:00:00') {
        return null;
      } else {
        const dateTime = moment(formdata[model], 'YYYY-MM-DD HH:mm:ss');
        if (!dateTime.isValid()) {
          console.log("DateTimeGetData: Invalid date format");
          return null;
        } else {
          const formattedTime = dateTime.format('HH:mm');
          return formattedTime;
        }
      }
    } else {
      if (!formdata[model] || formdata[model] === '' || formdata[model] === '0000-00-00 00:00:00') {
        return null;
      } else {
        const dateTime = moment(formdata[model], 'YYYY-MM-DD HH:mm:ss');
        if (!dateTime.isValid()) {
          console.log("DateTimeGetData: Invalid date format");
          return null;
        } else {
          return dateTime.toDate();
        }
      }
    }
  };

  const onDateTimeChanges = (datetime_return, data_val, fieldval) => {
    if (datetime_return) {
      let datetime = new Date();
      datetime.setHours(parseInt(data_val.hour), parseInt(data_val.minute), 0);

      if (formdata[fieldval.model] == "" || formdata[fieldval.model] == null) {
        setformdata((set) => {
          set[`${fieldval.model}`] = datetime.toISOString();

          return {
            ...set,
          };
        });
      } else {
        let updatetime = new Date(formdata[fieldval.model]);
        updatetime.setHours(
          parseInt(data_val.hour),
          parseInt(data_val.minute),
          0
        );
        setformdata((set) => {
          set[`${fieldval.model}`] = datetime.toISOString();

          return {
            ...set,
          };
        });
      }
    } else {
      const yyyy = data_val.getFullYear();
      let mm = data_val.getMonth() + 1;
      let dd = data_val.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = yyyy + "-" + mm + "-" + dd;
      if (formdata[fieldval.model] == "" || formdata[fieldval.model] == null) {
        handleChangedata(fieldval, { value: data_val });
      } else {
        let updatetime = new Date(formdata[fieldval.model]);
        updatetime.setFullYear(yyyy, mm, dd);
        handleChangedata(fieldval, { value: data_val });
      }
    }
  };
  async function getcontactbyid(id){
    let url = `/commonsearch?module=contact`
    let payload = {
      "search_value": id,
      "fields": "id",
      "skip": 0,
      "take": 10
    }
let response=  await Dispatch(Getsearch(url, payload))
if (response.status === 200) {
  let values = response.data.data[0]
  return [values]
}
  }
  function objectConverter(val) {
    let arr = JSON.parse(val.values[0]);
    if (val.fieldType === "RadioButton") {
      return (
        <>
          {arr.map((res, i) => {
            return (
              <Form.Check
                key={val.model + i + Math.round(Math.random() * 100).toString()}
                value={formdata[`${val.model}`]}
                disabled={val.disabled || val.readonly}
                onChange={() => handleChangedata(val, res)}
                checked={res.value === formdata[`${val.model}`]}
                type="radio"
                label={res.name}
              />
            );
          })}
        </>
      );
    } else if (val.fieldType === "Checkbox") {
      let val_ = formdata[`${val.model}`];
      return (
        <Form.Check
          key={val.model + Math.round(Math.random() * 100).toString()}
          value={formdata[`${val.model}`]}
          disabled={val.disabled || val.readonly}
          onChange={(e) =>
            handleChangedata(val, {
              checked: e.target.checked.toString(),
              value: val.model,
            })
          }
          type="checkbox"
          checked={val_ == "true"}
          label={`${val.label}`}
        />
      );
    } else {
      let default_value = [];
      let option_arr = [];
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        let obj = {};
        let default_val = {};
        obj["value"] = element.value;
        obj["label"] = element.name;
        option_arr.push(obj);
        if (formdata[`${val.model}`] === element.value) {
          default_val["value"] = element.value;
          default_val["label"] = element.name;
          default_value.push(default_val);
        }
      }
      return (
        <>
          <Select
            key={val.model + Math.round(Math.random() * 100).toString()}
            isMulti={val.multiple}
            isDisabled={val.disabled}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
            onChange={(e) => handleChangedata(val, e)}
            value={SelectedGetData(val)}
            options={val.model == 'disposition' ? dispo : val.model == 'timezone' ? timezone : val.model == 'tags' ? tags : val.model == "list_id" ? Listdata : val.model == "lead_status" ? leadstatus : val.model == "source" ? source : option_arr}
            isSearchable={true && val.readonly ? false : true}
            menuIsOpen={val.readonly ? false : undefined}
            placeholder={val.placeholder}
            menuPlacement={`auto`}
          />
        </>
      );
    }
  }

  function timer(data) {
    let val = data.val;
    let datetime = data.datetime;
    if (datetime) {
      onDateTimeChanges(true, data, val);
    } else {
      onTimeChanges(data, val);
    }
  }
  function openchatbox(data) {
    setchatdata(data)
  }
  function closeMsgpopup() {
    setchatdata({})
  }
  const promiseOptions = async(inputValue) =>

    new Promise(async(resolve) => {
      setTimeout(() => {
        let url = `/commonsearch?module=contact`
        let payload = {
          "search_value": inputValue,
          "fields": "name",
          "skip": 0,
          "take": 10
        }
        Dispatch(Getsearch(url, payload))
          .then(async(response) => {
            if (response.status === 200) {
              let values = response.data.data
              let arr = []
              for (let i = 0; i < values.length; i++) {
                const element = values[i];
                arr.push({phone_number:element.phone_number, label: element.name , value: element.id })

              }
              if(formdata[`${'associated_id'}`]!==null && formdata[`${'associated_id'}`]!=="" ){
                let id=await getcontactbyid(formdata[`${'associated_id'}`])
                if(id.length>0){
                 arr.push({phone_number:id[0].phone_number, label: id[0].name , value: id[0].id })
                }
               }
              
              setloadvalues(arr)
              resolve(arr);
            } else {

              resolve([]);
            }
          })
          .catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'fetch',
              module: 'ticket',
            })
          })
      }, 500);
    });
  return (
    <div>
      <Dialogbox
        fields={closepopup}
        value={popupdata}
        defaultclass={"dialog-body-default"}
      />
      <ChatBox formdata={formdata} chatdata={chatdata} closeMsgpopup={closeMsgpopup}></ChatBox>
      <Dialogbox
        fields={requireclosepopup}
        value={requiredpopupdata}
        defaultclass={"dialog-body-default"}
      />
      <div className="Customform-main-view">
        <>
          {catagory.map((resp, i) => {
            return (
              <div>
                {resp && (
                  <>
                    <div
                      className="Customform-catagory"
                      key={resp + i.toString()}
                    >
                      <div className="Customform-catagory-lft">
                        <div
                          className="Customform-category-icon"
                          key={resp + i + i.toString()}
                        >
                          {" "}
                          <BsFillHexagonFill />
                        </div>
                        <div
                          className="Customform-category-title"
                          key={resp + i + i + i.toString()}
                        >
                          {resp}
                        </div>
                      </div>
                    </div>

                    <div
                      className="Customform-body"
                      key={resp + i + i + i + i.toString()}
                    >
                      {customField.filter(res => res.model !== 'disposition').map((res, i) => {
                        return (
                          <>
                            {resp === res.catagory_value &&
                              res.visible == true &&
                              res.show_on_popup == true && (
                                <div
                                  className="Customform-fields"
                                  key={res.model}
                                >
                                  {res.fieldType == 'SelectList' && res.model === "associated_id" && (
                                    <>
                                      <div className="Customform-field-cnt">
                                        <label
                                          className={
                                            res.required
                                              ? 'label_cls required'
                                              : 'label_cls'
                                          }
                                        >
                                          {res.label}{' '}
                                        </label>
                                        {SelectedGetData(res)!==null&&<FiNavigation onClick={() => {   navigate(`/contact/${Encode( SelectedGetData(res).phone_number)}/${ formdata[`${res.model}`]}`) }} />}
                                        <AsyncSelect menuPlacement={`auto`}
                                          onChange={(e) => handleChangedata(res, e)}
                                          value={SelectedGetData(res)}
                                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                          menuPortalTarget={document.body}
                                          isDisabled={res.disabled}
                                          menuShouldScrollIntoView={false} isMulti={res.multiple}  loadOptions={promiseOptions} defaultOptions />
                                        {res.required &&
                                          formdata[`${res.model}`] == '' &&
                                          validate && (
                                            <span className="require_class">
                                              Please fill required fields
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == "SelectList" && res.model !== "associated_id" && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? "label_cls required"
                                              : res.readonly
                                                ? "label_cls readonly"
                                                : "label_cls"
                                          }
                                        >
                                          {res.label}{" "}
                                        </label>
                                        {objectConverter(res)}
                                        {res.required &&
                                          formdata[`${res.model}`] == "" &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}

                                  {res.fieldType == "TextInput" && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? "label_cls required"
                                              : res.readonly
                                                ? "label_cls readonly"
                                                : "label_cls"
                                          }
                                        >
                                          {res.label}
                                        </label>

                                        {location.pathname !== '/leads/create' && res.type === "phone_number" && AppPermission.customform_msg && <CiChat2 onClick={() => { openchatbox(res) }} />}
                                        <Form.Control
                                          key={res.model + i + i + i.toString()}
                                          disabled={res.disabled}
                                          onChange={(e) =>
                                            textinputchange(res, {
                                              value: e.target.value,
                                            })
                                          }
                                          value={
                                            formdata[`${res.model}`] ===
                                              undefined
                                              ? ''
                                              : (res.type === 'phone_number' && !props.operation) || (localStorage.getItem(`setStatic_number`) !== null && props.operation && "" != formdata[`${res.model}`]) || (res.model === 'phone_number' && props.operation && flagnumber) ? fromat(formdata[`${res.model}`], { maskbool: true }) : formdata[`${res.model}`]
                                          }
                                          className="form-control"
                                          readOnly={(state_value.background.oncall || flagnumber) && res.model === 'phone_number' ? true : localStorage.getItem(`setStatic_number`) !== null && res.model === 'phone_number' ? true : res.readonly}
                                          placeholder={res.placeholder}
                                        />
                                        {res.required &&
                                          formdata[`${res.model}`] == "" || formdata[`${res.model}`] == undefined &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                        {regexvalidation[`${res.model}`] && (
                                          <span className="require_class">
                                            Please Enter Valid {Format('format')}
                                          </span>
                                        )}

                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == "LongTextInput" && (
                                    <>
                                      <label
                                        key={res.model + i.toString()}
                                        className={
                                          res.required
                                            ? "label_cls required"
                                            : res.readonly
                                              ? "label_cls readonly"
                                              : "label_cls"
                                        }
                                      >
                                        {res.label}
                                      </label>
                                      <Form.Control
                                        key={res.model}
                                        disabled={res.disabled}
                                        readOnly={res.readonly}
                                        onChange={(e) =>
                                          gettextareavalue(e.target.value, res.model)
                                        }
                                        value={
                                          formdata[`${res.model}`] === undefined
                                            ? ""
                                            : formdata[`${res.model}`]
                                        }
                                        as="textarea"
                                        placeholder={res.placeholder}
                                      />
                                      {res.required &&
                                        formdata[`${res.model}`] == "" &&
                                        validate && (
                                          <span className="require_class">
                                            Please Fill Required Field
                                          </span>
                                        )}
                                      {textcount[`${res.model}`] === 0 && (
                                        <span className="require_class">
                                          Max Characters 255 Reached
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {res.fieldType == "RadioButton" && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        {/* {JSON.stringify(res)} */}
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? "label_cls required"
                                              : res.readonly
                                                ? "label_cls readonly"
                                                : "label_cls"
                                          }
                                        >
                                          {res.label}{" "}
                                        </label>
                                        {objectConverter(res)}
                                        {res.required &&
                                          formdata[`${res.model}`] == "" &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == "Checkbox" && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? "label_cls required"
                                              : res.readonly
                                                ? "label_cls readonly"
                                                : "label_cls"
                                          }
                                        >
                                          {res.label}{" "}
                                        </label>
                                        {objectConverter(res)}
                                        {res.required &&
                                          formdata[`${res.model}`] == "" &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == "DatePicker" && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? "label_cls required"
                                              : res.readonly
                                                ? "label_cls readonly"
                                                : "label_cls"
                                          }
                                        >
                                          {res.label}
                                        </label>

                                        <DatePicker
                                          key={res.model + i + i + i.toString()}
                                          disabled={res.disabled}
                                          readOnly={res.readonly}
                                          dateFormat="dd/MM/yyyy"
                                          selected={DateGetData(
                                            res.fieldType,
                                            res.model
                                          )}
                                          minDate={res.datelimit === true ? new Date(`${res.datelimitdata[0]}`) : ""}
                                          maxDate={res.datelimit === true ? new Date(`${res.datelimitdata[1]}`) : ""}
                                          onChange={(date_value) =>
                                            setuserdate(date_value, res)
                                          }
                                          placeholderText={res.placeholder}
                                          className="form-control"
                                        />
                                        {res.required &&
                                          formdata[`${res.model}`] == "" &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == "TimePicker" && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? "label_cls required"
                                              : res.readonly
                                                ? "label_cls readonly"
                                                : "label_cls"
                                          }
                                        >
                                          {res.label} {`(HH:MM)`}
                                        </label>
                                        <Timer
                                          key={res.model + i + i + i.toString()}
                                          currentvalue={DateGetData(
                                            res.fieldType,
                                            res.model
                                          )}
                                          disabled={res.disabled}
                                          readOnly={res.readonly}
                                          valuegetter={res}
                                          datetime={false}
                                          minutefinaldata={timer}
                                          hoursfinaldata={timer}
                                        ></Timer>
                                        {res.required &&
                                          formdata[`${res.model}`] == "" &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                  {res.fieldType == "DatetimePicker" && (
                                    <>
                                      <div
                                        className="Customform-field-cnt"
                                        key={res.model + i.toString()}
                                      >
                                        <label
                                          key={res.model + i + i.toString()}
                                          className={
                                            res.required
                                              ? "label_cls required"
                                              : res.readonly
                                                ? "label_cls readonly"
                                                : "label_cls"
                                          }
                                        >
                                          {res.label} {`(HH:MM DD/MM/YYYY)`}
                                        </label>
                                        <div
                                          className="DatetimePicker"
                                          key={res.model + i + i + i.toString()}
                                        >
                                          {/* <div
                                            className="Datetime_container"
                                            key={
                                              res.model +
                                              i +
                                              i +
                                              i +
                                              i.toString()
                                            }
                                          >
                                            <Timer
                                              key={
                                                res.model +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i.toString()
                                              }
                                              currentvalue={DateTimeGetData(
                                                true,
                                                res.model
                                              )}
                                              disabled={res.disabled}
                                              readOnly={res.readonly}
                                              valuegetter={res}
                                              datetime={true}
                                              minutefinaldata={timer}
                                              hoursfinaldata={timer}
                                            ></Timer>
                                          </div> */}

                                          <div
                                            className="Datetime_container"
                                            key={
                                              res.model +
                                              i +
                                              i +
                                              i +
                                              i +
                                              i +
                                              i.toString()
                                            }
                                          >
                                            <DatePicker
                                              key={
                                                res.model +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i +
                                                i.toString()
                                              }
                                              showTimeSelect
                                              timeIntervals={1}
                                              disabled={res.disabled}
                                              readOnly={res.readonly}
                                              dateFormat="yyyy/MM/d HH:mm"
                                              selected={DateTimeGetData(
                                                false,
                                                res.model
                                              )}
                                              onChange={(date_value) =>
                                                onDateTimeChanges(
                                                  false,
                                                  date_value,
                                                  res
                                                )
                                              }
                                              timeFormat="HH:mm"
                                              placeholderText={res.placeholder}
                                              className="form-control"
                                            />
                                          </div>
                                        </div>
                                        {res.required &&
                                          formdata[`${res.model}`] == "" &&
                                          validate && (
                                            <span className="require_class">
                                              Please Fill Required Field
                                            </span>
                                          )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
}

export default React.forwardRef(CustomForm);