import React, { useState, useEffect } from 'react'
import fromat from '../../utils/format-text'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Encode } from '../../utils/EncodeDecode'
function AutodialList(props) {
    let navigate = useNavigate()
    let stateValue = useSelector((state) => state);
    const [autodial_list, setautodial_list] = useState([   

    ])

    useEffect(() => {
        let autodata = stateValue.call.autodialdatas.map((e) => {
            return { leadName: e.first_name, leadNumber: e.phone_number, Lead_id: e.lead_id }
        })
        console.log(autodata,"uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
        setautodial_list(autodata)
    }, [])

    function clicktonav(data) {
        navigate(`/leads/${Encode(data.leadNumber)}/${data.Lead_id}`)
    }
    return (
        <>
            <div className="callscreen-log-div-bottom">
                <div
                    id="abandonedDiv"
                    style={{
                        height: '100%',
                        overflow: 'auto',
                        color: 'grey',
                        textAlign: 'center'
                        , margin: "5px"
                    }}
                >
                    {autodial_list.length > 0 && (
                        <div style={{ display: 'flex', flexDirection: 'column', "padding-right": '10px', }}>
                            {autodial_list.map((ele, k) => {
                                return (
                                    <div
                                        key={k.toString()}
                                        className="d-flex align-items-center"
                                        style={{

                                            borderBottom: '1px solid rgb(40 48 54 / 10%)',
                                            padding: '10px 0',
                                        }}
                                    >

                                        <div
                                            className="d-flex justify-content-between"
                                            style={{ width: '100%', paddingLeft: 10 }}
                                        >

                                            <>
                                                <div className="calls lead-autodial-flx">
                                                    <div className='lead-autodial-flx-lft'>
                                                        <h3 className='lead-autodial-flx-lft-sno'>{k + 1}</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className='lead-autodial-name'>{fromat(ele.leadName)}</h3>
                                                        <p>{fromat(ele.leadNumber, { maskbool: true })
                                                        }</p>
                                                    </div>

                                                </div>

                                            </>

                                        </div>
                                        <div
                                            style={{ cursor: 'pointer' }} onClick={() => { clicktonav(ele) }}
                                            className="material-icons complete_call"

                                        >
                                            contact_page
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {autodial_list.length === 0 && (
                        <div className="nodata log-noanswer">No Data</div>
                    )}
                </div>
            </div>
        </>
    )
}


export default AutodialList