import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { useParams, useLocation } from "react-router-dom";
import Dialogbox from './Dialogbox'
import { Decode } from '../utils/EncodeDecode'
import { Message } from '../redux/actions/Message';
import EventEmitter from '../utils/EventEmitter';
function ChatBox(props) {
  let { phone_number } = useParams()
  let dispatch = useDispatch()
  phone_number = Decode(phone_number)
  useEffect(() => {
     if (Object.keys(props.chatdata).length > 0) {
      setpopupdata((data) => {
        data.open = true
        data.field[0].value=props.formdata[`${props.chatdata.model}`]
        return {
          ...data,
        }
      })
    }
  }, [props.chatdata])
  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'TextInput',
        label: 'Phone Number',
        placeholder: 'Phone Number',
        required: true,
        model: 'phone_number',
        value: '',
        readonly: false,
        multiple: false,
        fieldtype: 'phone_number',
        model_type: "Number"
      },

      {
        type: 'LongTextInput',
        label: 'Message',
        placeholder: 'Type message',
        required: true,
        model: 'message',
        value: '',
        multiple: false,
      },
    ],

    title: 'Chat Box',
    Message: 'Created Successfully ',
    Butonstatus: 'Create',
  })


  async function closepopup(data) {
    if (data.action === "create") {
      console.log(data, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", "/message/{services}")
      let output = await dispatch(Message(data.data[0]))
      if (output.status === 200) {
        setpopupdata((data) => {
          data.open = false
          props.closeMsgpopup()
          return {
            ...data,
          }
        })
        EventEmitter.emit('error-message-getter', {
          action: 'template',
          module: "",
        })
      }else{
        EventEmitter.emit('error-message-getter', {
          action: 'message',
          module: "Fail to send Message",
        })
      }
    }else{
      setpopupdata((data) => {
        data.open = false
        props.closeMsgpopup()
        return {
          ...data,
        }
      })
    }

  }


  return (
    <>
      <Dialogbox
        fields={closepopup}
        value={popupdata}
        defaultclass={'dialog-body-default'}
      />

    </>
  )
}



export default ChatBox
