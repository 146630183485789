import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom';
import Timeline from '../Lead/Lead-timeline'
import CustomForm from './Ticket-Customform'
import Notepad from '../Lead/Lead-notepad'
import Meeting from '../Lead/Lead-meeting'
import { useParams, useLocation } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { PopupRouter } from '../../redux/actions/Customform'
import { TicketUpdate, TicketMerge, TicketDeMerge, TicketHistory } from '../../redux/actions/TicketAction.js'
import { useNavigate } from 'react-router'
import userEvent from '@testing-library/user-event'
import { CustommoduleFetch } from '../../redux/actions/Customform'
import EventEmitter from '../../utils/EventEmitter'
import Dialogbox from '../Dialogbox'
import Ring from '../../assets/images/timeline/bluelink.svg'
import Unlink from '../../assets/images/timeline/blueunlink.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import fromat from '../../utils/format-text'
import LinkTickets from '../Lead/Other-Ticket'
import { adminPermission } from '../../utils/localstorage-provider.js'
import {
    ContactFetch,
} from '../../redux/actions/ContactActions'
import { Encode, Decode } from '../../utils/EncodeDecode'
function TicketMainView(props) {
    let AppPermission
    const [custom_module, setcustom_module] = useState([])
    let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
    if (localStorage.getItem('config')) {
        AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    let dispatch = useDispatch()
    const agent = localStorage.getItem('user')
    let Dispatch = useDispatch()
    const location = useLocation()
    let navigate = useNavigate()
    const [count, setCount] = useState(0)
    const [save, setsave] = useState(false)
    const [viewcheck, setviewcheck] = useState(false)
    const [merge_ids, setmerge_ids] = useState("")
    let { ticket_id, phone_number, meeting_id } = useParams()
    phone_number = Decode(phone_number)
    let [fetchapi, setfetchapi] = useState(false)
    let [subject, setsubject] = useState("")
    let [isparent, setisparent] = useState(false)
    let [ischild, setischild] = useState(false)
    const [popupdata, setpopupdata] = useState({
        open: false,
        Type: 'form_bar',
        field: [],
        title: 'Assign Ticket ',
        Message: `once you assign ticket can't revock !!! `,
        Butonstatus: 'Save',
    })

    const [unlinkpopupdata, setunlinkpopupdata] = useState({
        open: false,
        Type: 'form_bar',
        field: [],
        title: 'Demerge Ticket ',
        Message: `Are You Sure You Want To Demerge !!! `,
        Butonstatus: 'Save',
    })
    const [mergepopupdata, setmergepopupdata] = useState({
        open: false,
        Type: 'form_bar',
        field: [
            {
                type: 'SearchSelectList',
                label: 'Ticket Id ',
                placeholder: 'please select',
                required: true,
                model: 'Ticket_Id',
                multiple: true,
                value: '',
                api_value: "",
            }, {
                type: 'SelectList',
                label: 'Status',
                placeholder: 'please select',
                required: true,
                model: 'merge_status',
                multiple: false,
                value: '',
                option: []
            }, {
                type: '',
                label: 'Is Duplicate ',
                placeholder: 'please select',
                required: false,
                model: 'Is_Duplicate',
                multiple: false,
                value: '',
            }
        ],
        title: 'Merge Ticket ',
        Butonstatus: 'Save',
    })
    const [checked, setchecked] = useState(false)
    const [name, setname] = useState('noname')
    let [regexvalue, setregexvalue] = useState(true)
    const { triggerCallScreenFunction ,outletData} = useOutletContext();
  
    let [triggerdispo, settriggerdispo] = useState(false)
  
  
    useEffect(() => {
      
      if(outletData){ 
        
       setCount(1)
       settriggerdispo(true)
      
       
      }
     }, [outletData])
    useEffect(() => {
        if (count === 1) {
            localStorage.setItem("oncustomform", "true")
        } else {
            localStorage.removeItem("oncustomform")
        }
    }, [count])
    useEffect(() => {
        dispatch(CustommoduleFetch({ template_name: JSON.parse(localStorage.getItem("selectedCampaign_data")).template_name, module: "ticket" })).then((response) => {
            if (response.data.statusCode === 200) {
                setcustom_module(response.data.data)
            } else {
                setcustom_module([])
            }
        })
            .catch((err) => {
                EventEmitter.emit('error-message-getter', {
                    action: 'create',
                    module: 'activity',
                })
            })
        if (AppPermission.ticket_duplicate) {
            mergepopupdata.field.push()

        }

        if (location.pathname === `/meeting/${Encode(phone_number)}/${meeting_id}` && AppPermission.view_meeting) {
            if (AppPermission.meeting_detail) {
                setCount(3)
            } else if (AppPermission.meeting_custom_form) {
                setCount(1)
            }
        } else {
            if (AppPermission.ticket_custom_form) {
                setCount(1)
            } else if (AppPermission.ticket_activity) {
                setCount(2)
            }
            else if (AppPermission.ticket_history) {
                setCount(7)
            } else if (AppPermission.ticket_comment_create) {
                setCount(5)
            } else if (AppPermission.ticket_merge_view) {
                setCount(6)
            } else if (AppPermission.ticket_meeting) {
                setCount(3)
            }
        }


        for (let i = 0; i < props.statedata.fields.length; i++) {
            const element = props.statedata.fields[i];
            if (element.model === "status") {
                let arr = JSON.parse(element.values)
                let mainarr = []
                for (let j = 0; j < arr.length; j++) {
                    const element = arr[j];
                    mainarr.push({ label: element.name, value: element.value })

                }
                let set = mergepopupdata
                set.field[1].option = mainarr


                setmergepopupdata(set)
            }

        }
    }, [])

    useEffect(() => {
        if (props.formdatas.status === 200) {
            let setparentid = mergepopupdata
            setparentid.field[0].api_value = props.formdatas.data.data[0].others.ticket_id
            setmergepopupdata(setparentid)


            setsubject(`${props.formdatas.data.data[0].others.ticket_id} ${props.formdatas.data.data[0].others.subject}`)
            setname(props.formdatas.data.data[0].basic.first_name)
            if (props.formdatas.data.data[0].others.merged_as === "parent") {
                setisparent(true)
            } else {
                setischild(true)
            }
            if (
                props.formdatas.data.data[0].others.user === '' ||
                props.formdatas.data.data[0].others.user === null ||
                props.formdatas.data.data[0].others.user === undefined
            ) {

                setviewcheck(true)
            }
            let id = ""
            if (props.formdatas.data.data[0].others.duplicate_merge_to !== "" && props.formdatas.data.data[0].others.nonduplicate_merge_to !== "") {
                id += props.formdatas.data.data[0].others.duplicate_merge_to + ',' + props.formdatas.data.data[0].others.nonduplicate_merge_to
            } else if (props.formdatas.data.data[0].others.nonduplicate_merge_to !== "") {
                id += props.formdatas.data.data[0].others.nonduplicate_merge_to
            }
            else if (props.formdatas.data.data[0].others.duplicate_merge_to !== "") {
                id += props.formdatas.data.data[0].others.duplicate_merge_to
            }
            setmerge_ids(id)
        } else {
            setsubject("Create New Ticket")
        }
    }, [props.formdatas])

    function contactdialogopen(phone, model) {
        if (model === "contacts") {
            let payload = {
                phone_number: phone
              }
              payload.module = 'contacts'
              dispatch(PopupRouter(payload)).then((res) => {
                if (res.data.ids.length > 0) {
                  let id = res.data.ids[0].id
                  navigate(`/contact/${Encode(phone)}/${id}`)
                } else {
        
                  navigate('/contact/create')
                  localStorage.setItem(`setStatic_number`,`${phone}`)
                }
              }).catch((err) => {
                EventEmitter.emit('error-message-getter', {
                  action: 'fetch',
                  module: 'popup router',
                })
              })
        }else{
            let payload = {
                phone_number: phone,
                template_name: campaigninfo.template_name,
            }
            payload.module = 'leads'
            dispatch(PopupRouter(payload)).then((res) => {
                if (res.data.ids.length > 0) {
                    let id = res.data.ids[0].lead_id
                    navigate(`/leads/${Encode(phone)}/${id}`)
                } else {
                    navigate('/leads/create')
                    localStorage.setItem(`setStatic_number`,`${phone}`)
                }
            }).catch((err) => {
                EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'popup router',
                })
            }) 
        }
    }

    function assign() {
        setpopupdata((set) => {
            set.open = true
            return {
                ...set,
            }
        })
        setchecked(true)
    }
    function update() {
        setsave(true)
    }

    function saveleaddetail() {
        setsave(false)
    }

    const createticket = () => {
        navigate(`/tickets/create`)
      }
    const closepopup = (data) => {
        if (data.action === "cancel") {
            setchecked(false)
            setpopupdata((set) => {
                set.open = false
                return {
                    ...set,
                }
            })
        } else {
            Dispatch(
                TicketUpdate(props.formdatas.data.data[0].others.ticket_id, { fields: [{ name: 'user', value: agent }, { name: "modified_by", value: agent }] }),
            )
                .then(function (res) {
                    if (res.status === 200) {
                        setpopupdata((set) => {
                            set.open = false
                            return {
                                ...set,
                            }
                        })
                        setviewcheck(false)
                    }
                })
                .catch((err) => {
                    EventEmitter.emit('error-message-getter', {
                        action: 'update',
                        module: 'ticket',
                    })
                })
        }
    }
    const unlinkclosepopup = (data) => {
        if (data.action === "cancel") {
            setunlinkpopupdata((set) => {
                set.open = false
                return {
                    ...set,
                }
            })
        } else {
            let id
            if (props.formdatas.data.data[0].others.is_duplicate === "true") {
                id = props.formdatas.data.data[0].others.duplicate_merge_to
            } else {
                id = props.formdatas.data.data[0].others.nonduplicate_merge_to
            }
            let payload = {
                agent: agent,
                parent_id: props.formdatas.data.data[0].others.ticket_id.toString(),
                child_id: id,
                duplicatemerge: props.formdatas.data.data[0].others.is_duplicate,
                portal: "false"
            }
            Dispatch(
                TicketDeMerge(payload),
            )
                .then(function (res) {
                    if (res.status === 201) {
                        setunlinkpopupdata((set) => {
                            set.open = false
                            return {
                                ...set,
                            }
                        })
                        props.fetchapi(!fetchapi)
                        setfetchapi(!fetchapi)
                    }
                })
                .catch((err) => {
                    EventEmitter.emit('error-message-getter', {
                        action: 'update',
                        module: "ticket demerge",
                    })
                })
        }
    }

    function mergepopup() {
        setmergepopupdata((set) => {
            set.open = true
            return {
                ...set
            }
        })
    }
    function unmergepopup() {
        setunlinkpopupdata((set) => {
            set.open = true
            return {
                ...set
            }
        })
    }


    function mergeclosepopup(data) {
        if (data.action === "cancel") {
            let set = mergepopupdata
            set.field[2].type = ""
            set.field[1].type = "SelectList"
            setmergepopupdata(set)
            setmergepopupdata((set) => {
                set.open = false
                return {
                    ...set
                }
            })
        } else {
            let is_Duplicate = data.data[0].Is_Duplicate
            let merge_data = {}
            if (is_Duplicate === "true") {
                merge_data.agent = agent
                merge_data.parent_id = props.formdatas.data.data[0].others.ticket_id.toString()
                merge_data.child_id = data.data[0].Ticket_Id
                merge_data.duplicatemerge = "true"
                merge_data.status = "closed"
            } else {
                merge_data.agent = agent
                merge_data.parent_id = props.formdatas.data.data[0].others.ticket_id.toString()
                merge_data.child_id = data.data[0].Ticket_Id
                merge_data.duplicatemerge = 'false'
                merge_data.status = data.data[0].merge_status
            } Dispatch(
                TicketMerge(merge_data),
            )
                .then(function (res) {
                    if (res.status === 201) {
                        let set = mergepopupdata
                        set.field[2].type = ""
                        set.field[1].type = "SelectList"
                        props.fetchapi(!fetchapi)
                        setmergepopupdata(set)
                        setmergepopupdata((set) => {
                            set.open = false
                            return {
                                ...set
                            }
                        })
                    }
                })
                .catch((err) => {
                    EventEmitter.emit('error-message-getter', {
                        action: 'update',
                        module: 'merge ticket',
                    })
                })
        }
    }

    function dialogonchange(value) {
        if (value === "true") {
            let set = mergepopupdata
            set.field[1].type = " "
            setmergepopupdata(set)
        }
        else if (value === "false") {
            let set = mergepopupdata
            set.field[1].type = "SelectList"
            setmergepopupdata(set)
        }
        else {
            if (AppPermission.ticket_duplicate) {
                let set = mergepopupdata
                set.field[2].type = "Toggle"
                setmergepopupdata(set)
            }

        }
    }
    function fetchdata(ticket) {
        if (ticket === "ticket") {
            setisparent(false)
            setischild(false)

            setCount(1)
        } else {
            props.fetchapi(!fetchapi)
        }

    }
    function regexval(data) {
        if (data) {
            setregexvalue(true)
        } else {
            setregexvalue(false)
        }
    }
    function closedispo(ticket) {

        settriggerdispo(false)
        triggerCallScreenFunction(false)
     
       }
    return (
        <>
            <Dialogbox
                fields={closepopup}
                value={popupdata}
                defaultclass={'dialog-body-default'}
            />
            <Dialogbox
                fields={unlinkclosepopup}
                value={unlinkpopupdata}
                defaultclass={'dialog-body-default'}
            />

            <Dialogbox
                fields={mergeclosepopup}
                value={mergepopupdata}
                dialogonchange={dialogonchange}
                defaultclass={'dialog-body-default1'}
            />
            {props.formdatas.status === 200 && (
                <div className="activity" style={{ cursor: 'pointer' }}>
                    <div>
                        {location.pathname === `/meeting/${Encode(phone_number)}/${meeting_id}` && (location.pathname.split("/")[1] == "meeting" && AppPermission.view_meeting && AppPermission.meeting_detail) && (
                            <span
                                onClick={() => setCount(3)}
                                className={count === 3 ? 'active' : ''}
                            >
                                Meetings
                            </span>
                        )}
                        {AppPermission.ticket_custom_form && <span
                            onClick={() => setCount(1)}
                            className={count === 1 ? 'active' : ''}
                        >
                            Details
                        </span>}
                        {AppPermission.ticket_activity && <span
                            onClick={() => setCount(2)}
                            className={count === 2 ? 'active' : ''}
                        >
                            {' '}
                            Activity
                        </span>}
                        {AppPermission.ticket_history && <span
                            onClick={() => setCount(7)}
                            className={count === 7 ? 'active' : ''}
                        >
                            {' '}
                            History
                        </span>}
                        {location.pathname !== `/meeting/${Encode(phone_number)}/${meeting_id}` && AppPermission.ticket_meeting && <span
                            onClick={() => setCount(3)}
                            className={count === 3 ? 'active' : ''}
                        >
                            Meetings
                        </span>}
                        {AppPermission.ticket_comment_create && <span
                            onClick={() => setCount(5)}
                            className={count === 5 ? 'active' : ''}
                        >
                            Comments
                        </span>}
                        {AppPermission.ticket_merge_view && props.formdatas.data.data[0].others.is_merge === "true" && <span
                            onClick={() => setCount(6)}
                            className={count === 6 ? 'active' : ''}
                        >
                            Merged Tickets
                        </span>}
                    </div>
                </div>
            )}

            <div className="lead-main-card">
                {count === 1 && AppPermission.ticket_custom_form && (
                    <>
                        <div className="Customform-catagory Customform-header">
                            <div className="Customform-catagory-lft">

                                {props.formdatas.status === 200 && <div className={props.formdatas.data.data[0].others.priority === "low" ? ' triangle-down' : props.formdatas.data.data[0].others.priority === "medium" ? " triangle-right" : "triangle-up"}></div>}
                                <div className="Customform-category-title Customform-ticket-category-title">
                                    {fromat(subject)}
                                </div>
                            </div>
                            <div className="Customform-catagory-rht">

                                <div className="Customform-save-bts">
                                    {custom_module.length > 0 && location.pathname !== "/tickets/create" && <>
                                        {custom_module.map((item, i) => {
                                            return (
                                                <>
                                                    {AppPermission.view_custommodule && <div onClick={() => {
                                                        localStorage.setItem("module_back", location.pathname)
                                                        navigate(`/custom-module/${item.template_name}`)
                                                    }} key={i}
                                                        className="navbar-custom-module-icon navbar-logo-img Customform-save-icon wa-icon">{item.template_name.split("")[0].toUpperCase()}</div>}
                                                </>

                                            )
                                        })
                                        }
                                    </>}
                                    {adminPermission('leadmanagement:tickets:create')&&props.operation === false && AppPermission.contact_create &&
                                        <OverlayTrigger
                                            placement={'bottom'}
                                            overlay={<Tooltip>
                                                New Ticket</Tooltip>}
                                        >
                                           <div
                                            onClick={createticket}
                                            className="Customform-save-icon material-icons"
                                          >
                                            add_circle
                                          </div>
                                        </OverlayTrigger>
                                    }
                                    {adminPermission('leadmanagement:tickets:edit')&&viewcheck && (
                                        <OverlayTrigger
                                            placement={'bottom'}
                                            overlay={<Tooltip>Assign Me</Tooltip>}
                                        >
                                            <div className="Customform-save-icon ">
                                                <Form.Check
                                                    checked={checked}
                                                    type="checkbox"
                                                    id="custom-check"
                                                    className="check-box"
                                                    onChange={(e) => {
                                                        if (e.target.checked.toString() === 'true') {
                                                            assign()
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </OverlayTrigger>
                                    )}

                                    {props.formdatas.status === 200 && <>
                                        {AppPermission.ticket_merge && props.formdatas.data.data[0].others.merged_as === "parent" && viewcheck === false &&
                                            <OverlayTrigger
                                                placement={'bottom'}
                                                overlay={<Tooltip>Merge</Tooltip>}
                                            >
                                                <img onClick={mergepopup}
                                                    className="Customform-ring"
                                                    src={Ring}
                                                    alt="Text0"
                                                />
                                            </OverlayTrigger>}
                                        {AppPermission.ticket_demerge && props.formdatas.data.data[0].others.merged_as === "child" && viewcheck === false &&
                                            <OverlayTrigger
                                                placement={'bottom'}
                                                overlay={<Tooltip>De
                                                    Merge</Tooltip>}
                                            >
                                                <img onClick={unmergepopup}
                                                    className="Customform-ring"
                                                    src={Unlink}
                                                    alt="Text0"
                                                />
                                            </OverlayTrigger>}

                                    </>}
                                    {adminPermission('leadmanagement:contacts:create')&&adminPermission('leadmanagement:contacts:view')&&props.operation === false && AppPermission.contact_create && AppPermission.view_contacts &&
                                        <OverlayTrigger
                                            placement={'bottom'}
                                            overlay={<Tooltip>
                                                Contact</Tooltip>}
                                        >
                                            <div
                                                className="material-icons Customform-save-icon "
                                                onClick={() => { contactdialogopen(phone_number, 'contacts') }}
                                            >
                                                contacts
                                            </div>
                                        </OverlayTrigger>
                                    }

                                    {props.formdatas.status == 200 && <>
                                        {adminPermission('leadmanagement:lead:view')&&AppPermission.lead_view &&
                                            <OverlayTrigger
                                                placement={'bottom'}
                                                overlay={<Tooltip>
                                                    Lead</Tooltip>}
                                            >
                                                <div
                                                    onClick={() => { contactdialogopen(phone_number, 'leads') }}
                                                    className="material-icons Customform-save-icon "
                                                >contact_page

                                                </div>
                                            </OverlayTrigger>
                                        }
                                    </>}
                                    {adminPermission('leadmanagement:tickets:edit')&&<div
                                        onClick={update}
                                        className={regexvalue ? "material-icons Customform-save-icon " : "material-icons Customform-save-icon disabledbutton"}
                                    >
                                        save
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div
                            className="lead-main-card_sub-content"
                        >
                            <CustomForm
                             triggerdispo={triggerdispo}
                             closedispo={closedispo}
                                operation={props.operation}
                                formdatas={props.formdatas}
                                statedata={props.statedata}
                                savebutton={save}
                                fetchapi={fetchdata}
                                saveleaddetail={saveleaddetail}
                                regexval={regexval}
                            />
                        </div>
                    </>
                )}
                {count === 2 && AppPermission.ticket_activity && (
                    <Timeline view={"action"} id={props?.formdatas?.data?.data[0]?.others?.ticket_id} type={'ticket'} />
                )}
                {count === 7 && AppPermission.ticket_history && (
                    <Timeline view={"history"} id={props?.formdatas?.data?.data[0]?.others?.ticket_id} type={'ticket'} />
                )}
                {count === 5 && AppPermission.ticket_comment_create &&
                    (
                        <Notepad
                            basicdata={{
                                id: props?.formdatas?.data?.data[0]?.others?.ticket_id,
                                all:props?.formdatas?.data?.data[0]?.others
                            }}
                            type={'ticket'}
                        />

                    )}
                {count === 3 && AppPermission.ticket_meeting &&
                    (
                        <Meeting type={"ticket"} name={name} id={props?.formdatas?.data?.data[0]?.others?.ticket_id}
                        />

                    )}


                {count === 6 && AppPermission.ticket_merge_view &&
                    (
                        <LinkTickets fetchapi={fetchdata} id={merge_ids} type={"ticket"}
                        />

                    )}

            </div>
        </>
    )
}

export default TicketMainView
