import React, { Component, useEffect, useState, useMemo, useRef } from 'react'
import Format from '../utils/format-text'
import EventEmitter from '../utils/EventEmitter'
import Dialogbox from './Dialogbox'
import fromat from '../utils/format-text'
import fb from '../assets/images/fb.png'
import webchat from '../assets/images/webchat.svg'
import wp from '../assets/images/wp.png'
import { connect, useDispatch, useSelector } from 'react-redux'
import Incoming from './Incoming'
import Oncall from './Oncall'
import Calllog from './Calllog'
import CallDialPad from './CallDialPad'
import Wpchat from './ChatWindow'
import { ContactFetch } from '../redux/actions/ContactActions'
import ChatM from './ChatM'
import JsSIP from 'jssip'
import { useNavigate } from 'react-router'
import { TicketFetch } from '../redux/actions/TicketAction'
import { WaLive } from '../redux/actions/ChatActions'
import Autodial_Template from './Lead/autodial-template'
import '../assets/css/Callscreen.css'
import { logout } from '../redux/actions/login'
import { PopupRouter } from '../redux/actions/Customform'
import {
  Outbound,
  newMessage,
  dispo,
  callForwardKick,
  callForward,
  addconference,
  conferenceKickout,
  conferenceEnd,
  conferenceMute,
  callGrab,
  wasessionhangup, forcelogout,
  callRecording, terminatecall, initiateEvent, checkagentlive,
  queuePause
} from '../redux/SocketAction/SocketAction'
import {
  session,
  socketmessage, oncall_number,
  callringtone,
  oncall,
  inConference,
  websocketstatus,
  activeConference,
  socketconnection,
  triggerdispostate, callLeadDetail, networkstatus
} from '../redux/background/BackgroundAction'
import { CallActivity } from '../redux/actions/AsterCallAction'
import { CustomformData } from '../redux/actions/Customform'
import {
  incoming,
  on_log,
  on_call,
  beforecallnavpath,
  fetchcallaction,
  autocalldial,
  autodial,
  dispoValidation,
  chatdispoValidation,
  callmodule,
  autodialnumberpopstate
} from '../redux/Calls/CallAction'
import io from 'socket.io-client'
import CallDispo from './callDispo'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { Encode } from '../utils/EncodeDecode'
import fbchat from '../assets/images/fb.svg'
import { setPause } from '../redux/Details/DetailAction'
import Select from 'react-select'
function CallScreen(props) {
  let AppPermission
  var interval = null
  let config
  let process = localStorage.getItem('process')
  let wasession = localStorage.getItem("wasession")
  let [conf_isclicked,setconf_isclicked]=useState(false)
  let tenant
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
  if (localStorage.getItem('config')) {
    tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    config = JSON.parse(localStorage.getItem('config'))
  }
  let dispatch = useDispatch()
  let navigate = useNavigate()
  let location = useLocation()
  let seconds = parseInt(localStorage.getItem("callcount"));
  let campaigndetailslocalstorage = JSON.parse(localStorage.getItem("campaigndetails"))
  const callValue = JSON.parse(localStorage.getItem('callstatus'))
  const user = localStorage.getItem('username')
  const agent = localStorage.getItem('user')
  const station = localStorage.getItem('extension')
  const campaign = localStorage.getItem('selectedCampaign')
  const selected_queue = JSON.parse(localStorage.getItem('selectedQueue'));

  let session_state = useSelector((state) => state)
  const OngoingCall = useRef(session_state); 
  const [waitingresponse, setwaitingresponse] = useState([])
  const [exitchat, setexitchat] = useState({})
  const [callChat, setcallChat] = useState(0)
  const [fb_chat, setfb_chat] = useState(false)
  const [wp_chat, setwp_chat] = useState(false)
  const [sipOptions, setsipOptions] = useState({
    mediaConstraints: {
      audio: true,
      video: false,
    },
  })
  const [openDialog, setopenDialog] = useState(false)
  const [socketFunction, setsocketFunction] = useState()
  const [checktransfer, setchecktransfer] = useState(true)
  const [transferresponse, settransferresponse] = useState([])
  const [callRecordingResponse, setcallRecordingResponse] = useState([])

  const [muteconf, setmuteconf] = useState('')
  const [userMessage, setuserMessage] = useState('')
  const [instantClick, setinstantClick] = useState('')
  const [whatsappsession, setwhatsappsession] = useState([])
  const [count, setcount] = useState(0)
  const [hangupdata, sethangupdata] = useState(0)
  const [list, setlist] = useState(false);
  // const [logout_, setlogout] = useState([])
  const [incom_popup, setIncom_popup] = useState(false)
  let [dialernum, setdialernum] = useState('')
  const [transferStatuschange, setTransferStatusChange] = useState(false);
  let callStatus = JSON.parse(localStorage.getItem('callstatus'))
  const [manualdid, setmanualdid] = useState({})
  const [dids, setdids] = useState([])
  let greetingsheader = ["We’re glad to have you back, [User's Name]!"
    , "Good to see you again, [User's Name]!"
    , "Hello, [User's Name]!",
    "Welcome back, [User's Name]!"]
  let greetings = [
    "Let’s continue where you left off.",
    " Let’s make progress together.",
    " Your workspace is prepared – let’s get started!",
    " Everything is set for a productive session."
  ]
  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: 'Notification_bar',
    field: [],
    title: greetingsheader[Math.floor(Math.random() * greetingsheader.length)].replace("[User's Name]", user),
    Message: greetings[Math.floor(Math.random() * greetings.length)].replace("[User's Name]", user),
    Butonstatus: `Let's go`,
  })
  const intervalRef = useRef(null);
  useEffect(() => {
    const handleLocalStorageChange = () => {
      checkAndRedirectToLogin();
    };

    const checkAndRedirectToLogin = () => {
      if (!localStorage.getItem('loggedIn')) {
        localStorage.clear();
        localStorage.setItem("revertload",'true')
        navigate('/');
      }
    };

    const monitorLocalStorage = () => {
      window.addEventListener('storage', handleLocalStorageChange);

      // redirect immediately in case localStorage was already cleared
      checkAndRedirectToLogin();

      // interval for browsers that don't support the 'storage' event
      intervalRef.current = setInterval(checkAndRedirectToLogin, 1000);
    };

    const cleanUpMonitoring = () => {
      window.removeEventListener('storage', handleLocalStorageChange);
      clearInterval(intervalRef.current);
    };

    monitorLocalStorage();
    return cleanUpMonitoring;
  }, [navigate]);
  useEffect(() => {
    OngoingCall.current = session_state;
}, [session_state]); 

  useEffect(() => {
    if (wasession === "false") {
      dispatch(oncall(false))
    }
  }, [wasession])
  useEffect(() => {
    if (session_state.background.oncall === false) {
      localStorage.setItem("dialpadname", "");
      setdialernum("")
    }
  }, [session_state.background.oncall])



  useEffect(() => {
    let campaigndetails = JSON.parse(localStorage.getItem("campaigndetails"))
    let man_did = JSON.parse(localStorage.getItem("manualdid"))
    let strategy = campaigndetails.did_rotate_strategy
    let did_number
    if (strategy === "manual") {
      did_number = campaigndetails?.did_routing_numbers.split(',')

      if (man_did === null) {
        setmanualdid({
          value: did_number[0],
          label: did_number[0]
        })
        localStorage.setItem('manualdid', did_number[0])
      } else {
        setmanualdid({
          value: man_did,
          label: man_did
        })
      }

      let didarr = []

      for (let i = 0; i < did_number.length; i++) {
        didarr.push({
          value: did_number[i],
          label: did_number[i]
        })
      }
      console.log(didarr, "did_numberdid_numberdid_numberdid_number")
      setdids(didarr)
    }
    event()

    if (
      session_state.background.websocket.WrtcStatus == false &&
      session_state.background.websocket.WsSocketStatus == false
    ) {
      if (AppPermission.webrtc) {
        background()
      }
      socketIo()
    } else if (session_state.background.websocket.WsSocketStatus == false) {
      socketIo()
    } else if (session_state.background.websocket.WrtcStatus == false && AppPermission.webrtc) {
      background()
    }

    sound(false)
    if (wasession === "false") {
      setcallChat(1)
    } else if (wasession === "true") {
      setcallChat(2)
      dispatch(oncall(true))
    }
    if (JSON.parse(localStorage.getItem("wa-session-end")) !== null) {
      chatexit(JSON.parse(localStorage.getItem("wa-session-end")))
    }
    localStorage.setItem("dialpadname", "");
    setdialernum("")
  }, [])
  useEffect(() => {

    if (Object.keys(session_state.background.session).length > 0) {
      let isEnded = session_state.background.session.isEnded()
      if (session_state.background.socket_message.evt === "DISPO") {
        setTransferStatusChange(false)
        if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber) {

          props.dispoValidation(false)
        }
      } else if (session_state.background.socket_message.evt === "ANSWERED" && isEnded === false && AppPermission.webrtc) {
        dispatch(fetchcallaction('on_call'))
      } else if (session_state.background.socket_message.evt === "ANSWERED" && isEnded === true && AppPermission.webrtc) {
        if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber) {
          props.dispoValidation(false)
        }
      } else if (!AppPermission.webrtc) {
        if (session_state.background.socket_message.evt === "ANSWERED" || session_state.background.socket_message.evt === "RINGING") {
          dispatch(initiateEvent(false))
          dispatch(fetchcallaction('on_call'))
        }
      }

    } else if (Object.keys(session_state.background.session).length === 0) {
      if (session_state.background.socket_message.evt === "DISPO") {
        if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber) {

          props.dispoValidation(false)
        }
      } else if (session_state.background.socket_message.evt === "ANSWERED" && AppPermission.webrtc) {
        if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber) {
          // if(callStatus !== null){
          props.dispoValidation(false)
          // }
        }
      } else if (!AppPermission.webrtc) {
        if (session_state.background.socket_message.evt === "ANSWERED" || session_state.background.socket_message.evt === "RINGING") {
          dispatch(fetchcallaction('on_call'))
        }
      }

    }

  }, [session_state.background.socket_message.evt])

  useEffect(() => {
    if (callChat === 2) {
      getlocalstoredata(agent)
    }

  }, [callChat])
  useEffect(() => {
    let render_ = JSON.parse(localStorage.getItem("waformdata"))
    if (render_ !== null) {
      if (render_.render === 0) {
        localStorage.setItem("waformdata", JSON.stringify(session_state.background.oncalllead[0]))
      }
    } else {
      localStorage.setItem("waformdata", JSON.stringify(session_state.background.oncalllead[0]))
    }

  }, [session_state.background.oncalllead[0]])
  useMemo(() => {
    if (session_state.background.socket_message.evt === "DISPO") {

      if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber && !campaigndetailslocalstorage.auto_dispo) {


        props.dispoValidation(false)
      } else if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber && campaigndetailslocalstorage.auto_dispo) {
        autodispofn(session_state)
        setcount(1)
      }

    } else if (session_state.background.socket_message.evt === "Drop") {
      sound(false)
      props.dispoValidation(true)

    }
  }, [
    session_state.background.socket_message.evt,
  ])
  useMemo(() => {
    if (session_state.background.oncall === true) {
      if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber) {
        localStorage.setItem("oncalldata", JSON.stringify(session_state.background.oncalllead[0]))
      }

    }



    if (AppPermission.popup) {
      if ((session_state.background.socket_message.evt == 'ANSWERED' && session_state.background.oncall === true)) {

        if (session_state.call.callmodule.auto == true) {
          dispatch(fetchcallaction('on_call'))
        }

      }
      else {
        if ((session_state.background.socket_message.evt == 'RINGING' && session_state.background.oncall === true)) {
          if (session_state.background.socket_message.calltype === "INBOUND" || (session_state.background.socket_message.calltype === "AUTO" || session_state.background.socket_message.calltype === "xfer")
            && session_state.background.socket_message.calltype !== "preview") {
              if (campaigndetailslocalstorage.auto_answer) {
                dispatch(on_call())
              } else {
                dispatch(incoming())
              }
          }
          if (session_state.call.callmodule.module !== "" && session_state.background.socket_message.calltype === "OUTBOUND") {
            if (session_state.call.callmodule.module == "lead") {
              if (session_state.call.callmodule.id === "") {
                if (location.pathname == "/leads/create") {
                  navigate('/leads')
                  setTimeout(() => {
                    navigate('/leads/create')
                  }, 2000);

                } else {
                  navigate('/leads/create')

                }
              } else {
                navigate(`/leads/${Encode(session_state.background.socket_message.phonenumber)}/${session_state.call.callmodule.id}`)
              }

            } else if (session_state.call.callmodule.module == "ticket") {
              if (session_state.call.callmodule.id === "") {
                navigate('/tickets/create')
              } else {
                navigate(`/tickets/${Encode(session_state.call.callmodule.phone_no)}/${session_state.call.callmodule.id}`)
              }
            } else if (session_state.call.callmodule.module == "meeting") {
              if (session_state.call.callmodule.id === "") {
                navigate('/contact/create')
              } else {
                navigate(`/meeting/${Encode(session_state.call.callmodule.phone_no)}/${session_state.call.callmodule.id}`)
              }

            } else if (session_state.call.callmodule.module == "voicemail") {
              navigate(`/voicemail/${Encode(session_state.background.socket_message.phonenumber)}`)
            } else if (session_state.call.callmodule.module == "contact") {
              navigate(`/contact/${Encode(session_state.background.socket_message.phonenumber)}/${session_state.call.callmodule.id}`)
            }
          } else {
            if (session_state.background.socket_message.calltype === "INBOUND" || session_state.background.socket_message.calltype === 'xfer' || session_state.background.socket_message.calltype === "AUTO" || session_state.background.socket_message.calltype === "preview") {
              buttonOnClick()
              let payload = {
                template_name: campaigninfo.template_name,
                phone_number: session_state.background.socket_message.phonenumber
              }
              if (process === "leads" || process === "leads,meetings" || process === "leads,tickets,meetings" || process === "leads,tickets") {
                payload.module = 'leads'
                payload.lead_id = session_state.background.socket_message.lead_id;
                dispatch(PopupRouter(payload)).then((res) => {
                  if (res.data.ids.length > 0) {
                    let id = res.data.ids[0].lead_id
                    navigate(`/leads/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
                  } else {
                    if (location.pathname == "/leads/create") {
                      navigate('/leads')
                      setTimeout(() => {
                        navigate('/leads/create')
                      }, 2000);

                    } else {
                      navigate('/leads/create')

                    }

                  }
                }).catch((err) => {
                  EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'popup router',
                  })
                })
              } else if (process === "tickets" || process === "tickets,meetings") {
                payload.module = 'tickets'
                dispatch(PopupRouter(payload)).then((res) => {
                  if (res.data.ids.length > 0) {
                    let id = res.data.ids[0].ticket_id
                    navigate(`/tickets/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
                  } else {

                    navigate('/tickets/create')

                  }
                }).catch((err) => {
                  EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'popup router',
                  })
                })
              }
              else if (process === "meetings") {
                payload.module = 'meetings'
                dispatch(PopupRouter(payload)).then((res) => {
                  if (res.data.ids.length > 0) {
                    let id = res.data.ids[0].fwp_id
                    navigate(`/meeting/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
                  } else {

                    if (res.data.contact_id.length > 0) {
                      navigate(`/contact/${Encode(session_state.background.socket_message.phonenumber)}/${res.data.contact_id[0].id}`)
                    } else {
                      navigate('/contact/create')
                    }


                  }
                }).catch((err) => {
                  EventEmitter.emit('error-message-getter', {
                    action: 'fetch',
                    module: 'popup router',
                  })
                })
              }
            }
          }

        }
      }

      // if (callValue !== null) {
      // dispatch(oncall(true))
      // }



    } if (session_state.background.socket_message.evt == 'RINGING') {
      setcount(0)
    }

    // if(session_state.background.socket_message.evt == 'ANSWERED' && AppPermission.auto_answer){
    // // dispatch(on_call())
    // props.type = 'on_call'
    // }

  }, [
    session_state.background.oncall,
    session_state.background.socket_message.evt
  ])
  useEffect(() => {
    let navigateValue = localStorage.getItem('beforeNavigatePath')
    if (session_state.background.oncall === false && callStatus == null && navigateValue !== null) {
      dispatch(beforecallnavpath(navigateValue))
      navigate(`${navigateValue}`)
    }
  }, [session_state.background.oncall, callStatus])
  useEffect(() => {

    if (
      session_state.background.socket_message &&
      Object.keys(session_state.background.socket_message).length === 0 &&
      Object.getPrototypeOf(session_state.background.socket_message) ===
      Object.prototype
    ) {
      if (callStatus == null) {
        dispatch(beforecallnavpath(location.pathname))
        localStorage.setItem('beforeNavigatePath', location.pathname)
      }
      localStorage.setItem("callcount", "0")
    }
    if (props.type === '' && session_state.call.callaction !== true) {
      props.callAction('log')
    } else {
      props.callAction(props.type)
    }
    if (`/leads/${Encode(session_state.background.socket_message.phonenumber)}/${session_state.call.callmodule.id}` == location.pathname || `/tickets/${Encode(session_state.call.callmodule.phone_no)}/${session_state.call.callmodule.id}` == location.pathname || `/meeting/${Encode(session_state.call.callmodule.phone_no)}/${session_state.call.callmodule.id}` == location.pathname || `/contact/${Encode(session_state.background.socket_message.phonenumber)}/${session_state.call.callmodule.id}` == location.pathname || `/voicemail/${Encode(session_state.background.socket_message.phonenumber)}` == location.pathname) {
      localStorage.setItem("customformdata", true)
    } else {
      localStorage.setItem("customformdata", false)
    }

  }, [location.pathname])

  useMemo(() => {
    let test = localStorage.getItem("dialpadname")
    if (test != null) {
      setdialernum(localStorage.getItem("dialpadname"))
    }
  }, [localStorage.getItem("dialpadname")])


  function buttonOnClick() {

    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          showNotification();
        }
      });
    }
  };
  const showNotification = () => {
    const notification = new Notification('Call Notification', {
      body: 'You have phonecall from  ' +  fromat(session_state.background.socket_message.phonenumber, { maskbool: true }),
      requireInteraction: true, // Prevent automatic closing
    });

    notification.addEventListener('click', () => {
    });
  };
  function event() {
    EventEmitter.addListener('switchToWhatsapp', (data) => {
      if (data === 'whatsapp') {
        setinstantClick({ channel: 'whatsapp', action: 'inspect' })
        if (wp_chat === false) {
          setwp_chat(true)

        }
      } else if (data === 'website') {
        setinstantClick({ channel: 'website', action: 'inspect' })
        if (wp_chat === false) {
          setwp_chat(true)

        } else if (data === 'facebook') {
          setinstantClick({ channel: 'facebook', action: 'inspect' })
          if (wp_chat === false) {
            setwp_chat(true)

          }
        }
      }

    })
    EventEmitter.addListener("whatsapp-cht", () => {
      setcallChat(2)
      changemessage('wc')
    })





  }
  //   const handleFunctionCall = () => {

  //     console.log("CallScreen function called");
  //   };

  //   // Call the passed function when needed
  // useEffect(() => {
  //     if (props.onCallFunction) {
  //       handleFunctionCall();

  //     }
  //   }, [props.onCallFunction]);
  const returnonCallFunction = (data) => {

    props.returnonCallFunction()


    };

  function handleRemoteStream(stream) {
    console.log("Audio: comming");
    let remoteAudio = new Audio();
    remoteAudio.srcObject = stream;
  
    remoteAudio.play()
      .then(() => {
        console.log("Audio: is playing.");
        // checkAudioLevels(stream); // Check audio levels after playing
      })
      .catch(function (error) {
        console.error("Audio: Something went wrong", error);
      });

    sound(false);
  }

  // function checkAudioLevels(stream) {
  //   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //   const analyser = audioContext.createAnalyser();
  //   const source = audioContext.createMediaStreamSource(stream);
    
  //   source.connect(analyser);
  //   analyser.fftSize = 256;
  
  //   const dataArray = new Uint8Array(analyser.frequencyBinCount);
  
  //   function detectAudio() {
  //     analyser.getByteFrequencyData(dataArray);
  
  //     // Check if there's any audio activity by analyzing the frequency data
  //     const audioLevel = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
  //     if (audioLevel > 10) {
  //       console.log("Audio: is coming through");
  //     } else {
  //       console.log("Audio: No audio detected");
  //     }
  
  //     requestAnimationFrame(detectAudio);
  //   }
  
  //   detectAudio();
  // }

  const validatedispo = () => {
    console.log("iiiiiiiiiiiiiiiiiiiiiii")
    props.passDataToOutlet()
  }
  function socketIo() {
    let apiinfo = JSON.parse(localStorage.getItem('apinfo'))
    let url = `${apiinfo.socket_url}`
    let socket = io(url, {
      reconnection: true,
      query: {
        token: apiinfo.token,
        type: 'doocti',
      },
      forceNew: true,
      allowEIO3: true,
    })

    dispatch(socketconnection(socket))

    socket.on('connect', function (error) {
      let userData = {
        socket_id: socket.id,
        station: station,
        type: 'doocti',
        tenant_id: tenant,
        agent: agent,
        station: station,
        campaign: campaign,
      }
      socket.emit('client-info', userData)
      dispatch(networkstatus("online"))
      dispatch(websocketstatus({ WsSocketStatus: true }))
      console.log("Socket Connected============>");
    })

    socket.on('connect_error', function (error) {
      dispatch(networkstatus("offline"))
      if (session_state.background.oncall) {
        console.log("Socket Connected error ============>", session_state.background.oncall);
        props.dispoValidation(false)
      }

      EventEmitter.emit('error-message-getter', {
        action: 'socket',
        module: 'Newtork Disconnected',
      })




      dispatch(websocketstatus({ WsSocketStatus: false }))
    })
    socket.on('initiate-call-response', function (data) {
      props.refreshProp()
      if (data.error.length === 0) {
        dispatch(oncall(true))
      } else {
        console.log(data, "initiate-call-response-error")
        EventEmitter.emit('error-message-getter', {
          action: 'socket',
          module: "Call Initiated Failed",
        })

      }

    })
    socket.on('failed', function (error) {
      dispatch(websocketstatus({ WsSocketStatus: false }))
    })
    socket.on('client-info-response', function (error) {
      const now = new Date();

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
      const day = String(now.getDate()).padStart(2, '0');

      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      interval = setInterval(() => {
        dispatch(checkagentlive(socket, {
          "event": "ping", "tenant_id": tenant, "agent": agent,
          station: station, "time": formattedDateTime
        }
        ))
      }, 5000)

      // clearInterval(interval)
    })
    socket.on('disconnect', function (error) {
      dispatch(websocketstatus({ WsSocketStatus: false }))
    })
    socket.on('message', function (data) {
      dispatch(websocketstatus({ WsSocketStatus: true }))
      let calling_type
      let subject
      if (data.evt !== "DISPO") {
        dispatch(oncall_number(data.uniqueid))
      }
      if (data.evt == "DISPO" && localStorage.getItem('processing_call') === 'force') {
          let array = JSON.parse(localStorage.getItem('logoutData'))
          dispatch(forcelogout(socket, array))
      }
      if (data.call_type === 'MANUAL') {
        if (data.evt !== "DISPO") {
          dispatch(oncall(true))
        }

        subject = `Call from ${station} to ${data.phonenumber}`
        calling_type = 'OUTBOUND'
      } else if (data.call_type === 'INBOUND' || data.call_type === 'POWER' || data.call_type === 'VB') {
        if (data.evt !== "DISPO") {
          dispatch(oncall(true))
        }
        if (data.call_type === 'POWER' || data.call_type === 'VB') {
          data.call_type = 'INBOUND'
        }
        subject = `Call from ${data.phonenumber} to ${station}`
        calling_type = 'INBOUND'
      }
      else if (data.call_type === 'xfer') {
        //setIncom_popup(true)
        dispatch(oncall(true))
        subject = `Call from ${data.phonenumber} to ${station}`

        calling_type = 'INBOUND'
      }
      else {
        if (data.evt !== 'Drop') {
          dispatch(oncall(true))
          subject = `Call from ${station} to ${data.phonenumber}`
          calling_type = 'OUTBOUND'
        }

        if (data.evt == 'Drop') {
          sound(false)
          dispatch(oncall(false))
        }
      }
      data['calling_type'] = calling_type
      data['subject'] = subject

      if (data.evt == 'Drop') {
        sound(false)
        dispatch(oncall(false))
        console.log(session_state.call, "session_state.call")

        navigate(`${session_state.call.beforecallNavigatePath}`)
        localStorage.removeItem('callstatus')
        props.dispoValidation(true)
        props.callAction('log')
        dispatch(socketmessage({ evt: "empty" }))
      }
      else if (JSON.parse(localStorage.getItem('callstatus')) === null && data.evt === "RINGING") {
        settransferresponse([])

        dispatch(socketmessage(data))
      } else {
        if (data.evt === "ANSWERED") {
          dispatch(socketmessage(data))
        }
        else if (JSON.parse(localStorage.getItem('callstatus')).evt === "RINGING" && (data.evt === "ANSWERED")) {
          dispatch(socketmessage(data))
        } else if ((JSON.parse(localStorage.getItem('callstatus')).evt === "RINGING" || JSON.parse(localStorage.getItem('callstatus')).evt === "ANSWERED") && (data.evt === "DISPO")) {
          dispatch(socketmessage(data))
        } else {
          dispatch(oncall(false))
        }
      }
    })
    socket.on('transfer-response', function (data) {

      settransferresponse([data])
      setTransferStatusChange(true)
    })
    socket.on('Xfer_Failed', function (data) {
      setchecktransfer(true)
      settransferresponse([])
      setTransferStatusChange(false)
      EventEmitter.emit('error-message-getter', {
        action: 'Xfer_Failed',
        module: 'transferCall',
      })

    })
    socket.on('xfer_success', function (data) {
      settransferresponse([])
      setTransferStatusChange(false)
      if (data.call_type === 'MANUAL') {
        data.subject = `Call from ${station} to ${data.phone_number}`
        data.calling_type = 'OUTBOUND'
      } else if (data.call_type === 'INBOUND') {
        data.subject = `Call from ${data.phone_number} to ${station}`
        data.calling_type = 'INBOUND'
      } else {
        data.call_type = 'AUTO'
      }
      data.station = station
      data.evt = "DISPO"
      data.phonenumber = data.phone_number
      dispatch(socketmessage(data))
      EventEmitter.emit('error-message-getter', {
        action: 'xfer_success',
        module: 'transferCall',
      })
    })
    socket.on('Xfer_Answered', function (data) {
      setTransferStatusChange(false)
    })
    socket.on('conference-response', function (data) {
    })
    socket.on('Joinconf', function (data) {
      let payload = {
        activity: 'push',
        data: data,
      }
      dispatch(inConference(payload))
      dispatch(activeConference(true))
    })
    socket.on('Leaveconf', function (data) {
      let payload = {
        activity: 'pop',
        data: data,
      }
      dispatch(inConference(payload))
    })

    socket.on('conf-mute-response', function (data) {
      setmuteconf(data)
    })
    socket.on('conf-unmute-response', function (data) {
      setmuteconf(data)
    })
    socket.on('conf-exit-response', function (data) {
    })
    socket.on('conf-end-response', function (data) {
    })
    socket.on('xfer-cancel-response', function (data) {
    })
    socket.on('forceloggedout', function (data) {
      let arr = [
        {
          action: "CTILogout",
          agent: data.user,
          campaign: data.campaign,
          station: data.station,
          tenant_id: data.tenant_id
        }
        , {
          action: "user-logout",
          agent: data.user,
          station: data.station,
          tenant_id: data.tenant_id
        },
        {
          action: "QueueLogout",
          agent: data.user,
          campaign: data.campaign,
          current_queue: [],
          queue: JSON.parse(localStorage.getItem('selectedQueue')),
          station: data.station,
          tenant_id: data.tenant_id,
          noqueuelogin: true
        }
      ]
      localStorage.setItem('logoutData', JSON.stringify(arr))
    if (!OngoingCall.current.background.oncall && localStorage.getItem('wasession') !== 'true') {
        console.log(session_state.background.oncall, "session_state.background.oncallsession_state.background.oncall")

        dispatch(forcelogout(socket, arr))

      } else {
        localStorage.setItem('processing_call', 'force')
      }
    })

    socket.on('queue-login-response', function (data) {
      EventEmitter.emit('queue-login-response', data)
    })
    socket.on('queue-logout-response', function (data) {
      EventEmitter.emit('queue-logout-response', data)
    })
    socket.on('user-logout-response', function (data) {
      if (data.noqueuelogin === true) {
        dispatch(logout({ user: localStorage.getItem('user') }))
          .then(function () {
            localStorage.clear();
            localStorage.setItem("revertload",'true')
            navigate("/")
          })
          .catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'logout',
              module: 'logout',
            })
          })
      }

    })

    socket.on('queue-pause-response', function (data) {
    })
    socket.on('queue-waiting-response', function (data) {
      setwaitingresponse([].concat(data.rows))
    })

    socket.on('new-message', function (data) {
      EventEmitter.emit('new-Wpmessage', data)
      setuserMessage(data)
    })

    socket.on('new-con-msg', function (data) {
      EventEmitter.emit('new-con-whmsg', data)
      setuserMessage(data)
    })

    socket.on('publish-message-response', function (data) {
      EventEmitter.emit('agent-message-response', data)
      let action = data.status === 'failed' ? `template_${data.status}`: `template_${data.status}`;
      if (data.action === "send-wa-template") {
        EventEmitter.emit('error-message-getter', {
          action,
          module: "",
        })
      }
    })

    socket.on('accept-new-chat-response', function (data) {
      if (data !== "" && data != undefined) {
        dispatch(oncall(true))
        localStorage.setItem("wasession", "true")
        EventEmitter.emit('wasession-response', "true")
        EventEmitter.emit('agent-message-response', data)
        EventEmitter.emit('agent-message-response-notification', data)
        getlocalstoredata(agent)
        setinstantClick(data)
        setwp_chat(false)
        changemessage('wc')
        setcallChat(2)
      }
    })

    socket.on('wa-session-end', function (data) {
      chatexit(data.MSG[0])
      localStorage.setItem("wa-session-end", JSON.stringify(data.MSG[0]))

    })
    socket.on('record-mute-response', function (data) {
      let res = data
      setcallRecordingResponse([res])

    })
    socket.on('wa-chat-drop', function (data) {
      EventEmitter.emit('wa-chat-drop-response', data)
    })
    socket.on('hangup-response', function (data) {
      localStorage.setItem("callcontroll", JSON.stringify({
        mute: false,
        hold: false,
        transfer: false,
        conf: false,
        dialpad: false
      }))
      // if (localStorage.getItem('processing_call') === 'force') {
      //   let array = JSON.parse(localStorage.getItem('logoutData'))
      //   dispatch(forcelogout(socket, array))
      // }
      EventEmitter.emit('trigger-wrapup', {socket,autodial:localStorage.getItem("autodial")})
      // navigate(`${session_state.call.beforecallNavigatePath}`)

    })
    socket.on('wa-session-hangup-response', function (data) {

      let payload = {
        subject: "whatsapp conversation",
        callType: "INBOUND",
        campaign: campaign,
        leadModule: '',
        leadID: `${data.sender_id}`,
        module: data.module,
        ownerID: agent,
        ownerName: user,
        callStartTime: data.start_time,
        phoneNumber: data.phone_number,
        duration: timeDifference(data.end_time, data.start_time),
        lead_name: data.sender_name,
        callResult: { name: data.dispo_status.name, value: data.dispo_status.name },
        callDescription: '',
        callEndTime: data.end_time,
        uniqueID: data.session_id,
        SubcallResult: { name: data.sub_dispo.name, value: data.sub_dispo.name },
        channel: data.channel,
        channel_id: data.session_id,
      }

      dispatch(CallActivity(payload)).then((response) => {
        if (response.status === 201) {
          EventEmitter.emit('trigger-wrapup', {socket,autodial:localStorage.getItem("autodial")})
          let leadData = {
            lead_id: "",
            lead_name: "",
            phone_number: "",
            render: 0,
            module: "",
          }

          localStorage.removeItem("wa-session-end")
          dispatch(callLeadDetail(leadData))
          setcallChat(1)
          getlocalstoredata(agent)
          EventEmitter.emit('wasession-response', "false")
          changemessage("wc")
          sethangupdata(1)
          localStorage.setItem("wasession", "false")
          localStorage.removeItem("waformdata")
          localStorage.removeItem("oncalldata")
          localStorage.removeItem("callstatus")
          localStorage.removeItem("showdispo")
          closechatdispo(true)




        }
      })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'create',
            module: "whatsapp activity",
          })
        })

    })
    socket.on('notification', function (data) {
      EventEmitter.emit('agent-notification', data)
    })

    setsocketFunction(socket)
  }
 

  function timeDifference(date1, date2) {
    let value1 = new Date(date1)
    let value2 = new Date(date2)
    var difference = value1.getTime() - value2.getTime();



    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(difference / 1000);

    if (hoursDifference < 9) {
      var hoursDifference = "0" + hoursDifference
    } if (minutesDifference < 9) {
      var minutesDifference = "0" + minutesDifference
    } if (secondsDifference < 9) {
      var secondsDifference = "0" + secondsDifference
    }

    return (

      hoursDifference + ':' +
      minutesDifference + ':' +
      secondsDifference)



  }


  function background() {
    let { webrtc_domain, webrtc_ip } = config.data.projects[0].config[0]
    let webrtcSocket = new JsSIP.WebSocketInterface(
      `wss://${webrtc_domain}/ws`,
    )
    var configuration = {
      sockets: [webrtcSocket],
      display_name: station,
      authorization_user: station,
      uri: `sip:${station}@${webrtc_ip}`,
      password: station,
      realm: webrtc_ip,
      DtlsSrtpKeyAgreement: false,
      contact_uri: `sip:${station}@${webrtc_ip}`,
      connection_recovery_min_interval: 5,
      connection_recovery_max_interval: 20,
      use_preloaded_route: true,
      session_timers: false,
      no_answer_timeout: 60000,
      register_expires: 30000000,
      ws_servers: `wss://${webrtc_ip}:5060`,
      trace_sip: true,
    }
    var coolPhone = new JsSIP.UA(configuration)
    coolPhone.start()
    coolPhone.register()
    coolPhone.on('registered', function (e) {

      dispatch(websocketstatus({ WrtcStatus: true }))
    })
    coolPhone.on('unregistered', function (e) {

      dispatch(websocketstatus({ WrtcStatus: false }))
    })
    coolPhone.on('registrationFailed', function (e) {
      dispatch(websocketstatus({ WrtcStatus: false }))
    })
    coolPhone.on('connected', function (e) {
      dispatch(websocketstatus({ WrtcStatus: true }))
      let socketConnectCount = JSON.parse(
        localStorage.getItem('socketConnectCount'),
      )
      if (socketConnectCount >= 1) {
        let wraptime = localStorage.getItem("wraptime")
        // if (wraptime == 0 || wraptime === null || wraptime === undefined) {
          setpopupdata((set) => {
            set.open = true
            return {
              ...set,
            }
          })
        // }

      }
      localStorage.setItem('socketConnectCount', socketConnectCount + 1)
    })
    coolPhone.on('disconnected', function (e) {

      dispatch(websocketstatus({ WrtcStatus: false }))
    })
    coolPhone.on('newRTCSession', function (data) {
      dispatch(websocketstatus({ WrtcStatus: true }))
      dispatch(session(data.session))
      let session_ = data.session

      if (session_.direction === 'incoming') {     
      
        let webrtccallstatus = localStorage.getItem('webrtcManual')
        if (
          webrtccallstatus === 'true'
        ) {
          dispatch(on_call())
          session_.on('peerconnection', function (data) {
              console.log("Audio: manual call");
              let peerConnection = data.peerconnection;
              if (peerConnection.getReceivers) {
                console.log("Audio: manual call");
                peerConnection.addEventListener("track", function (e) {
                  handleRemoteStream(e.streams[0]);
                });
              } else {
                console.log("Audio: manual call");
                peerConnection.addEventListener("addstream", function (e) {
                  handleRemoteStream(e.stream);
                });
              }
            })
          setTimeout(function () {
             session_.answer(sipOptions)
          }, 500)
        } else {
          if (campaigndetailslocalstorage.auto_answer) {
            dispatch(on_call())
            session_.on('peerconnection', function (data) {
              let peerConnection = data.peerconnection;
              if (peerConnection.getReceivers) {
                peerConnection.addEventListener("track", function (e) {
                  handleRemoteStream(e.streams[0]);
                });
              } else {
                peerConnection.addEventListener("addstream", function (e) {
                  handleRemoteStream(e.stream);
                });
              }
            })
            setTimeout(function () {
              session_.answer(sipOptions)
            }, 500)
          } else {


            session_.on('peerconnection', function (data) {
              let peerConnection = data.peerconnection;
              if (peerConnection.getReceivers) {
                peerConnection.addEventListener("track", function (e) {
                  handleRemoteStream(e.streams[0]);
                });
              } else {
                peerConnection.addEventListener("addstream", function (e) {
                  handleRemoteStream(e.stream);
                });
              }
            })
          }
        }
      }
      session_.on('failed', function (e) {
        sound(false)

      })
      session_.on('ended', function (e) {
        localStorage.setItem('webrtcManual', 'false')

        sound(false)

      })
      session_.on('started', function (e) {
      })

      session_.on('sending', function (e) {
      })
      session_.on('accepted', function (e) {
        sound(false)
      })
      session_.on('confirmed', function (e) {
        sound(false)
      })
      session_.on('connecting', function (e) {
      })
      session_.on('progress', function (e) {
      })
    })
  }

  const handleClick = (data) => {
    console.log("=============================================>", "start handleClick function", "testlogs")
    if (data) {
      console.log("=============================================>", "answer if condition", "testlogs")
      webRtcConnection('answer')
      console.log("=============================================>", "answer if condition2", "testlogs")
    } else {
      console.log("=============================================>", "end if condition", "testlogs")
      webRtcConnection('end')
      console.log("=============================================>", "end if condition2", "testlogs")
    }
  }
  const sound_ = (data) => {
    console.log("=============================================>", "end if sound_", "sound_")
    sound(true)
  }
  const outboundnumber = (data) => {
    dispatch(Outbound(data.state, data.value, undefined, data.isabandon))
  }
  const call_Action = (data) => {
    if (data === 'mute') {
      webRtcConnection('mute')
    } else if (data === 'unmute') {
      webRtcConnection('unmute')
    } else if (data === 'hold') {
      webRtcConnection('hold')
    } else if (data === 'unhold') {
      webRtcConnection('unhold')
    }
  }

  function changemessage(val) {
    if (val === 'fb') {
      setfb_chat(!fb_chat)
      setwp_chat(false)
    } else {
      setfb_chat(false)
      if (wp_chat === true) {
        localStorage.setItem('chatactive', 'false')
      } else {
        localStorage.setItem('chatactive', 'true')
      }

      setwp_chat(!wp_chat)
    }
  }
  function back2form() {
    let payload = {
      template_name: campaigninfo.template_name,
      phone_number: session_state.background.socket_message.phonenumber
    }
    if (process === "leads" || process === "leads,meetings" || process === "leads,tickets,meetings") {
      payload.module = 'leads'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].lead_id
          navigate(`/leads/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
        } else {
          navigate('/leads/create')

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    } else if (process === "tickets" || process === "tickets,meetings") {
      payload.module = 'tickets'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].ticket_id
          navigate(`/tickets/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
        } else {

          navigate('/tickets/create')

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }
    else if (process === "meetings") {
      payload.module = 'meetings'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].fwp_id
          navigate(`/meeting/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
        } else {

          if (res.data.contact_id.length > 0) {
            navigate(`/contact/${Encode(session_state.background.socket_message.phonenumber)}/${res.data.contact_id[0].id}`)
          } else {
            navigate('/contact/create')
          }


        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }


  }

  function sound(val) {
    if (val) {
      console.log(val, session_state.background, "Test1")
      if (session_state.background.ringing === false) {
        dispatch(callringtone(val))
      }
    } else {
      dispatch(callringtone(val))
    }


  }
  function autodispotrigger() {
    autodispofn(session_state)
  }
  function webRtcConnection(val) {

    let session_data = session_state.background.session

    if (val === 'answer') {
      session_data.answer(sipOptions)
    } else if (val === 'end') {
      if (AppPermission.webrtc === false) {
        autodispotrigger()
      } else {
        try {
          session_data.terminate()
          if (session_state.background.socket_message.calltype === 'xfer') {
            sound(false)
            dispatch(oncall(false))
            localStorage.removeItem('callstatus')
            props.dispoValidation(true)
            props.callAction('log')
            dispatch(socketmessage({ evt: "empty" }))
            const data = {
              action: 'QueuePause',
              agent: localStorage.getItem('user'),
              campaign: campaign,
              paused: false,
              station: station,
              queue: selected_queue,
              tenant_id: tenant,
            }
            dispatch(queuePause(session_state.background.websocketconnection, data))

          }
        } catch (err) {
          if (session_state.background.socket_message.uniqueid === session_state.background.oncall_phonenumber) {
            props.dispoValidation(false)
          }
        }
      }


    } else if (val === 'mute') {
      session_data.mute({
        audio: true,
      })
    } else if (val === 'unmute') {
      session_data.unmute({
        audio: true,
      })
    } else if (val === 'hold') {
      session_data.hold({
        audio: true,
      })
    } else if (val === 'unhold') {
      session_data.unhold({
        audio: true,
      })
    }
  }

  function callend(data, seconds) {
    if (!AppPermission.webrtc) {
      dispatch(terminatecall(session_state.background.websocketconnection, {
        "action": "terminate",
        "station": station,
        "tenant_id": tenant,
        "agent": agent,
        "phone_number": session_state.background.socket_message.phonenumber,
        "uniqueid": session_state.background.socket_message.uniqueid
      }))
    }
    else if (data) {
      webRtcConnection('end')

    }



  }

  function autodispofn(stateValue) {
    let { hangup_seconds, default_dispo } = config.data.projects[0].config[0]
    let selectedDispo = default_dispo
    let selectedSubDispo = default_dispo
    if (campaigndetailslocalstorage.auto_dispo) {
      // if (location.pathname === `/leads/${Encode(session_state.background.socket_message.phonenumber)}/${session_state.call.callmodule.id}`) {
      dispatch(triggerdispostate(true))
      // }
      let callEndTime

      if (session_state.background.socket_message.endtime === '') {
        var date = moment()
        var currentDate = date.format('YYYY-MM-D')
        var CurrentDateValue = new Date() // for now
        var time = `${CurrentDateValue.getHours()}:${CurrentDateValue.getMinutes()}:${CurrentDateValue.getSeconds()}`
        callEndTime = `${currentDate} ${time}`
      } else {
        callEndTime = session_state.background.socket_message.endtime
      }

      let duration
      let hours = Math.floor(seconds / 3600 % 60)
      let minites = Math.floor(seconds / 60 % 60)
      let sec = Math.floor(seconds / 1 % 60)
      if (hours < 10) hours = '0' + hours
      if (minites < 10) minites = '0' + minites
      if (sec < 10) sec = '0' + sec
      let obj = { evt: "endcall", duration: `${hours}:${minites}:${sec}` }
      dispatch(socketmessage(obj))
      duration = `${hours}:${minites}:${sec}`


      let lead = ''
      if (stateValue.customform.formdata.length > 0) {
        lead = stateValue.customform.formdata[0].lead_id
      }
      let payload = {
        subject: stateValue.background.socket_message.subject,
        callType: stateValue.background.socket_message.calltype,
        campaign: campaign,
        leadModule: '',
        ownerID: agent,
        ownerName: user,
        callStartTime: stateValue.background.socket_message.ringing_time,
        leadID: `${session_state.background.oncalllead[0].lead_id}`,
        module: session_state.background.oncalllead[0].module,
        phoneNumber: stateValue.background.socket_message.phonenumber,
        duration: duration,
        lead_name: localStorage.getItem("dialpadname"),
        callResult: { name: selectedDispo, value: selectedDispo },
        callDescription: '',
        callEndTime: callEndTime,
        uniqueID: stateValue.background.socket_message.uniqueid,
        SubcallResult: { name: selectedSubDispo, value: selectedSubDispo },
        channel: 'call',
        channel_id: stateValue.background.socket_message.uniqueid,
      }
      dispatch(CallActivity(payload)).then((response) => {
        if (response.status === 201) {
          closedispo(true)
          let leadData = {
            lead_id: "",
            lead_name: "",
            phone_number: "",
            module: "",
            render: 0
          }
          dispatch(callLeadDetail(leadData))
          let dispo = [
            { name: selectedDispo, value: selectedDispo },
            { name: selectedSubDispo, value: selectedSubDispo },
          ]
          dispodata([stateValue.background.websocketconnection, dispo])
          localStorage.removeItem("waformdata")
          localStorage.removeItem("oncalldata")
          localStorage.removeItem("callstatus")
          localStorage.removeItem("showdispo")

        }
      })
        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'create',
            module: 'activity',
          })
        })


    }
  }

  function closedispo(data) {
    dispatch(on_log())
    props.dispoValidation(data)
  }

  function dispodata(data) {
    dispatch(callmodule({
      id: "",
      module: "",
      phone_no: ""
    }))
    let status
    if (session_state.background.socket_message.stage2 === '') {
      status = session_state.background.socket_message.stage1
    } else {
      status = session_state.background.socket_message.stage2
    }
    let name = session_state.background.oncalllead[0].lead_name
    let id = session_state.background.oncalllead[0].lead_id
    let module = session_state.background.oncalllead[0].module
    if ((id != "" && id != undefined) && (module != undefined && module != "" && module == "lead")) {
      let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
      // const payload = {
      //   template_name: campaigninfo.template_name,
      //   id: id,
      //   phone_number: session_state.background.socket_message.phonenumber
      // }
      // dispatch(CustomformData(payload)).then((res) => {
      //   if (res.status != 204) {
      //     id = res.data.data[0].others.lead_id
      //     name = res.data.data[0].others.first_name
      //     module = 'lead'
      //   }
        let dispoData = {
          action: 'Hangup',
          agent: agent,
          agent_channel: session_state.background.socket_message.agentChannel,
          caller_channel: session_state.background.socket_message.userChannel,
          campaign: campaign,
          dispo_status: data[1][0],
          subdispo_status: data[1][1],
          extension: station,
          leadID: id,
          lead_id: id,
          phone_number: session_state.background.socket_message.phonenumber,
          lead_name: name,
          station: station,
          status: status,
          tenant_id: tenant,
          module: module,
          uniqueid: session_state.background.socket_message.uniqueid,
        }
        dispatch(socketmessage({ evt: "empty" }))
        dispatch(dispo(data[0], dispoData))
        // dispatch(oncall(false))
        setchecktransfer(true)
        dispatch(activeConference(false))
        dispatch(
          inConference({
            activity: 'remove',
          }),
        )
        setcallRecordingResponse([])
      // })


    } else {
      dispatch(
        ContactFetch(
          `/contact/fetchsearch?number=${session_state.background.socket_message.phonenumber}`,
        ),
      ).then((res) => {
        const s = res?.data?.data
        if (s?.length > 0) {
          name = s[0].name
          id = s[0].id
          module = 'contact'
          let dispoData = {
            action: 'Hangup',
            agent: agent,
            agent_channel: session_state.background.socket_message.agentChannel,
            caller_channel: session_state.background.socket_message.userChannel,
            campaign: campaign,
            dispo_status: data[1][0],
            subdispo_status: data[1][1],
            extension: station,
            leadID: id,
            lead_id: id,
            phone_number: session_state.background.socket_message.phonenumber,
            lead_name: name,
            station: station,
            status: status,
            tenant_id: tenant,
            module: module,
            uniqueid: session_state.background.socket_message.uniqueid,
          }
          dispatch(socketmessage({ evt: "empty" }))
          dispatch(dispo(data[0], dispoData))
          dispatch(oncall(false))
          setchecktransfer(true)
          dispatch(activeConference(false))
          dispatch(
            inConference({
              activity: 'remove',
            }),
          )
          setcallRecordingResponse([])
        }
        else {
          let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
          let payload = {
            template_name: campaigninfo.template_name,
            type: campaigninfo.type,
            phone_number: session_state.background.socket_message.phonenumber,
            id: ''
          }
          dispatch(CustomformData(payload)).then((res) => {
            if (res.status != 204) {
              id = res.data.data[0].others.lead_id
              name = res.data.data[0].others.first_name
              module = 'lead'

            }
            let dispoData = {
              action: 'Hangup',
              agent: agent,
              agent_channel: session_state.background.socket_message.agentChannel,
              caller_channel: session_state.background.socket_message.userChannel,
              campaign: campaign,
              dispo_status: data[1][0],
              subdispo_status: data[1][1],
              extension: station,
              leadID: id,
              lead_id: id,
              phone_number: session_state.background.socket_message.phonenumber,
              lead_name: name,
              station: station,
              status: status,
              tenant_id: tenant,
              module: module,
              uniqueid: session_state.background.socket_message.uniqueid,
            }
            dispatch(socketmessage({ evt: "empty" }))
            dispatch(dispo(data[0], dispoData))
            dispatch(oncall(false))
            setchecktransfer(true)
            dispatch(activeConference(false))
            dispatch(
              inConference({
                activity: 'remove',
              }),
            )
            setcallRecordingResponse([])

          })
        }
      })
    }



  }

  function forwardkickout() {
    let data = {
      action: 'xfer-cancel',
      station: station,
      agent: agent,
      tenant_id: tenant,
      uniqueid: session_state.background.socket_message.uniqueid,
    }
    dispatch(
      callForwardKick(session_state.background.websocketconnection, data),
    )

  }

  function transferScocket(data) {
    dispatch(callForward(session_state.background.websocketconnection, data))
    setchecktransfer(false)
  }

  function conferenceScocket(data) {
    setconf_isclicked(true)
    setTimeout(() => {
      setconf_isclicked(false);
    }, 10000); // 10 seconds in milliseconds
  
    if (session_state.background.activeconference) {
      data['action'] = 'joinconference'

      dispatch(
        addconference(session_state.background.websocketconnection, data),
      )
    } else {
      dispatch(
        addconference(session_state.background.websocketconnection, data),
      )
    }
  }

  function conferenceScocketKick(data) {
    dispatch(
      conferenceKickout(session_state.background.websocketconnection, data),
    )

  }

  function conferenceScocketEnd(data) {
    data['agent'] = agent
    dispatch(conferenceEnd(session_state.background.websocketconnection, data))

  }

  function conferenceScocketMute(data) {
    dispatch(conferenceMute(session_state.background.websocketconnection, data))

  }



  function getlocalstoredata(data) {
    dispatch(WaLive({ agent: data }))
      .then(function (response) {
        if (response.status === 200) {
          setwhatsappsession(response.data.data)
        } else {
          localStorage.setItem("wasession", "false")
          setwhatsappsession([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'whatsapp live session',
        })
      })

  }

  function callgrab(data) {
    let payload = {
      tenant_id: tenant,
      agent: agent,
      station: station,
      uniqueid: data.id,
      queue: data.qu,
      action: 'call-grab',
    }

    dispatch(callGrab(session_state.background.websocketconnection, payload))
  }
  function cutmusic_() {
    sound(false)
  }
  const closepopup = (data) => {
    setpopupdata((set) => {
      set.open = false
      return {
        ...set,
      }
    })
  }

  function chatexit(data) {
    setexitchat(data)
    // if (data.sender_type !== 0) {
    // let localdata = JSON.parse(localStorage.getItem("waformdata"))
    // localdata.lead_name = data.sender_name === null ? "" : data.sender_name
    // localdata.lead_id = data.lead_id === null ? "" : data.lead_id
    // localStorage.setItem("waformdata", JSON.stringify(localdata))
    // }



    props.chatdispoValidation(false)
    props.dispoValidation(false)
  }
  function closechatdispo(data) {
    props.chatdispoValidation(true)
    props.dispoValidation(true)
  }
  function chatdispodata(data) {
    let dispo_status = data[1][0]
    let subdispo_status = data[1][1]
    let socketdata = {
      'channel': exitchat.channel,
      "event": "wa-session-hangup",
      "session_id": exitchat.session_id,
      "station": exitchat.station,
      "agent": exitchat.agent,
      "module": exitchat.sender_type === 1 ? "contact" : exitchat.sender_type === 2 ? "lead" : "",
      "sender_type": exitchat.sender_type,
      "sender_name": exitchat.sender_name,
      "sender_id": exitchat.lead_id,
      "phone_number": exitchat.phone_number,
      "dispo_status": {
        name: dispo_status,
        value: dispo_status
      },
      "sub_dispo":
      {
        name: subdispo_status,
        value: subdispo_status
      },
      "tenant_id": tenant
    }
    dispatch(wasessionhangup(session_state.background.websocketconnection, socketdata))

  }

  function redirecttochat(data) {
    setinstantClick(data)
    setwp_chat(false)
    changemessage('wc')
    setcallChat(2)
  }

  function chatclose(data) {
    setwp_chat(data)
    sethangupdata(0)
  }
  function call_recording(record) {

    let data = {
      "action": "record-mute",
      "state": record,
      "agent": agent,
      "station": station,
      "tenant_id": tenant,
      "uniqueid": callValue.uniqueid,
    }

    dispatch(callRecording(session_state.background.websocketconnection, data))
  }
  function handleChangedata(val) {
    setmanualdid(val)
    localStorage.setItem('manualdid', val.value)
  }
  return (
    <div className="callscreen_main">

      <div className="callscreen-flx-top">
        {session_state.call.dispoValidation && (
          <>
            {(props.type === 'on_log') && (

              <Calllog list={list}
                outboundnumber={outboundnumber}
                dids={dids}
                manualdid={manualdid}
                handleChangedata={handleChangedata}
              />
            )}
            {props.type === 'incoming' && (
              <Incoming sound_={sound_} cutmusic={cutmusic_} call={handleClick} back2form={back2form} />
            )}{' '}
            {props.type === 'on_call' && (

              <Oncall

                confend={conferenceScocketEnd}
                muteconf={muteconf}
                back2form={back2form}
                forwardkickout={forwardkickout}
                transferresponse={transferresponse}
                conferencemute={conferenceScocketMute}
                conferencekick={conferenceScocketKick}
                conf_isclicked={conf_isclicked}
                conferenceScocket={conferenceScocket}
                checktransfer={checktransfer}
                transferScocket={transferScocket}
                callend={callend}
                transferStatuschange={transferStatuschange}
                Oncall_call_Action={call_Action}
                callRecord={call_recording}
                call_record_res={callRecordingResponse}
              />
            )}
            {props.type === 'on_dial' && (
              <CallDialPad
                calllogdialpad={true}
                loaddialpad={true}
              ></CallDialPad>
            )}
          </>
        )}
        {session_state.call.dispoValidation === false && campaigndetailslocalstorage.auto_dispo == false && session_state.call.chatdispoValidation == true && (
          <div className="callscreen-flx-top-main-div">
            <CallDispo
              returnonCallFunction={returnonCallFunction}
              onCallFunction={props.onCallFunction}
              validatedispo={validatedispo}
              campaign={campaign}
              user={user}
              closedispo={closedispo}
              dispodata={dispodata}
              type={"call"}
            ></CallDispo>
          </div>
        )}
        {session_state.call.dispoValidation === false && session_state.call.chatdispoValidation == false && (
          <div className="callscreen-flx-top-main-div">
            <CallDispo
              validatedispo={validatedispo}
              returnonCallFunction={returnonCallFunction}
              onCallFunction={props.onCallFunction}
              type={"chat"}
              closedispo={closechatdispo}
              dispodata={chatdispodata}
            ></CallDispo>
          </div>
        )}

      </div>
      <div className="callscreen-flx-bottom custom-cursor">

        <>
          <>
            {wp_chat && (
              <Wpchat
                reset={() => setinstantClick('')}
                instantClick={instantClick}
                userMessage={userMessage}
                whatsappsession={whatsappsession}
                ClinkMe={() => chatclose(false)}
                hangupdata={hangupdata}
              />
            )}
          </>{' '}
        </>
        {localStorage.getItem('selectedPause') === 'available' &&
          <>
            <div className="call_chat">
              {AppPermission.waiting_calls == true && wasession === "false" &&

                <span

                  onClick={() => {
                    setcallChat(1)
                  }}
                  className={callChat === 1 ? 'active' : ''}
                >
                  Waiting Calls
                </span>
              }
              {wasession === "true" && (
                <span
                  onClick={() => {

                    setcallChat(2)
                  }}
                  className={callChat === 2 ? 'active' : ''}
                >
                  Chats
                </span>
              )}

            </div>

            <div className='call_chat_body'>
              {AppPermission.waiting_calls == true && wasession === "false" &&
                <div className="scrollable-queue-list">
                  {waitingresponse.length > 0 && <>
                    {waitingresponse.map((item, i) => {
                      return (
                        <div key={i} className="call_back_border line" >
                          <p>Queue: {Format(item.qu)}</p>
                          <div >
                            {dispoValidation && AppPermission.waiting_call_grab && (
                              <span
                                onClick={() => {
                                  callgrab(item);
                                }}
                                className={
                                  session_state.background.oncall == true ||
                                    session_state.call.autodialstatus == true
                                    ? "material-icons complete_call disabledbutton"
                                    : "material-icons complete_call"
                                }
                              >
                                ads_click
                              </span>
                            )}
                          </div>
                          <p>Caller: {Format(item.pn, { maskbool: true })}</p>
                          <p>Duration: {item.du}</p>
                        </div>
                      );

                    })}
                  </>}

                  {waitingresponse.length === 0 && <>
                    <div className='nodata'>No Data</div>
                  </>}

                </div>
              }
              {AppPermission.chat_view && wasession === "true" && whatsappsession.length > 0 &&
                <>
                  {AppPermission.wa_view && whatsappsession[0].channel === "whatsapp" &&
                    <>
                      <div className="chat-box wp" >
                        <img src={wp} alt="test" />
                        <h3 className="chat_h">Whatsapp</h3>
                      </div>

                      <div className="chat_panel">
                        <div className="chat-box-session">
                          <div className="chat-body-title">Whatsapp Session</div>

                          {whatsappsession.length > 0 && (
                            <>
                              {whatsappsession.map((value, i) => {
                                return (
                                  <>
                                    <div key={i} className="chat-body ">
                                      <div onClick={() => { redirecttochat(value) }} className='chat-body-lft'>
                                        <div className="chat-body-sub-title">
                                          {fromat(value.sender_name, { maskbool: true })}
                                        </div>
                                        <span className="chat-body-sub-title">
                                          {fromat(value.phone_number, { maskbool: true })}
                                        </span>
                                        <span>Active Now</span>
                                      </div>
                                      <div className='chat-body-rht' >
                                        <span className="material-icons " onClick={() => { chatexit(value) }} style={{ color: "#E43554" }}>
                                          logout
                                        </span>
                                      </div>

                                    </div>
                                  </>
                                )
                              })}

                            </>
                          )}
                          {whatsappsession.length === 0 && (
                            <>
                              <div className="chat-body">
                                <span className="chat-body-sub-title">
                                  No Active Whatsapp Session
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  }
                  {AppPermission.webchat_view && whatsappsession[0].channel === "website" &&
                    <>
                      <div className="chat-box webchat" >
                        <img className='web_chatimg' src={webchat} alt="test" />
                        <h3 className="chat_h">WebChat</h3>
                      </div>

                      <div className="chat_panel">
                        <div className="chat-box-session">
                          <div className="chat-body-title">WebChat Session</div>

                          {whatsappsession.length > 0 && (
                            <>
                              {whatsappsession.map((value, i) => {
                                return (
                                  <>
                                    <div key={i} className="chat-body ">
                                      <div onClick={() => { redirecttochat(value) }} className='chat-body-lft'>
                                        <div className="chat-body-sub-title">
                                          {fromat(value.sender_name, { maskbool: true })}
                                        </div>
                                        <span className="chat-body-sub-title">
                                          {fromat(value.phone_number, { maskbool: true })}
                                        </span>
                                        <span>Active Now</span>
                                      </div>
                                      <div className='chat-body-rht' >
                                        <span className="material-icons " onClick={() => { chatexit(value) }} style={{ color: "#E43554" }}>
                                          logout
                                        </span>
                                      </div>

                                    </div>
                                  </>
                                )
                              })}

                            </>
                          )}
                          {whatsappsession.length === 0 && (
                            <>
                              <div className="chat-body">
                                <span className="chat-body-sub-title">
                                  No Active Whatsapp Session
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  }
                  {AppPermission.chat_view && whatsappsession[0].channel === "facebook" &&
                    <>
                      <div className="chat-box webchat" >
                        <img className='web_chatimg' src={fbchat} alt="test" />
                        <h3 className="chat_h">Facebook</h3>
                      </div>

                      <div className="chat_panel">
                        <div className="chat-box-session">
                          <div className="chat-body-title">Facebook Session</div>

                          {whatsappsession.length > 0 && (
                            <>
                              {whatsappsession.map((value, i) => {
                                return (
                                  <>
                                    <div key={i} className="chat-body ">
                                      <div onClick={() => { redirecttochat(value) }} className='chat-body-lft'>
                                        <div className="chat-body-sub-title">
                                          {fromat(value.sender_name, { maskbool: true })}
                                        </div>
                                        <span className="chat-body-sub-title">
                                          {fromat(value.phone_number, { maskbool: true })}
                                        </span>
                                        <span>Active Now</span>
                                      </div>
                                      <div className='chat-body-rht' >
                                        <span className="material-icons " onClick={() => { chatexit(value) }} style={{ color: "#E43554" }}>
                                          logout
                                        </span>
                                      </div>

                                    </div>
                                  </>
                                )
                              })}

                            </>
                          )}
                          {whatsappsession.length === 0 && (
                            <>
                              <div className="chat-body">
                                <span className="chat-body-sub-title">
                                  No Active Facebook Session
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  }
                </>}
            </div>
          </>}
      </div>



      <Dialogbox
        fields={closepopup}
        value={popupdata}
        defaultclass={'dialog-body-default'}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    call: state.call.callaction,
    loader: state.call.loading,
    type: state.call.type,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    callAction: (type) => dispatch(fetchcallaction(type)),
    chatdispoValidation: (type) => dispatch(chatdispoValidation(type)),
    dispoValidation: (type) => dispatch(dispoValidation(type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallScreen)
