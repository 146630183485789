import React, { useState, useMemo } from 'react'
import ContactMainView from '../components/Contacts/ContactMainView'
import LeadMainView from '../components/Lead/LeadMainView'
import Voicemail from '../components/Lead/Leadsideview'
import '../assets/css/Leads.css'
import EventEmitter from '../utils/EventEmitter'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { callLeadDetail } from '../redux/background/BackgroundAction'

import {
    ContactFetch,
    ContactCustomformFields,
} from '../redux/actions/ContactActions'
import { CustomformData, CustomformFields } from '../redux/actions/Customform'
import { Decode } from '../utils/EncodeDecode'
function VoicemailView(props) {
    let config
    if (localStorage.getItem('config')) {
        config = JSON.parse(localStorage.getItem('config'))
    }
    let navigate = useNavigate()
    let Dispatch = useDispatch()
    let { phone_number, meeting_id } = useParams()
    phone_number =Decode(phone_number)
    const agent = localStorage.getItem('user')
    let state_value = useSelector((state) => state)
    const [create, setcreate] = useState(false)
    const [formData, setformData] = useState({})
    const [isthere, setisthere] = useState(false)
    const [formfield, setformfield] = useState([])
    const [models, setmodels] = useState(null)
    const [name, setname] = useState()
    const [id, setid] = useState("")
    function fetchapi(params) {
        mount()
    }
    useMemo(() => {

        mount()
    }, [phone_number])

    function mount() {

        Dispatch(
            ContactFetch(
                `/contact/fetchsearch?number=${phone_number}`,
            ),
        ).then((res) => {
            if (res.data.data.length === 0) {

                let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
                Dispatch(CustomformFields(campaigninfo.template_name)).then((response) => {
                    setformfield(response.data.data[0])
                    let payload = {
                        template_name: campaigninfo.template_name,
                        type: campaigninfo.type,
                        phone_number: phone_number,

                    }
                    Dispatch(CustomformData(payload)).then((res) => {
                        if (res.status == 204) {
                            if (config.data.projects[0].config[0].crm_type === "sales") {
                                setmodels('lead')
                            } else {
                                setmodels('contact')
                                navigate(`/contact/create`)
                            }
                            setcreate(true)
                            setisthere(true)
                            setformData(res)

                            if (state_value.background.oncall === true && localStorage.getItem("dialpadname") === "") {
                                localStorage.setItem("dialpadname", "Unknown Number");
                                setname(localStorage.getItem("dialpadname"))
                            }
                        } else if (res.data.statusCode == 200) {
                            setid(res.data.data[0].others.lead_id)
                            setmodels('lead')
                            if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1 && state_value.background.socket_message.phonenumber.toString() === phone_number) {
                                let leadData = {
                                    lead_id: res.data.data[0].others.lead_id,
                                    lead_name: res.data.data[0].others.first_name,
                                    phone_number: res.data.data[0].others.phone_number,
                                    module: "lead",
                                    render: 1
                                }
                                Dispatch(callLeadDetail(leadData))
                                let names = res.data.data[0].others.first_name
                                if (names === "") {
                                    names = res.data.data[0].others.phone_number
                                }
                                localStorage.setItem("dialpadname", names);
                                setname(localStorage.getItem("dialpadname"))
                            }

                            setisthere(true)
                            setformData(res)
                            setcreate(false)

                        }
                    }).catch((err) => {
                        EventEmitter.emit('error-message-getter', {
                            action: 'fetch',
                            module: 'lead data',
                        })
                    })
                }).catch((err) => {
                    EventEmitter.emit('error-message-getter', {
                        action: 'fetch',
                        module: 'form fields',
                    })
                })
            } else if (res.data.statusCode == 200) {
                setmodels("contact")

                setformData(res)
                setid(res.data.data[0].id)
                Dispatch(ContactCustomformFields()).then((response) => {
                    setformfield(response.data)

                    setisthere(true)
                    setcreate(false)
                    let names = res.data.data[0].name
                    if (names === "") {
                        names = res.data.data[0].phone_number
                    }
                    localStorage.setItem("dialpadname", names);
                    setname(localStorage.getItem("dialpadname"))
                    if (state_value.background.oncall && state_value.background.oncalllead[0].render < 1
                        && state_value.background.socket_message.phonenumber.toString() === phone_number) {
                        let leadData = {
                            lead_id: res.data.data[0].id,
                            lead_name: res.data.data[0].name,
                            phone_number: res.data.data[0].phone_number,
                            module: "contact",
                            render: 1
                        }
                        Dispatch(callLeadDetail(leadData))
                    }

                }).catch((err) => {
                    EventEmitter.emit('error-message-getter', {
                        action: 'fetch',
                        module: 'form fields',
                    })
                })
            }
        }).catch((err) => {
            EventEmitter.emit('error-message-getter', {
                action: 'fetch',
                module: 'contact',
            })
        })








    }

    return (
        <div className="page-main-container page-main-container-mobile">
            {isthere && (
                <>
                    <div className="scroll-lft leads-lft">
                        {create === false && (
                            <div className="leads-sidebar">
                                <Voicemail
                                    formdatas={formData}
                                    type={models}
                                    id={id}
                                    router={"voicemail"}
                                />
                            </div>
                        )}
                        <div
                            className={
                                create
                                    ? 'leads-main leads-main-new'
                                    : 'leads-main leads-main-exist'
                            }
                        >
                            {models === "contact" && <ContactMainView
                                fetchapi={fetchapi}
                                operation={create}
                                formdatas={formData}
                                statedata={formfield}
                                type={"contact"}

                            />}

                            {models === "lead" && <LeadMainView
                                fetchapi={fetchapi}
                                operation={create}
                                formdatas={formData}
                                statedata={formfield}
                                type={"lead"}

                            />}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default VoicemailView
