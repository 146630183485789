import axios from "axios";
var apinfo = JSON.parse(localStorage.getItem("apinfo")) || "{}"
let axiosInstance = axios.create({

    baseURL: apinfo.api_url,
    // baseURL: "http://10.0.27.109:4001",
    timeout: 180000,

});

if(apinfo.token !== undefined){
    axiosInstance.defaults.headers.common['Authorization'] =`Bearer ${apinfo.token}`;

}

axiosInstance.interceptors.request.use(
  async (config) => {
    console.log("Request interceptor: Before request is sent");
    
    if (!navigator.onLine) {
      console.log("Request interceptor: User is offline, waiting for internet connection...");
      await waitForInternetConnection();
    }

    console.log("Request interceptor: After waiting for internet connection, if needed");
    return config;
  },
  (error) => {
    console.log("Request interceptor: Request error", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
    (response) => {

      console.log("Response interceptor: Response received successfully");
      return response;
    },
    async (error) => {
      console.log("Response interceptor: Response error", error);
  
      if (!navigator.onLine) {
        console.log("Response interceptor: Handling API error during offline, waiting for internet connection...");
        await waitForInternetConnection();
        console.log("Response interceptor: After waiting for internet connection, retrying the request");
        return axiosInstance(error.config);
      }
  
      console.log("Response interceptor: Continuing with the response error");
      return Promise.reject(error);
    }
  );
  


const waitForInternetConnection = () => {
  return new Promise((resolve) => {
    const checkConnection = () => {
      if (navigator.onLine) {
        window.removeEventListener("online", checkConnection);
        console.log("Internet connection established");
        resolve();
      }
    };

    if (!navigator.onLine) {
      console.log("Waiting for internet connection...");
      window.addEventListener("online", checkConnection);
    } else {
      console.log("User is already online");
      resolve();
    }
  });
};

export default axiosInstance;
/**
* @return The adapter that axios uses for dispatching requests. This may be overwritten in tests.
*
* @see https://github.com/axios/axios/tree/master/lib/adapters
* @see https://github.com/ctimmerm/axios-mock-adapter/blob/v1.12.0/src/index.js#L39
*/
export const getDefaultAdapter = () => axios.defaults.adapter;