import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import CalllogAll from './CalllogAll'
import Callloginbound from './CalllogInbound'
import Calllogoutbound from './CallOutbound'
import Callabandoned from './CallAbandoned'
import EventEmitter from '../../utils/EventEmitter'

function CallDetail(props) {
  var agent = localStorage.getItem('user')
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  const [callOption, setcallOption] = useState(0)

  useEffect(() => {
    if (AppPermission.calllog_all) {
      setcallOption(1)
    } else if (AppPermission.calllog_inbound) {
      setcallOption(2);
    } else if (AppPermission.calllog_outbound) {
      setcallOption(3);
    } else if (AppPermission.calllog_abandonded) {
      setcallOption(4);
    }
  }, [])

  const Allcalls = (event) => {
    setcallOption(1)
  }

  const OutboundCalls = (event) => {
    setcallOption(3)
  }
  const InboundCalls = (event) => {
    setcallOption(2)
  }
  const AbandondedCalls = (event) => {
    setcallOption(4)
  }
  
  const getabandonednumber = (value) => {
    let readystate = localStorage.getItem("selectedPause")
    if(readystate==="available"){
      value.push(true)
      props.callnumber(value)
      
    }else{
    
    EventEmitter.emit('error-message-getter', {
            action: 'ready',
            module: "Agent Not ready",
          })
    }
    
   
  }
  const getnumber = (value) => {
    let readystate = localStorage.getItem("selectedPause")
    if(readystate==="available"){
      value.push(false)
      props.callnumber(value)
     
    
    }else{
    
    EventEmitter.emit('error-message-getter', {
            action: 'ready',
            module: "Agent Not ready",
          })
    }
    
   
  }
  const reduceLength = (value) => {
    if (value.length > 10) {
      return value.substring(0, 10) + '...';
    }
    return value;
  }
  return (
    <div className="callscreen-log">
      <div style={{ cursor: 'pointer' }} className="callscreen-call-option">
        {AppPermission.calllog_all && <span className={callOption === 1 ? 'active' : ''} onClick={Allcalls}>
          All
        </span>}
        {AppPermission.calllog_inbound && <span
          className={callOption === 2 ? 'active' : ''}
          onClick={InboundCalls}
        >
          Inbound
        </span>}
        {AppPermission.calllog_outbound && <span
          className={callOption === 3 ? 'active' : ''}
          onClick={OutboundCalls}
        >
          Outbound
        </span>}
        {AppPermission.calllog_abandonded && <span
          className={callOption === 4 ? 'active' : ''}
          onClick={AbandondedCalls}
        >
          Abandoned
         
        </span>}
      </div>

      {AppPermission.calllog_all &&callOption === 1 && (
        <div className="callscreen-body-option">
          <CalllogAll number={getnumber} reduceLength = {reduceLength} />
        </div>
      )}

      {AppPermission.calllog_inbound &&callOption === 2 && (
        <div className="callscreen-body-option">
          <Callloginbound number={getnumber} reduceLength ={reduceLength} />
        </div>
      )}

      {AppPermission.calllog_outbound &&callOption === 3 && (
        <div className="callscreen-body-option">
          <Calllogoutbound number={getnumber} reduceLength ={reduceLength} />
        </div>
      )}

      {AppPermission.calllog_abandonded &&callOption === 4 && (
        <div className="callscreen-body-option">
          <Callabandoned number={getabandonednumber} reduceLength ={reduceLength}/>
        </div>
      )}
    </div>
  )
}

export default CallDetail
