import React, { useState, useEffect } from 'react'
import { Form, Table } from 'react-bootstrap';
import { AsterOtherAgent } from "../redux/actions/SelectCRM"
import { useDispatch, useSelector } from 'react-redux';
import EventEmitter from '../utils/EventEmitter'

function TransferAgentCall(props) {
    const station = localStorage.getItem("extension")
    var tenant = '';
    let AppPermission
    if (localStorage.getItem('config')) {
        AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    if (localStorage.getItem("config")) {
        tenant = JSON.parse(localStorage.getItem("config")).data.tenant_code
    }

    const agent = localStorage.getItem("user")
    let dispatch = useDispatch()
    let stateValue = useSelector((state) => state)
    let agentdata = useSelector((state) => state.detail.otherAgent)
    const [search, setsearch] = useState("")




    useEffect(() => {
        var interval = null;
        if (props.transferStatus === false) {
            interval = setInterval(() => {
                let user = localStorage.getItem("user")
                let username = localStorage.getItem("username")
                let payloaddata = {

                    user: user,
                    name: "",
                }
                let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
                if (Object.keys(teamdata).length > 0) {
                    payloaddata.team = teamdata.name
                }
                dispatch(AsterOtherAgent(payloaddata))
                    .then(async function (response) {


                    }).catch(() => {
                        EventEmitter.emit('error-message-getter', {
                            action: 'fetch',
                            module: "Other LiveAgent",
                        })
                    })
            }, 2000)
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [props.transferStatus])

    useEffect(() => {
        if (props.transferStatus === false) {
            let user = localStorage.getItem("user")
            let username = localStorage.getItem("username")
            let payloaddata = {

                user: user,
                name: "",
            }

            let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
            if (Object.keys(teamdata).length > 0) {
                payloaddata.team = teamdata.name
            }


            dispatch(AsterOtherAgent(payloaddata))
                .then(async function (response) {


                }).catch(() => {
                    EventEmitter.emit('error-message-getter', {
                        action: 'fetch',
                        module: "Other LiveAgent",
                    })
                })
        }
    }, [])



    function transferagent(value) {
        if (props.agentActionType === "transfer") {
            let data = {
                action: 'Transfer',
                uniqueid: stateValue.background.socket_message.uniqueid,
                caller_channel: stateValue.background.socket_message.userChannel,
                agent_channel: stateValue.background.socket_message.agentChannel,
                xfer_type: 'ATTENDED',
                xfer_to: 'agent',
                xfer_dest: value.station,
                agent: agent,
                phone_number: stateValue.background.socket_message.phonenumber,
                station: station,
                tenant_id: tenant,
                name:value.name
            };
            props.agenttransfer(data)
        }

        if (props.agentActionType === "conference") {

            let data = {
                action: "conference",
                add_number: value.station,
                station: station,
                tenant_id: tenant,
                uniqueid: stateValue.background.socket_message.uniqueid,
                caller_channel: stateValue.background.socket_message.userChannel,
                live_channel: stateValue.background.socket_message.agentChannel,
            }
            props.agentconference(data)
        }

    }
    return (
        <>
            {props.agentActionType == "transfer" && AppPermission.agent_tranfer_search || props.type == "conference" && AppPermission.agent_conference_search &&
                <div style={{ position: 'relative' }}>
                    <span className="material-icons" style={{ position: 'absolute', top: 8, left: 10 }}>
                        search
                    </span>
                    <Form.Control onChange={(event) => {
                        setsearch(event.target.value)
                    }} type="text" placeholder="Search" style={{ paddingLeft: 40, height: '35px', marginTop: '10px' }} />
                </div>
            }
            <div className='table_cls'>
                <Table hover size="sm" className="agent_table">
                    <thead>
                        <tr>
                            <th>Agent</th>
                            {(props.agentActionType == "transfer" && AppPermission.agent_tranfer_status || props.type == "conference" && AppPermission.agent_conference_status) && <th>Status</th>}
                            {(props.agentActionType == "transfer" && AppPermission.agent_tranfer_queue || props.type == "conference" && AppPermission.agent_conference_queue) && <th>Queue</th>}

                        </tr>
                    </thead>
                    <tbody>
                        {agentdata?.filter((value) => {
                            if (search === "") {
                                return value
                            }
                            else if (value.name !== null && value.name.toLowerCase().includes(search.toLowerCase())) {
                                return value
                            }
                            else if (value.status !== null && value.status.toLowerCase().includes(search.toLowerCase())) {
                                return value
                            }
                            else if (value.queue !== null && value.queue.toLowerCase().includes(search.toLowerCase())) {
                                return value
                            }

                        }).map((value) => {
                            return (

                                <tr className={props?.conf_isclicked||value.status !== "READY" || props?.transfercall?.length > 0 ? "disabledbutton" : 'enablebutton'} onClick={() => { transferagent(value) }}>
                                    <td>{value.name}</td>
                                    {(props.agentActionType == "transfer" && AppPermission.agent_tranfer_status || props.type == "conference" && AppPermission.agent_conference_status) && <td className="connect">{value.status}</td>}
                                    {(props.agentActionType == "transfer" && AppPermission.agent_tranfer_queue || props.type == "conference" && AppPermission.agent_conference_queue) && <td>{value.queue}</td>}

                                </tr>
                            )
                        })

                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default TransferAgentCall;