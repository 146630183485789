import React, { useState } from 'react';
import { Container, Form, Button,Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { asterForgot } from '../redux/actions/login';
import  EventEmitter  from '../utils/EventEmitter';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true); // State to track email validity
    const [isLoading, setIsLoading] = useState(false);
 
    const handleChange = (event) => {
        const emailRegex =/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       if(emailRegex.test(event.target.value)){
        setIsValid(true)
        }
        setEmail(event.target.value);
    };
    const formStyle = {
        opacity: isLoading ? 0.6 : 1,
        pointerEvents: isLoading ? 'none' : 'auto'
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const emailRegex =/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            setIsLoading(true);
            console.log("Email submitted:", email);
            const payload = {
                email:email,
                role:2
            }
            dispatch(asterForgot(payload)).then((response)=>{
               if(response.status===204){
                setEmail("")
                EventEmitter.emit('error-message-getter',{
                    action:'fav',
                    module:'Given mail id is not found'
                })
               }else{
                EventEmitter.emit('error-message-getter',{
                    action:'updated',
                    module:'forgot password'
                })
                setEmail("")
                setTimeout(() => {
                    localStorage.clear();
                    navigate('/')
                }, 5000);
               }
               
            }).finally(() => {
                setIsLoading(false);
              });
        } else {
            setIsValid(false);
        }
    };
 
    return (
        <Container fluid style={{ padding: 0 }}>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh", background: "#e5e5e5" }}
            >
                <div className="box1" style={{ width: '370px' }}>
                    <h3 className="title">Forgot Password</h3>
 
                    <Form onSubmit={handleSubmit} style={formStyle}>
                        <div className="row" style={{ padding: '0 20px' }}>
                            <div className="col-md-12 mt-4">
                                <Form.Control
                                    type="text"
                                    value={email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    isInvalid={!isValid} // Apply Bootstrap's isInvalid styling if email is invalid
                                />
                                {/* Display validation error message */}
                                {!isValid && (
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid email address.
                                    </Form.Control.Feedback>
                                )}
                            </div>
                        </div>
 
                        <div style={{ padding: "20px" }}>
                            <Button variant="primary" type="submit" disabled={isLoading} className="mb-3">
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="ms-2">Submitting...</span>
                                    </>
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    );
}
 
export default ForgotPassword;
 