import React from 'react';

const loaderContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 9999,
};

const loaderStyle = {
  border: '16px solid #f3f3f3',
  borderTop: '16px solid #3498db',
  borderRadius: '50%',
  width: '120px',
  height: '120px',
  animation: 'spin 1s linear infinite',
};

const FullPageLoader = () => {
  return (
    <div style={loaderContainerStyle}>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div style={loaderStyle}></div>
    </div>
  );
};

export default FullPageLoader;