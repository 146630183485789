import React, { useState, useEffect } from 'react'
import TransferAgentCall from './transferAgentCall'
import TransferContactCall from './transferCenterCall'
import TransferOthersCall from './transgerOthersCall'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap'
import Format from '../utils/format-text';
function Conference(props) {
    let AppPermission
    if (localStorage.getItem('config')) {
        AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
    }
    let state = useSelector((state) => state)
    const [ConferenceType, setConferenceType] = useState(0)
    const [conf, setConf] = useState(false);
    const [addConferenceperson, setaddConferenceperson] = useState(false)
    const [conferenceStatus, setconferenceStatus] = useState(false)
    const [manageconf, setmanageconf] = useState(state.background.conference)

    useEffect(() => {
        if (AppPermission.agent_conference) {
            setConferenceType(1)
        } else if (AppPermission.did_conference) {
            setConferenceType(3)
        } else {
            setConferenceType(2)
        }
    }, [])
    useEffect(() => {
        let arr = []
        for (let i = 0; i < state.background.conference.length; i++) {
            const element = state.background.conference[i];
            element.front_hand = false
            element.mic_off = false
            element.logout = false
            arr.push(element)
        }
        setmanageconf(arr)
    }, [state.background.conference.length])

    useEffect(() => {
        let arr = []
        for (let i = 0; i < manageconf.length; i++) {
            const element = manageconf[i];
            if (element.party_number === props.muteconf.phone_number) {
                element.mic_off = !element.mic_off
            }
            arr.push(element)
        }
        setmanageconf(arr)
    }, [props.muteconf])


    const openAgents = () => {
        setaddConferenceperson(!addConferenceperson)
    }
    const socketTransfer = (data) => {
        props.socketConference(data)
    }
    const conference = (status) => {
        setconferenceStatus(true)
        props.showconference()
    }

    const agentconference = (status) => {
        props.agentconference(status)
    }

    const otherconference = (status) => {
        props.otherconference(status)
    }

    const contactconference = (status) => {
        props.otherconference(status)
    }

    const fronthand = (status, phone) => {
        let array = []
        for (let i = 0; i < manageconf.length; i++) {
            const element = manageconf[i];
            if (element.party_number === phone) {
                element.front_hand = !status
            }
            array.push(element)
        }
        setmanageconf(array)
    }
    const micoff = (status, phone) => {
        let array = []
        for (let i = 0; i < manageconf.length; i++) {
            const element = manageconf[i];
            if (element.party_number === phone) {

                let action
                if (element.mic_off) {
                    action = "conf-unmute"
                } else {
                    action = "conf-mute"
                }
                let data = {
                    action: action,
                    conference: element.conf_no,
                    phone_number: element.party_number,
                    station: element.station,
                    tenant_id: element.tenant_id,
                    uniqueid: element.uniqueid,
                    channel: element.channel
                }
                props.conf_mute_action(data)
            }
            array.push(element)
        }
        setmanageconf(array)
    }
    const kickout = (status, phone) => {
        let array = []
        for (let i = 0; i < manageconf.length; i++) {
            const element = manageconf[i];
            if (element.party_number === phone) {
                element.logout = !status

                let data = {
                    action: "conf-exit",
                    conference: element.conf_no,
                    phone_number: element.party_number,
                    station: element.station,
                    tenant_id: element.tenant_id,
                    uniqueid: element.uniqueid,
                    channel: element.channel
                }
                props.conf_kickout_action(data)
            }
            array.push(element)
        }
        setmanageconf(array)
    }

    const endConf = () => {
        let data = {
            action: "conf-end",
            conference: manageconf[0].conf_no,
            station: manageconf[0].station,
            tenant_id: manageconf[0].tenant_id,
            uniqueid: manageconf[0].uniqueid,
            channel: manageconf[0].channel
        }
        props.conf_end(data)
    }
    return (
        <>
            <div className="contact_head">
                <h3>Add Conference</h3>
                <span className="material-icons" onClick={() => conference(conf)}>
                    close
                </span>
            </div>
            <div className='conference_head_txt'>
                {AppPermission.agent_conference && <div onClick={() => setConferenceType(1)} className={ConferenceType === 1 ? 'contact_head_txt-subdiv active' : 'contact_head_txt-subdiv'}>Agents</div>}
                {AppPermission.did_conference && <div onClick={() => setConferenceType(3)} className={ConferenceType === 3 ? 'contact_head_txt-subdiv active' : 'contact_head_txt-subdiv'}>Contact</div>}
                {AppPermission.other_conference_view && <div onClick={() => setConferenceType(2)} className={ConferenceType === 2 ? 'contact_head_txt-subdiv active' : 'contact_head_txt-subdiv'}>Others</div>}
            </div>
            <div className='call-action-controll'>
                {ConferenceType === 1 && AppPermission.agent_conference &&
                    <TransferAgentCall conf_isclicked={props?.conf_isclicked} type={"conference"} agentconference={agentconference} transferStatus={conferenceStatus} agentActionType={"conference"} ></TransferAgentCall>
                }{ConferenceType === 3 && AppPermission.did_conference &&
                    <TransferContactCall  conf_isclicked={props?.conf_isclicked} type={"conference"} contactconference={contactconference} agentActionType={"conference"} othertransfer={socketTransfer}></TransferContactCall>
                }{ConferenceType === 2 && AppPermission.other_conference_view &&
                    <TransferOthersCall conf_isclicked={props?.conf_isclicked} type={"conference"} otherconference={otherconference} agentActionType={"conference"} othertransfer={socketTransfer}></TransferOthersCall>
                }
            </div>
            {AppPermission.view_manage_conference && <div style={{ textAlign: 'center', color: '#2977EC', paddingTop: 20, paddingBottom: 10 }}>
                <span onClick={() => openAgents()}>Manage Conference</span>
            </div>}

            {addConferenceperson &&
                <>
                    <>
                        {
                            manageconf.map((value, i) => {
                                return (
                                    < div key={i}>
                                        <div className="manage_conf">
                                            <h3 className="manage_conf1">{Format(value.party_number, { maskbool: true })}</h3>
                                            {value.station === value.party_number &&
                                                <h3 className="manage_conf2 conf-red">Host</h3>
                                            }
                                            {value.station !== value.party_number &&
                                                <h3 className="manage_conf2 conf-green">Guest</h3>
                                            }
                                            <div className="manage_conf3" >
                                                {AppPermission.manage_conference_handraise && <span onClick={() => fronthand(value.front_hand, value.party_number)} className={value.front_hand ? "material-icons shape" : "material-icons"}>
                                                    front_hand
                                                </span>}
                                                {AppPermission.manage_conference_mute && <span onClick={() => micoff(value.mic_off, value.party_number)} className={value.mic_off ? "material-icons shape" : "material-icons"}>
                                                    {value.mic_off ? "mic_off" : "mic"}
                                                </span>}
                                                {AppPermission.manage_conference_kickout && value.station !== value.party_number &&
                                                    <span onClick={() => kickout(value.logout, value.party_number)} className="material-icons redend">
                                                        logout
                                                    </span>
                                                }
                                                {AppPermission.manage_conference_kickout && value.station === value.party_number &&
                                                    <span onClick={() => endConf()} className="material-icons redend">
                                                        logout
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        }
                    </>
                    {AppPermission.manage_conference_endcall && manageconf.length > 0 &&
                        <div className='endnow-div'>
                            <Button onClick={() => endConf()} className="endnow" >End Conference</Button>
                        </div>
                    }
                </>

            }


        </>
    )
}

export default Conference;